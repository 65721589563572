"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAccounts = function (state) {
  return state.WalletReducer.getIn(["accounts"], null);
};
exports.getSelectedAccount = function (state) {
  var selectedAccountID = state.WalletReducer.get("selectedAccountID");
  if (!selectedAccountID) {
    return null;
  }
  return state.WalletReducer.getIn(["accounts", selectedAccountID], null);
};
exports.getAccount = function (state, accountID) {
  return state.WalletReducer.getIn(["accounts", accountID], null);
};
exports.getSelectedAccountWallet = function (state) {
  var selectedAccount = exports.getSelectedAccount(state);
  return selectedAccount && selectedAccount.get("wallet", null);
};
exports.getWallet = function (state, accountID) {
  return state.WalletReducer.getIn(["accounts", accountID, "wallet"], null);
};