"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spread = this && this.__spread || function () {
  for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
  return ar;
};
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  result["default"] = mod;
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var React = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var wallet_1 = require("../../../actions/wallet");
var copy = require("clipboard-copy");
var mapStateToProps = function mapStateToProps(state) {
  var walletState = state.WalletReducer;
  var walletCache = walletState.get("walletCache");
  return {
    wallet: walletCache.wallet,
    walletTranslations: walletState.get("walletTranslations")
  };
};
var Backup = function (_super) {
  __extends(Backup, _super);
  function Backup(props) {
    var _this = _super.call(this, props) || this;
    _this.state = {
      indices: new Set(),
      testing: false
    };
    _this.mnemonic = null;
    return _this;
  }
  Backup.prototype.render = function () {
    var _this = this;
    var testing = this.state.testing;
    var _a = this.props,
      wallet = _a.wallet,
      walletTranslations = _a.walletTranslations;
    var handleCopy = function handleCopy(e) {
      e.clipboardData.setData("text/plain", wallet.getMnemonic());
      e.preventDefault();
    };
    var letter = this.getMnemonicArray().join(' ');
    return React.createElement("div", {
      className: "RainConnect-backup RainConnect-fieldGroup"
    }, React.createElement("div", {
      className: "RainConnect-label recoverylabel"
    }, walletTranslations.recoveryPhrase), React.createElement("div", {
      className: "RainConnect-mnemonic"
    }, React.createElement("i", {
      className: "RainConnect-copy RainConnect-fa fa fa-clipboard text-black",
      onClick: function onClick() {
        copy(letter);
      }
    }), React.createElement("ol",
    // console.log("this.getMnemonicArray()", this.getMnemonicArray().join(' ')),
    {
      className: "RainConnect-words",
      onCopy: function onCopy(e) {
        return handleCopy(e);
      },
      ref: function ref(elem) {
        return _this.setMnemonicElem(elem);
      }
    }, this.getMnemonicArray().map(function (word, index) {
      return _this.renderWord(word, index);
    }))), React.createElement("div", {
      className: "RainConnect-desc mb-3"
    }, testing ? walletTranslations.testingDesc : walletTranslations.backupDesc), React.createElement("button", {
      className: "RainConnect-button RainConnect-submitButton RainConnect-featureButton",
      onClick: function onClick() {
        return testing ? _this.confirm() : _this.test();
      }
    }, walletTranslations.next));
  };
  Backup.prototype.test = function () {
    var phraseLength = this.getMnemonicArray().length;
    var indices = new Set();
    while (indices.size < 3) {
      var index = Math.floor(Math.random() * phraseLength);
      indices.add(index);
    }
    this.setState({
      indices: indices,
      testing: true
    });
  };
  Backup.prototype.confirm = function () {
    var dispatch = this.props.dispatch;
    if (!this.mnemonic) {
      return;
    }
    if (__spread(this.mnemonic.querySelectorAll("input")).filter(function (input) {
      return input.value !== input.id;
    }).length > 0) {
      return;
    }
    dispatch(wallet_1.setWalletStep(wallet_1.WALLET_STEPS.CREATE_CONFIRM));
  };
  Backup.prototype.renderWord = function (word, index) {
    var _a = this.state,
      indices = _a.indices,
      testing = _a.testing;
    if (testing && indices.has(index)) {
      word = React.createElement(BackupWord, {
        word: word
      });
    }
    return React.createElement("li", {
      key: index,
      className: "RainConnect-word"
    }, word);
  };
  Backup.prototype.setMnemonicElem = function (elem) {
    this.mnemonic = elem;
  };
  Backup.prototype.getMnemonicArray = function () {
    var wallet = this.props.wallet;
    return wallet.getMnemonic().split(" ");
  };
  return Backup;
}(React.PureComponent);
exports.default = react_redux_1.connect(mapStateToProps)(Backup);
var BackupWord = function (_super) {
  __extends(BackupWord, _super);
  function BackupWord(props) {
    var _this = _super.call(this, props) || this;
    _this.state = {
      className: ""
    };
    return _this;
  }
  BackupWord.prototype.handleChange = function (value) {
    if (!value) {
      this.setState({
        className: ""
      });
    } else if (value !== this.props.word) {
      this.setState({
        className: "invalid"
      });
    } else {
      this.setState({
        className: "valid"
      });
    }
  };
  BackupWord.prototype.render = function () {
    var _this = this;
    var className = ["RainConnect-input", this.state.className].filter(function (cls) {
      return !!cls;
    }).join(" ");
    return React.createElement("input", {
      id: this.props.word,
      className: className,
      onChange: function onChange(e) {
        return _this.handleChange(e.target.value);
      }
    });
  };
  return BackupWord;
}(React.PureComponent);