import React from 'react';
import { connect } from 'react-redux';
import { loadTrades } from '../../actions/account';
import PerfectScrollbar from 'perfect-scrollbar';
import { WalletButton, getSelectedAccount } from '@gongddex/hydro-sdk-wallet';
import BigNumber from 'bignumber.js';
import { toast } from 'react-toastify';
import MediaQuery from 'react-responsive';
import moment from 'moment';


const mapStateToProps = state => {
  const selectedAccount = getSelectedAccount(state);
  const address = selectedAccount ? selectedAccount.get('address') : null;
  return {
    address,
    trades: state.account.get('trades'),
    isLoggedIn: state.account.getIn(['isLoggedIn', address]),
    currentMarket: state.market.getIn(['markets', 'currentMarket'])
  };
};

class Trades extends React.PureComponent {
  componentDidMount() {
    const { isLoggedIn, dispatch } = this.props;
    if (isLoggedIn) {
      dispatch(loadTrades(toast));
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoggedIn, dispatch, trades, currentMarket,address } = this.props;

    if (isLoggedIn && (isLoggedIn !== prevProps.isLoggedIn || currentMarket !== prevProps.currentMarket)) {
      dispatch(loadTrades(toast));
    }
    if (address && address !== prevProps.address) {
      dispatch(loadTrades(toast));
    }
    if (trades !== prevProps.trades) {
      this.ps && this.ps.update();
    }
  }

  render() {
    return (
      <>
        <MediaQuery minWidth={1366}>{this.renderDesktop()}</MediaQuery>
        <MediaQuery minWidth={1024} maxWidth={1365}>
          {this.renderLaptop()}
        </MediaQuery>
        <MediaQuery minWidth={768} maxWidth={1023}>
          {this.renderTablet()}
        </MediaQuery>
        <MediaQuery maxWidth={767}>{this.renderMobile()}</MediaQuery>
      </>
    );
  }

  renderDesktop() {
    const { trades, address, currentMarket, isLoggedIn } = this.props;
    return (
      <div className="trades flex-1 position-relative table-responsive scrollable-content" /* ref={ref => this.setRef(ref)} */ style={{ height: '324px', overflow: 'scroll' }}>
        {isLoggedIn ?
        trades.toArray().length > 0 ?
            <>
              <table className="table">
                <thead className='ordersheader'>
                  <tr className="text-secondary">
                    <th className="pair-column">Pair</th>
                    <th>Side</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Amount</th>
                    <th className="text-center">Time</th>
                    <th className="text-right">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {trades
                    .toArray()
                    .reverse()
                    .map(([id, trade]) => {
                      let side;
                      if (trade.taker === address) {
                        side = trade.takerSide;
                      } else {
                        side = trade.takerSide === 'buy' ? 'sell' : 'buy';
                      }

                      let status;
                      let className = 'text-center ';
                      if (trade.status === 'successful') {
                        status = <i className="fa fa-check" aria-hidden="true" />;
                        className += 'text-success';
                      } else if (trade.status === 'pending') {
                        status = <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" />;
                      } else {
                        className += 'text-danger';
                        status = <i className="fa fa-close" aria-hidden="true" />;
                      }
                      const symbol = trade.marketID.split('-')[0];
                      return (
                        <tr key={id} style={{cursor:'auto'}}>
                          <td className="pair-column">{trade.marketID}</td>
                          <td className={`${side === 'sell' ? 'text-danger' : 'text-success'}`} style={{fontSize:'15px',textTransform:'capitalize'}}>{side}</td>
                          <td className={`text-right${side === 'sell' ? ' text-danger' : ' text-success'}`}>
                            {new BigNumber(trade.price).toFixed(currentMarket.priceDecimals)}
                          </td>
                          <td className="text-right">
                            {new BigNumber(trade.amount).toFixed(currentMarket.amountDecimals)} {symbol}
                          </td>
                          <td className="text-center">
                            {moment(trade.updatedAt).format("DD-MM-YY HH:mm")}
                          </td>
                          <td className={className}>{status}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          : <div className='connectwallet'> No Trades Found !!!</div>
          : <div className='connectwallet'><span><WalletButton /></span></div>}
      </div>
    );
  }

  renderLaptop() {
    const { trades, address, currentMarket, isLoggedIn } = this.props;
    return (
      <div className="trades flex-1 position-relative table-responsive scrollable-content" /* ref={ref => this.setRef(ref)} */ style={{ height: '324px', overflow: 'scroll' }}>
        {isLoggedIn ?
          trades.toArray().length > 0 ?
            <>
              <table className="table">
                <thead className='ordersheader'>
                  <tr className="text-secondary">
                    <th className="pair-column">Pair</th>
                    <th>Side</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Amount</th>
                    <th className="text-center">Time</th>
                    <th className="text-right">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {trades
                    .toArray()
                    .reverse()
                    .map(([id, trade]) => {
                      let side;
                      if (trade.taker === address) {
                        side = trade.takerSide;
                      } else {
                        side = trade.takerSide === 'buy' ? 'sell' : 'buy';
                      }

                      let status;
                      let className = 'text-center ';
                      if (trade.status === 'successful') {
                        status = <i className="fa fa-check" aria-hidden="true" />;
                        className += 'text-success';
                      } else if (trade.status === 'pending') {
                        status = <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" />;
                      } else {
                        className += 'text-danger';
                        status = <i className="fa fa-close" aria-hidden="true" />;
                      }
                      const symbol = trade.marketID.split('-')[0];
                      return (
                        <tr key={id} style={{ cursor: 'auto' }}>
                          <td className="pair-column">{trade.marketID}</td>
                          <td className={`${side === 'sell' ? 'text-danger' : 'text-success'}`} style={{ fontSize: '15px', textTransform: 'capitalize' }}>{side}</td>
                          <td className={`text-right${side === 'sell' ? ' text-danger' : ' text-success'}`}>
                            {new BigNumber(trade.price).toFixed(currentMarket.priceDecimals)}
                          </td>
                          <td className="text-right">
                            {new BigNumber(trade.amount).toFixed(currentMarket.amountDecimals)} {symbol}
                          </td>
                          <td className="text-center">
                            {moment(trade.updatedAt).format("DD-MM-YY HH:mm")}
                          </td>
                          <td className={className}>{status}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
            : <div className='connectwallet'> No Trades Found !!!</div>
          : <div className='connectwallet'><span><WalletButton /></span></div>}
      </div>
    );
  }

  renderTablet() {
    const { trades, address, currentMarket, isLoggedIn } = this.props;
    return (
      <div className="trades flex-1 position-relative overflow-hidden table-responsive scrollable-content scrollBar" style={{ height: '182px' }} ref={ref => this.setRef(ref)}>
        {isLoggedIn ?
          trades.toArray().length > 0 ?
            <>
              <table className="table">
                <thead className='ordersheader'>
                  <tr className="text-secondary">
                    <th className="pair-column">Pair</th>
                    <th>Side</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Amount</th>
                    <th className="text-center">Time</th>
                    <th className="text-right">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {trades
                    .toArray()
                    .reverse()
                    .map(([id, trade]) => {
                      let side;
                      if (trade.taker === address) {
                        side = trade.takerSide;
                      } else {
                        side = trade.takerSide === 'buy' ? 'sell' : 'buy';
                      }

                      let status;
                      let className = 'text-center ';
                      if (trade.status === 'successful') {
                        status = <i className="fa fa-check" aria-hidden="true" />;
                        className += 'text-success';
                      } else if (trade.status === 'pending') {
                        status = <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" />;
                      } else {
                        className += 'text-danger';
                        status = <i className="fa fa-close" aria-hidden="true" />;
                      }
                      const symbol = trade.marketID.split('-')[0];
                      return (
                        <tr key={id} style={{ cursor: 'auto' }}>
                          <td className="pair-column">{trade.marketID}</td>
                          <td className={`${side === 'sell' ? 'text-danger' : 'text-success'}`} style={{ fontSize: '15px', textTransform: 'capitalize' }}>{side}</td>
                          <td className={`text-right${side === 'sell' ? ' text-danger' : ' text-success'}`}>
                            {new BigNumber(trade.price).toFixed(currentMarket.priceDecimals)}
                          </td>
                          <td className="text-right">
                            {new BigNumber(trade.amount).toFixed(currentMarket.amountDecimals)} {symbol}
                          </td>
                          <td className="text-center">
                            {moment(trade.updatedAt).format("DD-MM-YY HH:mm")}
                          </td>
                          <td className={className}>{status}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
            : <div className='connectwallet'> No Trades Found !!!</div>
          : <div className='connectwallet'><span><WalletButton /></span></div>}
      </div>
    );
  }

  renderMobile() {
    const { trades, address, currentMarket, isLoggedIn } = this.props;
    return (
      <div className="trades flex-1 position-relative table-responsive scrollable-content" ref={ref => this.setRef(ref)} style={{ height: '490px', overflow: 'scroll' }}>
        {isLoggedIn ?
          trades.toArray().length > 0 ?
            <>
              <table className="table">
                <thead>
                  <tr className="ordersheader">
                    <th className="pair-column">Pair</th>
                    <th>Side</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Amount</th>
                    <th className="text-center">Time</th>
                    <th className="text-right">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {trades
                    .toArray()
                    .reverse()
                    .map(([id, trade]) => {
                      let side;
                      if (trade.taker === address) {
                        side = trade.takerSide;
                      } else {
                        side = trade.takerSide === 'buy' ? 'sell' : 'buy';
                      }

                      let status;
                      let className = 'text-center ';
                      if (trade.status === 'successful') {
                        status = <i className="fa fa-check" aria-hidden="true" />;
                        className += 'text-success';
                      } else if (trade.status === 'pending') {
                        status = <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" />;
                      } else {
                        className += 'text-danger';
                        status = <i className="fa fa-close" aria-hidden="true" />;
                      }
                      const symbol = trade.marketID.split('-')[0];
                      return (
                        <tr key={id} style={{ cursor: 'auto' }}>
                          <td className="pair-column">{trade.marketID}</td>
                          <td className={`${side === 'sell' ? 'text-danger' : 'text-success'}`} style={{ fontSize: '15px', textTransform: 'capitalize' }}>{side}</td>
                          <td className={`text-right${side === 'sell' ? ' text-danger' : ' text-success'}`}>
                            {new BigNumber(trade.price).toFixed(currentMarket.priceDecimals)}
                          </td>
                          <td className="text-right">
                            {new BigNumber(trade.amount).toFixed(currentMarket.amountDecimals)} {symbol}
                          </td>
                          <td className="text-center">
                            {moment(trade.updatedAt).format("DD-MM-YY HH:mm")}
                          </td>
                          <td className={className}>{status}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
            : <div className='connectwallet'> No Trades Found !!!</div>
          : <div className='connectwallet'><span><WalletButton /></span></div>}
      </div>
    );
  }

  setRef(ref) {
    if (ref) {
      this.ps = new PerfectScrollbar(ref, {
        suppressScrollX: true,
        maxScrollbarLength: 20
      });
    }
  }
}

export default connect(mapStateToProps)(Trades);
