

export const getbalance = (balance) => {
    return async dispatch => {
        return dispatch({
            type: 'GET_ETHBALANCE',
            payload: balance
        });
    };
};
