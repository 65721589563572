import React from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import MediaQuery from 'react-responsive';
import PerfectScrollbar from 'perfect-scrollbar';
import {settrade} from '../../actions/ordertrade'
import env from '../../lib/env';

class OrderBook extends React.Component {
  constructor(props) {
    super(props);
    this.lastUpdatedAt = null;
    this.forceRenderTimer = null;
  }

  // max 1 render in 1 second
  shouldComponentUpdate() {
    if (this.lastUpdatedAt) {
      const diff = new Date().valueOf() - this.lastUpdatedAt;
      const shouldRender = diff > 1000;

      if (!shouldRender && !this.forceRenderTimer) {
        this.forceRenderTimer = setTimeout(() => {
          this.forceUpdate();
          this.forceRenderTimer = null;
        }, 1000 - diff);
      }
      return shouldRender;
    } else {
      return true;
    }
  }

  componentWillUnmount() {
    if (this.forceRenderTimer) {
      clearInterval(this.forceRenderTimer);
    }
  }

  componentDidUpdate() {
    this.lastUpdatedAt = new Date();
  }

  render() {
    return (
      <>
        <MediaQuery minWidth={1366}>{this.renderDesktop()}</MediaQuery>
        <MediaQuery minWidth={1024} maxWidth={1365}>
          {this.renderLaptop()}
        </MediaQuery>
        <MediaQuery minWidth={768} maxWidth={1023}>
          {this.renderTablet()}
        </MediaQuery>
        <MediaQuery maxWidth={767}>{this.renderMobile()}</MediaQuery>
      </>

    );
  }
  totalCalculation() {
    const bids = this.props.bids.toArray().map(priceLevel => {
      return {
        amount: Number(priceLevel[1].toString())
      };
    });
    const asks = this.props.asks.toArray().map(priceLevel => {
      return {
        amount: Number(priceLevel[1].toString())
      };
    });
    let bidsum = 0;
    bids.forEach(element => {
      bidsum += element.amount;
    });
    let asksum = 0;
    asks.forEach(element => {
      asksum += element.amount;
    });
    return {
      bidsum,
      asksum
    }
  }

  renderDesktop() {
    let { bids, asks, websocketConnected, currentMarket, dispatch, networkId, selectedAccountID, markets } = this.props;
    let currentmarket = markets.filter(market => market.id === currentMarket.id)._tail.array[0];
    let { bidsum,asksum } = this.totalCalculation()
    return (

      <>
        <div className="mb-3" style={{ height:'444px'}}>
          <div className='col-12 basiccard h-100' style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
            <div className='aftertitleborder pb-1 pt-2 row'>
              <div className='col-12 cardtitle'>Orderbook</div>
              <div className="col-12 cardsubtitle">Available Bid and Ask orders</div>
            </div>
            <div className="row pt-2">
              <div className='col-12 d-flex'>
                <div className="col-6 text-center tablehead">Amount</div>
                <div className="col-6 text-center tablehead">Price</div>
              </div>
            </div>
            <div className="row">
              <div className="asks flex-column flex-column-reverse  overflow-hidden col-12 pt-1" style={{height:'365px'}}>
                {asks.size > 0 ? 
                  asks.slice(-23).reverse().toArray().map(([price, amount]) => {
                    return (
                      <div className="ask flex align-items-center row" key={price.toString()} style={{ position: 'relative' }} onClick={() => {
                        if (selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10)) {
                          dispatch(settrade(price, amount, "buy", "click"))
                        }
                        }}>
                        <div className='asksbackclr' style={{ width: `${Math.round((Number(amount.toFixed(currentMarket.amountDecimals)) * 100) / asksum)}%` }}></div>
                        <div className="col-6 orderbook-amount text-center orderbook-amt">
                          {amount.toFixed(currentMarket.amountDecimals)}
                        </div>
                        <div className="col-6 text-danger text-center orderbook-price">{price.toFixed(currentMarket.priceDecimals)}</div>
                      </div>
                    );
                  }) : <div className='nodatacardorder'>No Data Found !!!</div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="" style={{ height: '444px' }}>
          <div className='col-12 basiccard h-100'>
            <div className='row aftertitleborder'>
              <div className="status col-12 p-0">
                {websocketConnected ? (
                  <div className="col-12 text-themeclr p-3 pl-0 text-uppercase">
                    <i className="fa fa-circle" aria-hidden="true" /> {currentmarket.lastPrice + ' ' + currentMarket.baseTokenName}
                  </div>
                ) : (
                    <div className="col-12 text-danger p-3 pl-0">
                    <i className="fa fa-circle" aria-hidden="true" /> Disconnected
                  </div>
                )}
              </div>
            </div>
            <div className="row pt-2">
              <div className='col-12 d-flex'>
                <div className="col-6 text-center tablehead">Amount</div>
                <div className="col-6 text-center tablehead">Price</div>
              </div>
            </div>
            <div className="row">
              <div className="bids flex-column col-12 pt-1 overflow-hidden" style={{ height: '365px' }}>
                {bids.size > 0 ?
                  bids.slice(0, 23)
                  .toArray()
                  .map(([price, amount]) => {
                    return (
                      <div className="bid flex align-items-center row" key={price.toString()} style={{ position: 'relative' }} onClick={() => {
                        if (selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10)) {
                          dispatch(settrade(price, amount, "sell", "click"))
                        }
                        }}>
                        <div className='bidbackclr' style={{ width: `${Math.round((Number(amount.toFixed(currentMarket.amountDecimals)) * 100) / bidsum)}%` }}></div>
                        <div className="col-6 orderbook-amount text-center orderbook-amt">
                          {amount.toFixed(currentMarket.amountDecimals)}
                        </div>
                        <div className="col-6 text-center orderbook-price text-themeclr ">{price.toFixed(currentMarket.priceDecimals)}</div>
                      </div>
                    );
                  }) : <div className='nodatacardorder'>No Data Found !!!</div>}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderLaptop() {
    let { bids, asks, websocketConnected, currentMarket, dispatch, networkId, selectedAccountID, markets } = this.props;
    let currentmarket = markets.filter(market => market.id === currentMarket.id)._tail.array[0];
    let { bidsum, asksum } = this.totalCalculation()

    return (

      <>
        <div className="mb-3" style={{ height: '444px' }}>
          <div className='col-12 basiccard h-100' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div className='aftertitleborder pb-1 pt-2 row'>
              <div className='col-12 cardtitle'>Orderbook</div>
              <div className="col-12 cardsubtitle">Available Bid and Ask orders</div>
            </div>
            <div className="row pt-2">
              <div className='col-12 d-flex'>
                <div className="col-6 text-center tablehead">Amount</div>
                <div className="col-6 text-center tablehead">Price</div>
              </div>
            </div>
            <div className="row">
              <div className="asks flex-column flex-column-reverse  overflow-hidden col-12 pt-2" style={{ height: '365px' }}>
                {asks.size > 0 ? 
                  asks.slice(-23).reverse().toArray().map(([price, amount]) => {
                    return (
                      <div className="ask flex align-items-center row" key={price.toString()} style={{ position: 'relative' }} onClick={() => {
                        if (selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10)) {
                          dispatch(settrade(price, amount, "buy", "click"))
                        }
                        }}>
                        <div className='asksbackclr' style={{ width: `${Math.round((Number(amount.toFixed(currentMarket.amountDecimals)) * 100) / asksum)}%` }}></div>
                        <div className="col-6 orderbook-amount text-center orderbook-amt">
                          {amount.toFixed(currentMarket.amountDecimals)}
                        </div>
                        <div className="col-6 text-danger text-center orderbook-price">{price.toFixed(currentMarket.priceDecimals)}</div>
                      </div>
                    );
                  }) : <div className='nodatacardorder'>No Data Found !!!</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="" style={{ height: '444px' }}>
          <div className='col-12 basiccard h-100'>
            <div className='row aftertitleborder'>
              <div className="status col-12 p-0">
                {websocketConnected ? (
                  <div className="col-12 text-themeclr p-3 pl-0 text-uppercase">
                    <i className="fa fa-circle" aria-hidden="true" /> {currentmarket.lastPrice + ' ' + currentMarket.baseTokenName}
                  </div>
                ) : (
                    <div className="col-12 text-danger p-3 pl-0">
                    <i className="fa fa-circle" aria-hidden="true" /> Disconnected
                  </div>
                )}
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-6 text-center tablehead">Amount</div>
              <div className="col-6 text-center tablehead">Price</div>
            </div>
            <div className="row">
              <div className="bids flex-column col-12 pt-2 overflow-hidden" style={{ height: '365px' }}>
                {bids.size > 0 ?
                bids
                  .slice(0, 23)
                  .toArray()
                  .map(([price, amount]) => {
                    return (
                      <div className="bid flex align-items-center row" key={price.toString()} style={{ position: 'relative' }} onClick={() => {
                        if (selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10)) {
                          dispatch(settrade(price, amount, "sell", "click"))
                        }
                        }}>
                        <div className='bidbackclr' style={{ width: `${Math.round((Number(amount.toFixed(currentMarket.amountDecimals)) * 100) / bidsum)}%` }}></div>
                        <div className="col-6 orderbook-amount text-center orderbook-amt">
                          {amount.toFixed(currentMarket.amountDecimals)}
                        </div>
                        <div className="col-6 text-center orderbook-price text-themeclr ">{price.toFixed(currentMarket.priceDecimals)}</div>
                      </div>
                    );
                  }) : <div className='nodatacardorder'>No Data Found !!!</div>}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderTablet() {
    let { bids, asks, websocketConnected, currentMarket, dispatch, networkId, selectedAccountID, markets } = this.props;
    let currentmarket = markets.filter(market => market.id === currentMarket.id)._tail.array[0];
    let { bidsum, asksum } = this.totalCalculation()

    return (

      // <div className=" flex-column">
      //   <div className="flex header text-secondary">
      //     <div className="col-6 text-center">Amount</div>
      //     <div className="col-6 text-center">Price</div>
      //   </div>
      //   <div className="flex-column flex-1">
      //     <div className="asks flex-column flex-column-reverse  overflow-hidden">
      //       {
      //         asks.slice(-23).reverse().toArray().map(([price, amount]) => {
      //           return (
      //             <div className="ask flex align-items-center" key={price.toString()}>
      //               <div className="col-6 orderbook-amount text-center orderbook-amt">
      //                 {amount.toFixed(currentMarket.amountDecimals)}
      //               </div>
      //               <div className="col-6 text-danger text-center orderbook-price">{price.toFixed(currentMarket.priceDecimals)}</div>
      //             </div>
      //           );
      //         })}
      //     </div>
      //     <div className="status border-top border-bottom">
      //       {websocketConnected ? (
      //         <div className="col-6 text-success p-3 ">
      //           <i className="fa fa-circle" aria-hidden="true" /> {currentMarket.lastPrice + ' ' + currentMarket.baseTokenName}
      //         </div>
      //       ) : (
      //         <div className="col-6 text-danger">
      //           <i className="fa fa-circle" aria-hidden="true" /> Disconnected
      //         </div>
      //       )}
      //     </div>
      //     <div className="bids flex-column flex-1 overflow-hidden">
      //       {bids
      //         .slice(0, 23)
      //         .toArray()
      //         .map(([price, amount]) => {
      //           return (
      //             <div className="bid flex align-items-center" key={price.toString()}>
      //               <div className="col-6 orderbook-amount text-center orderbook-amt">
      //                 {amount.toFixed(currentMarket.amountDecimals)}
      //               </div>
      //               <div className="col-6 text-success text-center orderbook-price">{price.toFixed(currentMarket.priceDecimals)}</div>
      //             </div>
      //           );
      //         })}
      //     </div>
      //   </div>
      // </div>
      <>
        <div className="row">
          <div className="col-12 status ">
            {websocketConnected ? (
              <div className="col-12 text-success p-3 border-bottom text-uppercase">
                <i className="fa fa-circle" aria-hidden="true" /> {currentmarket.lastPrice + ' ' + currentMarket.baseTokenName}
              </div>
            ) : (
              <div className="col-12 text-success p-3 border-bottom">
                <i className="fa fa-circle" aria-hidden="true" /> Disconnected
              </div>
            )}
          </div>
          {/* <div className="flex header text-secondary"> */}
        </div>
        <div className="position-relative row m-0" style={{width:'100%',height:'229px' }} ref={ref => this.setRef(ref)}>
          <div className='col-6'>
            <div className='pt-2 flex'>
              <div className="col-6 text-center tablehead">Amount</div>
              <div className="col-6 text-center tablehead">Price</div>
            </div>
            <div className="row">
              <div className="asks flex-column flex-column-reverse  overflow-hidden col-12 pt-1">
                {asks.size > 0 ? 
                  asks.slice(-11).reverse().toArray().map(([price, amount]) => {
                    return (
                      <div className="ask flex align-items-center row" key={price.toString()} style={{ position: 'relative' }} onClick={() => {
                        if (selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10)) {
                          dispatch(settrade(price, amount, "buy", "click"))
                        }
                        }}>
                        <div className='asksbackclr' style={{ width: `${Math.round((Number(amount.toFixed(currentMarket.amountDecimals)) * 100) / asksum)}%` }}></div>
                        <div className="col-6 orderbook-amount text-center orderbook-amt">
                          {amount.toFixed(currentMarket.amountDecimals)}
                        </div>
                        <div className="col-6 text-danger text-center orderbook-price">{price.toFixed(currentMarket.priceDecimals)}</div>
                      </div>
                    );
                  }) : <div className='nodatacardorder' style={{ height:'200px'}}>No Data Found !!!</div>}
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='pt-2 flex'>
              <div className="col-6 text-center tablehead">Amount</div>
              <div className="col-6 text-center tablehead">Price</div>
            </div>
            {/* <div className="flex-column flex-1"> */}
            <div className="row">
              <div className="bids flex-column col-12 pt-1 overflow-hidden" >
                {bids.size > 0 ? 
                bids
                  .slice(0, 11)
                  .toArray()
                  .map(([price, amount]) => {
                    return (
                      <div className="bid flex align-items-center row" key={price.toString()} style={{ position: 'relative' }} onClick={() => {
                        if (selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10)) {
                          dispatch(settrade(price, amount, "sell", "click"))
                        }
                        }}>
                        <div className='bidbackclr' style={{ width: `${Math.round((Number(amount.toFixed(currentMarket.amountDecimals)) * 100) / bidsum)}%` }}></div>
                        <div className="col-6 orderbook-amount text-center orderbook-amt">
                          {amount.toFixed(currentMarket.amountDecimals)}
                        </div>
                        <div className="col-6 text-center orderbook-price text-themeclr ">{price.toFixed(currentMarket.priceDecimals)}</div>
                      </div>
                    );
                  }) : <div className='nodatacardorder' style={{ height: '200px' }}>No Data Found !!!</div>}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderMobile() {
    let { bids, asks, websocketConnected, currentMarket, dispatch, networkId, selectedAccountID, markets } = this.props;
    let currentmarket = markets.filter(market => market.id === currentMarket.id)._tail.array[0];
    let { bidsum, asksum } = this.totalCalculation()
    return (

      // <div className="flex-column">
      //   <div className="flex header text-secondary">
      //     <div className="col-6 text-right">Amount</div>
      //     <div className="col-6 text-right">Price</div>
      //   </div>
      //   <div className="flex-column flex-1">
      //     <div className="asks flex-column flex-column-reverse  overflow-hidden" style={{ height: '400px' }}>
      //       {
      //         asks.slice(-23).reverse().toArray().map(([price, amount]) => {
      //           return (
      //             <div className="ask flex align-items-center" key={price.toString()}>
      //               <div className="col-6 orderbook-amount text-right">
      //                 {amount.toFixed(currentMarket.amountDecimals)}
      //               </div>
      //               <div className="col-6 text-danger text-right">{price.toFixed(currentMarket.priceDecimals)}</div>
      //             </div>
      //           );
      //         })}
      //     </div>
      //     <div className="status border-top border-bottom">
      //       {websocketConnected ? (
      //         <div className="col-6 text-success p-3 ">
      //           <i className="fa fa-circle" aria-hidden="true" /> {currentMarket.lastPrice + ' ' + currentMarket.baseTokenName}
      //         </div>
      //       ) : (
      //         <div className="col-6 text-danger">
      //           <i className="fa fa-circle" aria-hidden="true" /> Disconnected
      //         </div>
      //       )}
      //     </div>
      //     <div className="bids flex-column flex-1 overflow-hidden">
      //       {bids
      //         .slice(0, 23)
      //         .toArray()
      //         .map(([price, amount]) => {
      //           return (
      //             <div className="bid flex align-items-center" key={price.toString()}>
      //               <div className="col-6 orderbook-amount text-right">
      //                 {amount.toFixed(currentMarket.amountDecimals)}
      //               </div>
      //               <div className="col-6 text-success text-right">{price.toFixed(currentMarket.priceDecimals)}</div>
      //             </div>
      //           );
      //         })}
      //     </div>
      //   </div>
      // </div>
      <div className="row" style={{height:'552px'}}>
        <div className="col-12 status ">
          {websocketConnected ? (
            <div className="col-12 text-success p-3 border-bottom text-uppercase">
              <i className="fa fa-circle" aria-hidden="true" /> {currentmarket.lastPrice + ' ' + currentMarket.baseTokenName}
            </div>
          ) : (
            <div className="col-12 text-success p-3 border-bottom">
              <i className="fa fa-circle" aria-hidden="true" /> Disconnected
            </div>
          )}
        </div>
        {/* <div className="flex header text-secondary"> */}
        <div className='col-6 p-0' style={{ borderRight: '1px solid #373b40',height:'518px'}}>
          <div className='pb-3 pt-2 flex'>
            <div className="col-6 text-center tablehead p-0">Amount</div>
            <div className="col-6 text-center tablehead p-0">Price</div>
          </div>
          <div className="">
            <div className="asks flex-column flex-column-reverse  overflow-hidden col-12 pt-2 pr-1 pl-0" style={{ maxHeight: '500px', minHeight: '500px',justifyContent:'flex-end' }}>
              {
                asks.slice(-27).reverse().toArray().map(([price, amount]) => {
                  return (
                    <div className="ask flex align-items-center" key={price.toString()} style={{ position: 'relative' }} onClick={() => {
                      if (selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10)) {
                        dispatch(settrade(price, amount, "buy", "click"))
                      }
                      }}>
                      <div className='asksbackclr' style={{ width: `${Math.round((Number(amount.toFixed(currentMarket.amountDecimals)) * 100) / asksum)}%` }}></div>
                      <div className="col-6 orderbook-amount text-center orderbook-amt p-0">
                        {amount.toFixed(currentMarket.amountDecimals)}
                      </div>
                      <div className="col-6 text-danger text-center orderbook-price p-0">{price.toFixed(currentMarket.priceDecimals)}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <hr/>
        <div className='col-6 p-0' style={{height:'518px'}}>
          <div className='pb-3 pt-2 flex'>
            <div className="col-6 text-center tablehead p-0">Amount</div>
            <div className="col-6 text-center tablehead p-0">Price</div>
          </div>
          {/* <div className="flex-column flex-1"> */}
          <div className="">
            <div className="bids flex-column col-12  pb-2 pt-2 overflow-hidden pr-1 pl-0" style={{ maxHeight: '500px', minHeight: '500px' }}>
              {bids
                .slice(0, 27)
                .toArray()
                .map(([price, amount]) => {
                  return (
                    <div className="bid flex align-items-center" key={price.toString()} style={{ position: 'relative' }} onClick={() => {
                      if (selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10)) {
                        dispatch(settrade(price, amount, "sell", "click"))
                      }
                      }}>
                      <div className='bidbackclr' style={{ width: `${Math.round((Number(amount.toFixed(currentMarket.amountDecimals)) * 100) / bidsum)}%` }}></div>
                      <div className="col-6 orderbook-amount text-center orderbook-amt p-0">
                        {amount.toFixed(currentMarket.amountDecimals)}
                      </div>
                      <div className="col-6 text-center orderbook-price text-themeclr p-0 ">{price.toFixed(currentMarket.priceDecimals)}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
  setRef(ref) {
    if (ref) {
      this.ps = new PerfectScrollbar(ref, {
        suppressScrollX: true,
        maxScrollbarLength: 20
      });
    }
  }

}

const mapStateToProps = state => {
  const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  return {
    asks: state.market.getIn(['orderbook', 'asks']),
    bids: state.market.getIn(['orderbook', 'bids']),
    loading: false,
    currentMarket: state.market.getIn(['markets', 'currentMarket']),
    websocketConnected: state.config.get('websocketConnected'),
    theme: state.config.get('theme'),
    networkId: state.WalletReducer.getIn(['accounts', selectedAccountID, 'networkId']),
    selectedAccountID,
    markets: state.market.getIn(['markets', 'data']),
  };
};

export default connect(mapStateToProps)(OrderBook);
