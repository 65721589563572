import React from 'react';
import { updateCurrentMarket } from '../../actions/markets';
import { connect } from 'react-redux';
import PerfectScrollbar from 'perfect-scrollbar';
import MediaQuery from 'react-responsive';

const mapStateToProps = state => {
  return {
    currentMarket: state.market.getIn(['markets', 'currentMarket']),
    markets: state.market.getIn(['markets', 'data']),
    lightmode: state.mode
  };
};

class Pairs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      showTopSearch: false,
      watchlist: [],
      showWatchlist: false
    };
  }

  toggleWatchlist = () => {
    this.setState(prevState => ({
      showWatchlist: !prevState.showWatchlist
    }));
  }

  watchlistButton = (market) => {
    const { watchlist } = this.state;
    const { lightmode } = this.props

    const isWatched = watchlist.includes(market.id);
    const color = isWatched ? '#B6A371' : lightmode.lightmode ? '#000000' : '#FFFFFF';
    return (
      <div
        style={{ cursor: "pointer" }}
        className={
          `mr-2 ml-1 star-icon ${isWatched ? 'starred' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          if (isWatched) {
            this.setState({
              watchlist: watchlist.filter((id) => id !== market.id),
            });
          } else {
            this.setState({
              watchlist: [...watchlist, market.id],
            });
          }
        }}
      >
        <i className="fa fa-star" style={{ color }}></i>
      </div >
    );
  };
  componentDidMount() {
    const storedWatchlist = localStorage.getItem('watchlist');
    if (storedWatchlist) {
      this.setState({ watchlist: JSON.parse(storedWatchlist) });
    }
  }

  componentDidUpdate() {
    localStorage.setItem('watchlist', JSON.stringify(this.state.watchlist));
  }


  render() {
    return (
      <>
        <MediaQuery maxWidth={767}>{this.renderTablet()}</MediaQuery>
        <MediaQuery minWidth={768} maxWidth={1023}>{this.renderLaptop()}</MediaQuery>
        <MediaQuery minWidth={1024} maxWidth={1200}>{this.renderWeb()}</MediaQuery>
        <MediaQuery minWidth={1201}>{this.renderlargeWeb()}</MediaQuery>
      </>

    )
  }
  renderlargeWeb() {
    const { currentMarket, markets, dispatch } = this.props;
    const filteredMarkets = markets.filter(market => market.id.toLowerCase().includes(this.state.searchTerm.toLowerCase()));
    const color = this.state.showWatchlist ? '#B6A371' : '#737373';
    const data = (market) => (
      <div
        className="ask flex align-items-center"
        key={market.id}
        onClick={() => {
          this.setState({ showTopSearch: false });
          if (currentMarket.id !== market.id) {
            dispatch(updateCurrentMarket(market));
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <div className="col-1 orderbook-amount text-center p-0">
          {this.watchlistButton(market)}
        </div>
        <div className="col-5 orderbook-amount text-left d-flex blackwhitechange pl-0 pr-0">
          {market.id}
        </div>
        <div className="col-6 orderbook-amount text-center text-themeclr p-0" style={{ fontWeight: '600' }}>
          {market.lastPrice}
        </div>
      </div>
    );

    return (
      <>
        <div className="" style={{ height: "247px", overflow: 'scroll' }} >
          <div className={['form-group align-items-center', this.state.showTopSearch ? '' : '', 'd-flex', 'mb-0'].join(' ')}>
            <div className="input-group ">
              <span className="unit2"><i className="fa fa-search"></i></span>
              <input className="form-control pl-5 mt-3 mb-3 inputfield" style={{ height: "30px" }} type="text" placeholder="Search" value={this.state.searchTerm} onClick={() => this.setState({ showTopSearch: true })} onChange={(e) => {
                this.setState({ searchTerm: e.target.value }); e.target.value === '' ? this.setState({ showTopSearch: true }) : this.setState({ showTopSearch: false })
              }}
              />
              {this.state.searchTerm && (
                <span className="text-secondary unit" style={{ cursor: "pointer", pointerEvents: "auto" }} onClick={() => this.setState({ searchTerm: '', })}
                >
                  <i className="fa fa-close"></i>
                </span>
              )}
            </div>
            {this.state.showTopSearch && (
              <p className='searchcancel mb-0 ml-2' style={{ cursor: "pointer", pointerEvents: "auto" }} onClick={() => this.setState({ showTopSearch: false, })}>cancel</p>
            )}
          </div>

          {this.state.showTopSearch ?
            <>
              {/* <div className="col-12 text-left">Top 5 Search</div> */}
              <div className="flex header justify-content-between" style={{ lineHeight: '30px' }}>
                <div className="col-1 text-center p-0"></div>
                <div className="col-5 text-left tablehead pl-0 pr-0">Pairs</div>
                <div className="col-6 text-center tablehead p-0">Price</div>
                {/* <div className="col-3 text-center tablehead p-0">Volume</div> */}
              </div>
              <div style={{ height: "calc(100% - 100px)", overflowY: "auto" }} className='scrollable-content' onClick={() => this.setState({ showTopSearch: false })}>
                <div className="flex-column flex-1">
                  <div className="asks flex-column overflow-hidden" style={{ fontSize: "12px", lineHeight: '22px' }}>
                    {filteredMarkets.slice(0, 5).map(market => {
                      return (
                        <div className="ask flex align-items-center justify-content-between" key={market.id}
                          onClick={() => {
                            this.setState({ showTopSearch: false })
                            currentMarket.id !== market.id && dispatch(updateCurrentMarket(market))
                          }} style={{ cursor: "pointer" }}>
                          <div className="col-1 orderbook-amount text-center blackwhitechange p-0">
                            {this.watchlistButton(market)}
                          </div>
                          <div className="col-5 orderbook-amount text-left d-flex pl-0 pr-0 blackwhitechange">
                            {market.id}
                          </div>
                          <div className="col-6 orderbook-amount text-center text-themeclr p-0" style={{ fontWeight: '600' }}>
                            {market.lastPrice}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
            :
            <>
              <div className="flex header" style={{ lineHeight: '30px' }}>
                <div className="col-1 text-center" style={{ padding: "0", color: color, cursor: "pointer" }} onClick={this.toggleWatchlist}><i className="fa fa-star" style={{ fontSize: '17px' }}></i></div>
                <div className="col-5 text-left tablehead pl-0 pr-0 d-flex ">
                  Pairs
                </div>
                <div className="col-6 text-center tablehead p-0">Price</div>
                {/* <div className="col-3 text-center tablehead p-0">Volume</div> */}
              </div>
              <div style={{ height: "calc(100% - 100px)", overflowY: "auto" }} className='scrollable-content' onClick={() => this.setState({ showTopSearch: false })}>
                <div className="flex-column flex-1">
                  <div className="asks flex-column overflow-hidden" style={{ fontSize: "12px", lineHeight: '22px' }}>
                    {
                      this.state.showWatchlist
                        ?
                        filteredMarkets.filter((market) => this.state.watchlist.includes(market.id)).map((market) => {
                          return data(market);

                        })
                        :
                        filteredMarkets.map((market) => {
                          return data(market);
                        })
                    }
                  </div>
                </div>
              </div>
            </>}
        </div>
      </>
    )
  }
  renderWeb() {
    const { currentMarket, markets, dispatch } = this.props;
    const filteredMarkets = markets.filter(market => market.id.toLowerCase().includes(this.state.searchTerm.toLowerCase()));
    const color = this.state.showWatchlist ? '#B6A371' : '#737373';
    const data = (market) => (
      <div
        className="ask flex align-items-center justify-content-between"
        key={market.id}
        onClick={() => {
          this.setState({ showTopSearch: false });
          if (currentMarket.id !== market.id) {
            dispatch(updateCurrentMarket(market));
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <div className="col-1 orderbook-amount text-center p-0 mr-2">
          {this.watchlistButton(market)}
        </div>
        <div className="col-5 orderbook-amount text-left d-flex blackwhitechange pl-0 pr-0">
          {market.id}
        </div>
        <div className="col-6 orderbook-amount text-center text-themeclr p-0" style={{ fontWeight: '600' }}>
          {market.lastPrice}
        </div>
      </div>
    );

    return (
      <>
        <div className="" style={{ height: "247px", overflow: 'scroll' }} >
          <div className={['form-group align-items-center', this.state.showTopSearch ? '' : '', 'd-flex', 'mb-0'].join(' ')}>
            <div className="input-group ">
              <span className="unit2"><i className="fa fa-search"></i></span>
              <input className="form-control pl-5 mt-3 mb-3 inputfield" style={{ height: "30px" }} type="text" placeholder="Search" value={this.state.searchTerm} onClick={() => this.setState({ showTopSearch: true })} onChange={(e) => {
                this.setState({ searchTerm: e.target.value }); e.target.value === '' ? this.setState({ showTopSearch: true }) : this.setState({ showTopSearch: false })
              }}
              />
              {this.state.searchTerm && (
                <span className="text-secondary unit" style={{ cursor: "pointer", pointerEvents: "auto" }} onClick={() => this.setState({ searchTerm: '', })}
                >
                  <i className="fa fa-close"></i>
                </span>
              )}
            </div>
            {this.state.showTopSearch && (
              <p className='searchcancel mb-0 ml-2' style={{ cursor: "pointer", pointerEvents: "auto" }} onClick={() => this.setState({ showTopSearch: false, })}>cancel</p>
            )}
          </div>

          {this.state.showTopSearch ?
            <>
              {/* <div className="col-12 text-left">Top 5 Search</div> */}
              <div className="flex header" style={{ lineHeight: '30px' }}>
                <div className="col-1 text-center p-0"></div>
                <div className="col-5 text-left tablehead pl-0 pr-0">Pairs</div>
                <div className="col-6 text-center tablehead p-0">Price</div>
                {/* <div className="col-2 text-center tablehead p-0">Vol.</div> */}
              </div>
              <div style={{ height: "calc(100% - 100px)", overflowY: "auto" }} className='scrollable-content' onClick={() => this.setState({ showTopSearch: false })}>
                <div className="flex-column flex-1">
                  <div className="asks flex-column overflow-hidden" style={{ fontSize: "12px", lineHeight: '22px' }}>
                    {filteredMarkets.slice(0, 5).map(market => {
                      return (
                        <div className="ask flex align-items-center justify-content-between" key={market.id}
                          onClick={() => {
                            this.setState({ showTopSearch: false })
                            currentMarket.id !== market.id && dispatch(updateCurrentMarket(market))
                          }} style={{ cursor: "pointer" }}>
                          <div className="col-1 orderbook-amount text-center blackwhitechange p-0">
                            {this.watchlistButton(market)}
                          </div>
                          <div className="col-5 orderbook-amount text-left d-flex pl-2 pr-0 blackwhitechange">
                            {market.id}
                          </div>
                          <div className="col-6 orderbook-amount text-center text-themeclr p-0" style={{ fontWeight: '600' }}>
                            {market.lastPrice}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
            :
            <>
              <div className="flex header" style={{ lineHeight: '30px' }}>
                <div className="col-1 text-center mr-2" style={{ padding: "0", color: color, cursor: "pointer" }} onClick={this.toggleWatchlist}><i className="fa fa-star" style={{ fontSize: '17px' }}></i></div>
                <div className="col-5 text-left tablehead pl-0 d-flex pr-0">
                  Pairs
                </div>
                <div className="col-6 text-center tablehead p-0">Price</div>
                {/* <div className="col-2 text-center tablehead p-0">Vol.</div> */}
              </div>
              <div style={{ height: "calc(100% - 100px)", overflowY: "auto" }} className='scrollable-content' onClick={() => this.setState({ showTopSearch: false })}>
                <div className="flex-column flex-1">
                  <div className="asks flex-column overflow-hidden" style={{ fontSize: "12px", lineHeight: '22px' }}>
                    {
                      this.state.showWatchlist
                        ?
                        filteredMarkets.filter((market) => this.state.watchlist.includes(market.id)).map((market) => {
                          return data(market);
                        })
                        :
                        filteredMarkets.map((market) => {
                          return data(market);
                        })
                    }
                  </div>
                </div>
              </div>
            </>}
        </div>
      </>
    )
  }
  renderLaptop() {
    const { currentMarket, markets, dispatch } = this.props;
    const filteredMarkets = markets.filter(market => market.id.toLowerCase().includes(this.state.searchTerm.toLowerCase()));
    const color = this.state.showWatchlist ? '#B6A371' : '#737373';
    const data = (market) => (
      <div
        className="ask flex align-items-center col-12"
        key={market.id}
        onClick={() => {
          this.setState({ showTopSearch: false });
          if (currentMarket.id !== market.id) {
            dispatch(updateCurrentMarket(market));
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <div className="col-1 orderbook-amount text-center p-0 mr-2">
          {this.watchlistButton(market)}
        </div>
        <div className="col-5 orderbook-amount text-left d-flex blackwhitechange pl-0 pr-0">
          {market.id}
        </div>
        <div className="col-6 orderbook-amount text-center text-themeclr p-0 ml-2" style={{ fontWeight: '600' }}>
          {market.lastPrice}
        </div>
      </div>
    );

    return (
      <>
        <div className="position-relative" style={{ height: "277px" }}>
          <div className='cardtitle' style={{ color: "#16C782", textAlign: 'center', paddingTop: '16px' }}>{currentMarket && currentMarket.id}</div>
          <div className={['form-group align-items-center col-12', this.state.showTopSearch ? '' : '', 'd-flex', 'mb-0'].join(' ')}>
            <div className="input-group ">
              <span className="unit2"><i className="fa fa-search"></i></span>
              <input className="form-control pl-5 mt-3 mb-3 inputfield" style={{ height: "30px" }} type="text" placeholder="Search" value={this.state.searchTerm} onClick={() => this.setState({ showTopSearch: true })} onChange={(e) => {
                this.setState({ searchTerm: e.target.value }); e.target.value === '' ? this.setState({ showTopSearch: true }) : this.setState({ showTopSearch: false })
              }}
              />
              {this.state.searchTerm && (
                <span className="text-secondary unit" style={{ cursor: "pointer", pointerEvents: "auto" }} onClick={() => this.setState({ searchTerm: '', })}
                >
                  <i className="fa fa-close"></i>
                </span>
              )}
            </div>
            {this.state.showTopSearch && (
              <p className='searchcancel mb-0 ml-2' style={{ cursor: "pointer", pointerEvents: "auto" }} onClick={() => this.setState({ showTopSearch: false, })}>cancel</p>
            )}
          </div>

          {this.state.showTopSearch ?
            <>
              {/* <div className="col-12 text-left">Top 5 Search</div> */}
              <div className="flex col-12" style={{ lineHeight: '30px', paddingRight: '6px' }}>
                <div className="col-1 text-center p-0"></div>
                <div className="col-5 text-left tablehead pl-0 pr-0">Pairs</div>
                <div className="col-6 text-center tablehead p-0">Price</div>
                {/* <div className="col-3 text-center tablehead p-0">Volume</div> */}
              </div>
              <div onClick={() => this.setState({ showTopSearch: false })} >
                <div className="flex-column flex-1">
                  <div className="asks flex-column overflow-hidden" style={{ fontSize: "12px", lineHeight: '22px' }}>
                    {filteredMarkets.slice(0, 5).map(market => {
                      return (
                        <div className="ask flex align-items-center col-12" key={market.id}
                          onClick={() => {
                            this.setState({ showTopSearch: false })
                            currentMarket.id !== market.id && dispatch(updateCurrentMarket(market))
                          }} style={{ cursor: "pointer" }}>
                          <div className="col-1 orderbook-amount text-center blackwhitechange p-0">
                            {this.watchlistButton(market)}
                          </div>
                          <div className="col-5 orderbook-amount text-left d-flex pl-2 pr-0 blackwhitechange">
                            {market.id}
                          </div>
                          <div className="col-6 orderbook-amount text-center text-themeclr p-0" style={{ fontWeight: '600' }}>
                            {market.lastPrice}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
            :
            <>
              <div className="flex col-12" style={{ lineHeight: '30px', paddingRight: '6px' }}>
                <div className="col-1 text-center mr-2" style={{ padding: "0", color: color, cursor: "pointer" }} onClick={this.toggleWatchlist}><i className="fa fa-star" style={{ fontSize: '17px' }}></i></div>
                <div className="col-5 text-left tablehead pl-0 d-flex pr-0">
                  Pairs
                </div>
                <div className="col-6 text-center tablehead p-0">Price</div>
              </div>
              <div style={{ height: "155px", overflowY: 'auto' }} className='scrollable-content' onClick={() => this.setState({ showTopSearch: false })}>
                <div className="flex-column flex-1">
                  <div className="asks flex-column" style={{ fontSize: "12px", lineHeight: '22px' }}>
                    {
                      this.state.showWatchlist
                        ?
                        filteredMarkets.filter((market) => this.state.watchlist.includes(market.id)).map((market) => {
                          return data(market);

                        })
                        :
                        filteredMarkets.map((market) => {
                          return data(market);
                        })
                    }
                  </div>
                </div>
              </div>
            </>}
        </div>
      </>
    )
  }
  renderTablet() {
    const { currentMarket, markets, dispatch } = this.props;
    const filteredMarkets = markets.filter(market => market.id.toLowerCase().includes(this.state.searchTerm.toLowerCase()));
    const color = this.state.showWatchlist ? '#B6A371' : '#737373';
    const data = (market) => (
      <div
        className="ask flex align-items-center"
        key={market.id}
        onClick={() => {
          this.setState({ showTopSearch: false });
          if (currentMarket.id !== market.id) {
            dispatch(updateCurrentMarket(market));
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <div className="col-1 orderbook-amount text-center p-0">
          {this.watchlistButton(market)}
        </div>
        <div className="col-5 orderbook-amount text-left d-flex blackwhitechange pl-2 pr-0">
          {market.id}
        </div>
        <div className="col-6 orderbook-amount text-center text-themeclr p-0" style={{ fontWeight: '600' }}>
          {market.lastPrice}
        </div>
      </div>
    );

    return (
      <>
        <div className="" style={{ height: "100%", overflow: 'hidden' }}>
          <div className='cardtitle' style={{ color: "#16C782", textAlign: 'center' }}>{currentMarket && currentMarket.id}</div>
          <div className={['form-group align-items-center col-12 p-0', this.state.showTopSearch ? '' : '', 'd-flex', 'mb-0'].join(' ')}>
            <div className="input-group ">
              <span className="unit2"><i className="fa fa-search"></i></span>
              <input className="form-control pl-5 mt-3 mb-3 inputfield" style={{ height: "30px" }} type="text" placeholder="Search" value={this.state.searchTerm} onClick={() => this.setState({ showTopSearch: true })} onChange={(e) => {
                this.setState({ searchTerm: e.target.value }); e.target.value === '' ? this.setState({ showTopSearch: true }) : this.setState({ showTopSearch: false })
              }}
              />
              {this.state.searchTerm && (
                <span className="text-secondary unit" style={{ cursor: "pointer", pointerEvents: "auto" }} onClick={() => this.setState({ searchTerm: '', })}
                >
                  <i className="fa fa-close"></i>
                </span>
              )}
            </div>
            {this.state.showTopSearch && (
              <p className='searchcancel mb-0 ml-2' style={{ cursor: "pointer", pointerEvents: "auto" }} onClick={() => this.setState({ showTopSearch: false, })}>cancel</p>
            )}
          </div>

          {this.state.showTopSearch ?
            <>
              {/* <div className="col-12 text-left">Top 5 Search</div> */}
              <div className="flex" style={{ lineHeight: '30px', paddingRight: '6px' }}>
                <div className="col-1 text-center p-0"></div>
                <div className="col-5 text-left tablehead pl-2 pr-0">Pairs</div>
                <div className="col-6 text-center tablehead p-0">Price</div>
                {/* <div className="col-3 text-center tablehead p-0">Volume</div> */}
              </div>
              <div style={{ height: "calc(100% - 100px)", overflowY: "auto" }} className='scrollable-content' onClick={() => this.setState({ showTopSearch: false })}>
                <div className="flex-column flex-1">
                  <div className="asks flex-column overflow-hidden" style={{ fontSize: "12px", lineHeight: '22px' }}>
                    {filteredMarkets.slice(0, 5).map(market => {
                      return (
                        <div className="ask flex align-items-center" key={market.id}
                          onClick={() => {
                            this.setState({ showTopSearch: false })
                            currentMarket.id !== market.id && dispatch(updateCurrentMarket(market))
                          }} style={{ cursor: "pointer" }}>
                          <div className="col-1 orderbook-amount text-center blackwhitechange p-0">
                            {this.watchlistButton(market)}
                          </div>
                          <div className="col-5 orderbook-amount text-left d-flex pl-2 pr-0 blackwhitechange">
                            {market.id}
                          </div>
                          <div className="col-6 orderbook-amount text-center text-themeclr p-0" style={{ fontWeight: '600' }}>
                            {market.lastPrice}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
            :
            <>
              <div className="flex" style={{ lineHeight: '30px', paddingRight: '6px' }}>
                <div className="col-1 text-center" style={{ padding: "0", color: color, cursor: "pointer" }}
                  onClick={this.toggleWatchlist}
                ><i className="fa fa-star" style={{ fontSize: '17px' }}></i></div>
                <div className="col-5 text-left tablehead pl-2 pr-0">Pairs</div>
                <div className="col-6 text-center tablehead p-0">Price</div>
              </div>
              <div style={{ height: "calc(100% - 100px)", overflowY: "auto" }} className='scrollable-content' onClick={() => this.setState({ showTopSearch: false })}>
                <div className="flex-column flex-1">
                  <div className="asks flex-column overflow-hidden" style={{ fontSize: "12px", lineHeight: '22px' }}>
                    {
                      this.state.showWatchlist
                        ?
                        filteredMarkets.filter((market) => this.state.watchlist.includes(market.id)).map((market) => {
                          return data(market);

                        })
                        :
                        filteredMarkets.map((market) => {
                          return data(market);
                        })
                    }
                  </div>
                </div>
              </div>
            </>}
        </div>
      </>
    )
  }

  setRef(ref) {
    if (ref) {
      this.ps = new PerfectScrollbar(ref, {
        suppressScrollX: true,
        maxScrollbarLength: 20
      });
    }
  }
}




export default connect(mapStateToProps)(Pairs);