import React from 'react';
import { connect } from 'react-redux';
import { loadTokens } from '../../actions/account';
import { toUnitAmount } from '../../lib/utils';
import { stateUtils } from '../../selectors/account';
import { getSelectedAccount } from '@gongddex/hydro-sdk-wallet';
import { BigNumber } from 'bignumber.js';
import { ethers } from 'ethers';

import env from '../../lib/env'
import { getbalance } from '../../actions/ethbalance';
import { toast } from 'react-toastify';
const mapStateToProps = state => {
  // const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  const selectedAccount = getSelectedAccount(state);
  const address = selectedAccount ? selectedAccount.get('address') : null;
  // let tokenloaders = []
  // stateUtils.getTokensInfo(state, address).toArray().map(([token, info]) => { return tokenloaders.push(false) })
  return {
    tokensInfo: stateUtils.getTokensInfo(state, address),
    // tokenloader: tokenloaders,
    address,
    ethBalance: Number(state.ethbalance)
  };
};

class Tokens extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      // loader: [],
      changeload: false
    };
  }
  componentDidMount() {
    const { address, dispatch } = this.props;
    if (address) {
      dispatch(loadTokens());
    }

  }

  async componentDidUpdate(prevProps) {
    const { address, dispatch/* , tokenloader */ } = this.props;
    if (address && address !== prevProps.address) {
      dispatch(loadTokens());
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const balanceInWei = await provider.getBalance(address);
    const balanceInEth = ethers.utils.formatEther(balanceInWei);
    await dispatch(getbalance(balanceInEth))

    // if (localStorage.getItem("loader") === JSON.stringify(this.state.loader)) {
    //   this.setState({ changeload: false })
    // } else {
    //   this.setState({ loader: tokenloader })
    // }
  }
  addTokentoMetamask = async (address, token, decimals, image) => {
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: address, // The address that the token is at.
            symbol: token, // A ticker symbol or shorthand, up to 5 chars.
            decimals: decimals, // The number of decimals in the token
            image: image, // give image path 
          },
        },
      });

      if (wasAdded) {
        toast.success('Token added successfully');
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
  render() {
    const { tokensInfo, ethBalance } = this.props;
    return (
      <div className="flex-column">
        <div className="flex flex-1 align-items-center">
          <div className="col-6 tokenclr pl-0" style={{ lineHeight: '31px' }}>{env.MAINTICKER}</div>
          <div className="col-6 text-right tokenclr">{ethBalance.toFixed(5)}</div>
        </div>
        {tokensInfo.toArray().map(([token, info], index) => {
          const { address, balance, /* allowance, */ decimals, lockedBalance } = info.toJS();
          // const isApproved = isTokenApproved(allowance);
          const availableBalance = toUnitAmount(BigNumber.max(balance.minus(lockedBalance), '0'), decimals).toFixed(5);
          const toolTipTitle = `<div>In-Order: ${toUnitAmount(lockedBalance, decimals).toFixed(
            5
          )}</div><div>Total: ${toUnitAmount(balance, decimals).toFixed(5)}</div>`;
          return (
            <div key={token} className="flex flex-1 tokenborder pt-2 pb-1">
              <div className="flex-column col-8 p-0">
                <div className='alltokenclr'><i className='fa fa-plus-circle plusicon' style={{ color: "var(--theme-clr)", cursor: 'pointer' }} onClick={() => this.addTokentoMetamask(address, token, decimals, `${env.BASEURL}/uploads/${token == "Wrapped VII" ? "VII" : token}.png`)}></i><span className='ml-2'>{token}</span></div>
                {/* <div className="tokenclr">{isApproved ? 'Enabled' : 'Disabled'}</div> */}
              </div>
              <div className="col-4 text-right">
                <div
                  className="flex-column text-themeclr"
                  style={{ fontWeight: '600', cursor: "pointer" }}
                  key={toolTipTitle}
                  data-html="true"
                  data-toggle="tooltip"
                  data-placement="right"
                  title={toolTipTitle}
                  ref={ref => window.$(ref).tooltip()}>
                  {availableBalance}
                </div>
                {/* {this.state.loader && this.state.loader[index]? <i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>:
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={address}
                    checked={isApproved}
                    onChange={() => {
                      this.changeswitch(address, token, decimals, toast, isApproved, index)
                    }}
                  />
                  <label className="custom-control-label" htmlFor={address} />
                </div>
                } */}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  // async changeswitch(address, token, decimals, toast, isApproved, index) {
  //   const { dispatch } = this.props;
  //   let setloader = this.state.loader
  //   setloader[index] = true

  //   this.setState({ loader: setloader, changeload: true })
  //   localStorage.setItem("loader", JSON.stringify(this.state.loader))

  //   if (isApproved) {
  //     try {
  //       await dispatch(disable(address, token, decimals, toast));
  //       let falseloader = this.state.loader
  //       falseloader[index] = false
  //       localStorage.setItem("loader", JSON.stringify(falseloader))
  //       this.setState({ loader: falseloader, changeload: true })

  //     } catch (error) {
  //       let falseloader = this.state.loader
  //       falseloader[index] = false
  //       localStorage.setItem("loader", JSON.stringify(falseloader))
  //       this.setState({ loader: falseloader, changeload: true })
  //     }
  //   } else {

  //     try {
  //       await dispatch(enable(address, token, decimals, toast));
  //       let falseloader = this.state.loader
  //       falseloader[index] = false
  //       localStorage.setItem("loader", JSON.stringify(falseloader))
  //       this.setState({ loader: falseloader, changeload: true })

  //     } catch (error) {
  //       let falseloader = this.state.loader
  //       falseloader[index] = false
  //       localStorage.setItem("loader", JSON.stringify(falseloader))
  //       this.setState({ loader: falseloader, changeload: true })
  //     }
  //   }
  // }
}

export default connect(mapStateToProps)(Tokens);
