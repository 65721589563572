"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  result["default"] = mod;
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var React = __importStar(require("react"));
var PasswordInput = function (_super) {
  __extends(PasswordInput, _super);
  function PasswordInput() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  PasswordInput.prototype.render = function () {
    var _a = this.props,
      handleChange = _a.handleChange,
      text = _a.text,
      label = _a.label,
      error = _a.error,
      errorMsg = _a.errorMsg,
      type = _a.type;
    return React.createElement("div", {
      className: "RainConnect-fieldGroup fieldgroup"
    }, React.createElement("div", {
      className: "RainConnect-labelGroup"
    },
    // React.createElement("div", { className: "RainConnect-label" }, label),
    React.createElement("div", {
      className: "RainConnect-errorMsg"
    }, errorMsg)), React.createElement("input", {
      className: "RainConnect-input" + (error ? " RainConnect-error" : ""),
      placeholder: label,
      type: type || "password",
      value: text,
      onChange: function onChange(e) {
        return handleChange(e.target.value);
      }
    }));
  };
  return PasswordInput;
}(React.PureComponent);
exports.default = PasswordInput;