"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var immutable_1 = require("immutable");
var bignumber_js_1 = require("bignumber.js");
var wallet_1 = require("../actions/wallet");
exports.initializeAccount = immutable_1.Map({
  address: null,
  balance: new bignumber_js_1.BigNumber("0"),
  isLocked: true,
  networkId: null,
  wallet: null
});
var initialState = immutable_1.fromJS({
  accounts: immutable_1.Map(),
  selectedAccountID: null,
  selectedWalletType: "",
  extensionWalletSupported: false,
  connecting: immutable_1.Map(),
  isShowWalletModal: false,
  step: wallet_1.WALLET_STEPS.SELECT,
  walletCache: null,
  walletTranslations: {},
  LocalWallet: null,
  unit: "ETH",
  decimals: 18
});
exports.default = function (state, action) {
  if (state === void 0) {
    state = initialState;
  }
  switch (action.type) {
    case "RAIN_WALLET_SET_UNIT":
      state = state.set("unit", action.payload.unit);
      state = state.set("decimals", action.payload.decimals);
      return state;
    case "RAIN_WALLET_INIT_CUSTOM_LOCAL_WALLET":
      state = state.set("LocalWallet", action.payload.walletClass);
      return state;
    case "RAIN_WALLET_SELECT_WALLET_TYPE":
      state = state.set("selectedWalletType", action.payload.type);
      return state;
    case "RAIN_WALLET_SET_TRANSLATIONS":
      state = state.set("walletTranslations", action.payload.translations);
      return state;
    case "RAIN_WALLET_CONNECT_WALLET_FINISHED":
      state = state.setIn(["connecting", action.payload.type], false);
      return state;
    case "RAIN_WALLET_CONNECT_WALLET":
      state = state.setIn(["connecting", action.payload.type], true);
      return state;
    case "RAIN_WALLET_DELETE_ACCOUNT":
      state = state.removeIn(["accounts", action.payload.accountID]);
      return state;
    case "RAIN_WALLET_CACHE_WALLET":
      state = state.set("walletCache", action.payload);
      return state;
    case "RAIN_WALLET_SET_STEP":
      state = state.set("step", action.payload.step);
      return state;
    case "RAIN_WALLET_INIT_ACCOUNT":
      state = state.setIn(["accounts", action.payload.accountID], exports.initializeAccount);
      state = state.setIn(["accounts", action.payload.accountID, "wallet"], action.payload.wallet);
      return state;
    case "RAIN_WALLET_UPDATE_WALLET":
      var wallet = action.payload.wallet;
      state = state.setIn(["accounts", wallet.id(), "wallet"], wallet);
      return state;
    case "RAIN_WALLET_SHOW_DIALOG":
      state = state.set("isShowWalletModal", true);
      return state;
    case "RAIN_WALLET_HIDE_DIALOG":
      state = state.set("isShowWalletModal", false);
      return state;
    case "RAIN_WALLET_LOCK_ACCOUNT":
      state = state.setIn(["accounts", action.payload.accountID, "isLocked"], true);
      return state;
    case "RAIN_WALLET_UNLOCK_ACCOUNT":
      state = state.setIn(["accounts", action.payload.accountID, "isLocked"], false);
      return state;
    case "RAIN_WALLET_LOAD_ADDRESS":
      state = state.setIn(["accounts", action.payload.accountID, "address"], action.payload.address);
      return state;
    case "RAIN_WALLET_LOAD_BALANCE":
      state = state.setIn(["accounts", action.payload.accountID, "balance"], new bignumber_js_1.BigNumber(String(action.payload.balance)));
      return state;
    case "RAIN_WALLET_SELECT_ACCOUNT":
      state = state.set("selectedAccountID", action.payload.accountID);
      return state;
    case "RAIN_WALLET_SUPPORT_EXTENSION_WALLET":
      state = state.set("extensionWalletSupported", true);
      return state;
    case "RAIN_WALLET_LOAD_NETWORK":
      state = state.setIn(["accounts", action.payload.accountID, "networkId"], action.payload.networkId);
      return state;
    default:
      return state;
  }
};