import React from 'react';
import { loginRequest, login, loadTokens } from '../../actions/account';
import { setlightmode, setdarkmode } from '../../actions/modeaction'
import { connect } from 'react-redux';
import { WalletButton, getSelectedAccount } from '@gongddex/hydro-sdk-wallet';
import './styles.scss';
import { loadAccountHydroAuthentication } from '../../lib/session';
import MediaQuery from 'react-responsive';
import BigNumber from 'bignumber.js';
import abi from '../../lib/abi';
import abiWVII from '../../lib/abiWVII';
import env from '../../lib/env';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { setloader } from '../../actions/loader'
import { getbalance } from '../../actions/ethbalance'
import { toUnitAmount } from '../../lib/utils';
import { stateUtils } from '../../selectors/account';
var wallets_1 = require("@gongddex/hydro-sdk-wallet");

const data = require('../../data/chaindata.json');

const mapStateToProps = state => {
    const selectedAccount = getSelectedAccount(state);
    const selectedAccountID = state.WalletReducer.get('selectedAccountID');
    const address = selectedAccount ? selectedAccount.get('address') : null;
    let tokenbalance = 0
    stateUtils.getTokensInfo(state, address).toArray().map(([token, info]) => {
        const { balance, decimals, lockedBalance } = info.toJS();
        if (env.BASETOKENNAME === token) {
            tokenbalance = toUnitAmount(BigNumber.max(balance.minus(lockedBalance), '0'), decimals).toFixed(5);
        }
        return true
    })
    return {
        address,
        selectedAccountID,
        isLocked: selectedAccount ? selectedAccount.get('isLocked') : true,
        isLoggedIn: state.account.getIn(['isLoggedIn', address]),
        currentMarket: state.market.getIn(['markets', 'currentMarket']),
        markets: state.market.getIn(['markets', 'data']),
        networkId: state.WalletReducer.getIn(['accounts', selectedAccountID, 'networkId']),
        lightmode: state.mode,
        loader: state.loader,
        ethBalance: Number(state.ethbalance),
        tokenbalance
    };
};

class Header extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            networks: [],
            fromchain: { name: 'Select Network', img: '' },
            tochain: { name: 'Select Network', img: '' },
            token: [],
            tokenname: { name: 'Select Token', img: '' },
            amount: '',
            fromselectobj: {},
            tokenobj: {},
            tokenbalance: 0,
            loader: false,
            swaploader: false,
            swapamount: '',
            fromswapobjname: { name: 'Select', image: '' },
            toswapobjname: { name: 'Swap To', image: '' },
            swaptokenbalance: 0,
        };
        this.coinstate = {
            mixdata: []
        }
    }
    componentDidMount() {
        const { address, dispatch, lightmode } = this.props;
        const viiAuthentication = loadAccountHydroAuthentication(address);
        if (viiAuthentication) {
            dispatch(login(address));
        }
        let networks = JSON.parse(env.NETWORKS)
        let filternetwork = data.filter((x) => { return networks.includes(x.ticker) })
        this.setState({ networks: filternetwork })

        let basecoin = env.BASECOIN
        let basetoken = JSON.parse(env.BASETOKEN)
        let filtercoin = data.find((x) => { return x.ticker === basecoin })
        let finalcoin = {
            chainName: filtercoin.chainName,
            ticker: filtercoin.ticker,
            image: filtercoin.image,
            name: `${env.BASECOINNAME}(${env.BASECOIN})`
        }

        let tokenobj = filtercoin.tokens.find((x) => { return basetoken.includes(x.ticker) })
        let finaltokenobj = {
            ...tokenobj,
            name: `${env.BASETOKENNAME}(${basetoken[0]})`
        }
        let mixdata = []
        mixdata.push(finalcoin, finaltokenobj)
        this.setState(this.coinstate.mixdata = mixdata)


        const root = document.querySelector(':root');
        root.style.setProperty('--white-change', lightmode.lightmode ? '#FFFFFF' : '#000000');
        root.style.setProperty('--black-change', lightmode.lightmode ? '#000000' : '#FFFFFF');
        root.style.setProperty('--back-clr', lightmode.lightmode ? '#FFFBF0' : '#373531');
        root.style.setProperty('--redfont-clr', lightmode.lightmode ? '#D00A16' : '#FE544C');
        root.style.setProperty('--title-clr', lightmode.lightmode ? '#737373' : '#FFFFFF');
        root.style.setProperty('--white-change-backclr', lightmode.lightmode ? '#FFFFFF' : '#1B1B1B');
        root.style.setProperty('--card-title-bdr', lightmode.lightmode ? '#E3E2E2' : '#403F3F');
        root.style.setProperty('--input-back-clr', lightmode.lightmode ? '#F0F0F0' : '#2B2B2B');
        root.style.setProperty('--active-btn-clr', lightmode.lightmode ? '#FEFAEF' : '#373531');
        root.style.setProperty('--sellbtn-clr', lightmode.lightmode ? '#E75C56' : '#FE544C');
        root.style.setProperty('--token-switch-clr', lightmode.lightmode ? '#D9D9D9' : '#373531');
        root.style.setProperty('--model-selected-back', lightmode.lightmode ? '#F0F0F0' : '#373531');
        root.style.setProperty('--asks-back-clr', lightmode.lightmode ? '#FBE7E8' : '#5F2C2A');
        root.style.setProperty('--bids-back-clr', lightmode.lightmode ? '#F8F6F1' : '#2A2823');
        root.style.setProperty('--buybtn-clr', lightmode.lightmode ? '#00955B' : '#04B771');
    }
    async componentDidUpdate(prevProps) {
        const { address, dispatch, lightmode, selectedAccountID, networkId } = this.props;
        const { tokenname, tokenobj } = this.state;

        const viiAuthentication = loadAccountHydroAuthentication(address);
        if (address !== prevProps.address && viiAuthentication) {
            dispatch(login(address));
        }
        localStorage.setItem('mode', lightmode.lightmode);
        const root = document.querySelector(':root');
        root.style.setProperty('--white-change', lightmode.lightmode ? '#FFFFFF' : '#000000');
        root.style.setProperty('--black-change', lightmode.lightmode ? '#000000' : '#FFFFFF');
        root.style.setProperty('--back-clr', lightmode.lightmode ? '#FFFBF0' : '#373531');
        root.style.setProperty('--redfont-clr', lightmode.lightmode ? '#D00A16' : '#FE544C');
        root.style.setProperty('--title-clr', lightmode.lightmode ? '#737373' : '#FFFFFF');
        root.style.setProperty('--white-change-backclr', lightmode.lightmode ? '#FFFFFF' : '#1B1B1B');
        root.style.setProperty('--card-title-bdr', lightmode.lightmode ? '#E3E2E2' : '#403F3F');
        root.style.setProperty('--input-back-clr', lightmode.lightmode ? '#F0F0F0' : '#2B2B2B');
        root.style.setProperty('--active-btn-clr', lightmode.lightmode ? '#FEFAEF' : '#373531');
        root.style.setProperty('--sellbtn-clr', lightmode.lightmode ? '#E75C56' : '#FE544C');
        root.style.setProperty('--token-switch-clr', lightmode.lightmode ? '#D9D9D9' : '#373531');
        root.style.setProperty('--model-selected-back', lightmode.lightmode ? '#F0F0F0' : '#373531');
        root.style.setProperty('--asks-back-clr', lightmode.lightmode ? '#FBE7E8' : '#5F2C2A');
        root.style.setProperty('--bids-back-clr', lightmode.lightmode ? '#F8F6F1' : '#2A2823');
        root.style.setProperty('--buybtn-clr', lightmode.lightmode ? '#00955B' : '#04B771');

        if (selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) && tokenname.name !== 'Select Token') {
            const balance = await this.getTokenBalance(tokenobj.tokenAddress, address)
            this.setState({ tokenbalance: Number(balance) / `1e${tokenobj.precision}` })
        }
    }
    getNetworkName() {
        switch (parseInt(env.NETWORK_ID, 10)) {
            case 1:
                return 'Mainnet';
            case 3:
                return 'Ropsten';
            case 4:
                return 'Rinkeby';
            case 66:
                return 'localhost:8545';
            default:
                return 'id: ' + env.NETWORK_ID;
        }
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={908}>{this.renderDesktop()}</MediaQuery>
                <MediaQuery minWidth={768} maxWidth={907}>{this.rendermidTab()}</MediaQuery>
                <MediaQuery minWidth={534} maxWidth={767}>{this.renderTablet()}</MediaQuery>
                <MediaQuery maxWidth={533}>{this.renderMobile()}</MediaQuery>
            </>
        );
    }

    switchchain = async () => {
        const { dispatch, selectedAccountID } = this.props;
        if (window.ethereum) {
            dispatch(setloader(true))
            let obj = data.find((x) => x.ticker === env.MAINTICKER)
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: `0x${obj.chainId.toString(16)}` }],
                });
                dispatch(setloader(false))
                dispatch(wallets_1.selectAccount(selectedAccountID, selectedAccountID));
                window.location.reload()  //if network switch but can't fetch functionality then uncommment line 
            } catch (error) {
                if (error.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [{
                                chainId: `0x${obj.chainId.toString(16)}`,
                                chainName: obj.chainName,
                                nativeCurrency: {
                                    name: obj.ticker,
                                    symbol: obj.ticker,
                                    decimals: obj.precision
                                },
                                rpcUrls: [obj.rpcUrl],
                                blockExplorerUrls: [obj.blockExplorerURL]
                            }]
                        });
                        dispatch(setloader(false))
                    } catch (addError) {
                        console.error(addError);
                        dispatch(setloader(false))
                    }
                }
                console.error(error);
                dispatch(setloader(false))
            }
        }
    };

    switchnetwork = async () => {
        const { selectedAccountID, dispatch } = this.props
        const { fromchain, fromselectobj } = this.state;

        if (fromchain.name !== '' && fromchain.name !== 'Select Network') {
            let selectedchain = data.find((post) => { return post.chainName === fromchain.name });
            if (window.ethereum) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: `0x${selectedchain.chainId.toString(16)}` }],
                    });
                    // var wallettype = account.get("wallet")
                    dispatch(wallets_1.selectAccount(selectedAccountID, selectedAccountID));
                    // window.location.reload()  //if in bridge modal click on network switch but can't switch network then uncommment line
                    let tokenfilter = fromselectobj.tokens.filter(x => { return JSON.parse(env.TOKENS).includes(x.ticker) })
                    this.setState({ token: tokenfilter, tokenname: { name: 'Select Token', img: '' }, amount: '', tokenobj: {}, tokenbalance: 0 })
                } catch (error) {
                    if (error.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    chainId: `0x${selectedchain.chainId.toString(16)}`,
                                    chainName: `${selectedchain.chainName}`,
                                    nativeCurrency: {
                                        name: `${selectedchain.chainName}`,
                                        symbol: `${selectedchain.ticker}`,
                                        decimals: selectedchain.precision
                                    },
                                    rpcUrls: [`${selectedchain.rpcUrl}`],
                                    blockExplorerUrls: [`${selectedchain.blockExplorerURL}`]
                                }]
                            });
                            let tokenfilter = fromselectobj.tokens.filter(x => { return JSON.parse(env.TOKENS).includes(x.ticker) })
                            this.setState({ token: tokenfilter, tokenname: { name: 'Select Token', img: '' }, amount: '', tokenobj: {}, tokenbalance: 0 })
                        } catch (addError) {
                            console.error(addError);
                        }
                    }
                    console.error(error);
                }
            }
        }
    };

    getTokenBalance = async (tokenAddress, accountAddress) => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(tokenAddress, abi, provider);
            const balance = await contract.balanceOf(accountAddress);
            return new BigNumber(balance);
        } catch (error) {
            console.error('Error fetching token balance:', error);
        }
    };


    fromselect = (post) => {
        const { networkId } = this.props;
        const { tochain } = this.state;

        let obj = data.find((x) => x.ticker === env.MAINTICKER)
        if (post.ticker !== env.MAINTICKER) {
            this.setState({ fromchain: { name: post.chainName, img: post.image }, tochain: { name: obj.chainName, img: obj.image }, fromselectobj: post, tokenname: { name: 'Select Token', img: '' } })
        } else if (tochain.name === obj.chainName) {
            this.setState({ fromchain: { name: post.chainName, img: post.image }, tochain: { name: 'Select Network', img: '' }, fromselectobj: post, tokenname: { name: 'Select Token', img: '' } })
        } else {
            this.setState({ fromchain: { name: post.chainName, img: post.image }, fromselectobj: post, tokenname: { name: 'Select Token', img: '' } })
        }
        if (post.chainId === parseInt(networkId, 10)) {
            let tokenfilter = post.tokens.filter(x => { return JSON.parse(env.TOKENS).includes(x.ticker) })
            this.setState({ token: tokenfilter })
        } else {
            this.setState({ token: [] })
        }
    }
    toselect = (post) => {
        const { networkId } = this.props;
        const { fromchain } = this.state;

        let obj = data.find((x) => x.ticker === env.MAINTICKER)
        if (post.ticker !== env.MAINTICKER) {
            this.setState({ tochain: { name: post.chainName, img: post.image }, fromchain: { name: obj.chainName, img: obj.image }, fromselectobj: obj, tokenname: { name: 'Select Token', img: '' } })
            if (obj.chainId === parseInt(networkId, 10)) {
                this.setState({ tokenobj: {}, tokenbalance: 0 })
            } else {
                this.setState({ token: [], tokenobj: {}, tokenbalance: 0 })
            }
        } else if (fromchain.name === obj.chainName) {
            this.setState({ tochain: { name: post.chainName, img: post.image }, fromchain: { name: 'Select Network', img: '' }, token: [], fromselectobj: {}, tokenname: { name: 'Select Token', img: '' }, tokenobj: {}, tokenbalance: 0 })
        } else {
            this.setState({ tochain: { name: post.chainName, img: post.image }, tokenname: { name: 'Select Token', img: '' } })
        }
    }
    swapfromselect = async (post) => {
        // let { ethBalance, address } = this.props
        // let basetoken = JSON.parse(env.BASETOKEN)

        // if (basetoken.length < 2) {   
        let anotherobj = this.coinstate.mixdata.find((x) => { return post.ticker !== x.ticker })
        this.setState({ fromswapobjname: post, toswapobjname: anotherobj })
        // if (post.ticker === env.BASECOIN) {
        //   this.setState({ fromswapobjname: post, toswapobjname: anotherobj, swaptokenbalance: ethBalance.toFixed(5) })
        // } else {
        // let balance = await this.getTokenBalance(post.tokenAddress, address)
        // this.setState({ fromswapobjname: post, toswapobjname: anotherobj, swaptokenbalance: Number(balance) / `1e${post.precision}` })
        // }
        // } else {
        //   this.setState(this.coinstate.fromobjname = post)
        //   this.setState(this.coinstate.toobjname = { ticker: 'Select', image: '' })
        // }
    }
    // swaptoselect = (post) =>{
    //   let basetoken = JSON.parse(env.BASETOKEN)

    //   if (basetoken.length < 2) {
    //     let anotherobj = this.coinstate.mixdata.find((x) => { return post.ticker !== x.ticker })
    //     console.log("functiopn call",anotherobj)
    //     this.setState(this.coinstate.toobjname = { ticker: post.ticker, image: post.image })
    //     this.setState(this.coinstate.fromobjname = { ticker: anotherobj.ticker, image: anotherobj.image })
    //   } else {
    //     this.setState(this.coinstate.toobjname = { ticker: post.ticker, image: post.image })
    //     this.setState(this.coinstate.fromobjname = { ticker: 'Select', image: '' })
    //   }
    // }
    settoken = async (post) => {
        const { address } = this.props;
        let balance = await this.getTokenBalance(post.tokenAddress, address)
        if (balance) {
            this.setState({ tokenname: { name: post.ticker, img: post.image }, tokenobj: post, tokenbalance: Number(balance) / `1e${post.precision}` })
        }
    }
    bignumprint = (str) => {
        return str.toLocaleString('fullwide', { useGrouping: false })
    }
    relayToken = async (tokenAddress, foreignMediatorAddress, precision, amount) => {
        try {
            const allowance = "0xf000000000000000000000000000000000000000000000000000000000000000"
            const { address } = this.props;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await window.ethereum.enable();
            const signer = provider.getSigner()
            const contract = new ethers.Contract(tokenAddress, abi, signer);
            const balance = await contract.allowance(address, foreignMediatorAddress);
            const allowanceNumber = new BigNumber(balance) / `1e${precision}`
            const getRelayContract = new ethers.Contract(foreignMediatorAddress, abi, signer);
            let hash;
            if (allowanceNumber < amount) {
                const approveTx = await contract.approve(foreignMediatorAddress, allowance);
                let tnxHash = await approveTx.wait(); // Wait for the transaction to be mined
                if (tnxHash) {
                    hash = await getRelayContract.relayTokens(tokenAddress, this.bignumprint(amount * `1e${precision}`))
                    if (hash) {
                        toast.success("Bridge request successfully sent")
                        this.setState({ loader: false })
                        document.getElementById('modelclose').click();
                    } else {
                        toast.error("Bridge request failed")
                        this.setState({ loader: false })
                    }
                }
            } else {
                hash = await getRelayContract.relayTokens(tokenAddress, this.bignumprint(amount * `1e${precision}`))
                if (hash) {
                    this.setState({ loader: false })
                    toast.success("Bridge request successfully sent")
                    document.getElementById('modelclose').click();
                } else {
                    toast.error("Bridge request failed")
                    this.setState({ loader: false })
                }
            }
        } catch (error) {
            this.setState({ loader: false })
            toast.error(error.message)
        }


    }
    TransferAndCall = async (tokenAddress, homeMediatorAddress, precision, amount) => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await window.ethereum.enable();
            const signer = provider.getSigner()
            let transferAndCallContract = new ethers.Contract(tokenAddress, abi, signer);
            let hash = await transferAndCallContract.transferAndCall(homeMediatorAddress, this.bignumprint(amount * `1e${precision}`), "0x")
            if (hash) {
                toast.success("Bridge request successfully sent")
                this.setState({ loader: false })
                document.getElementById('modelclose').click();
            } else {
                toast.error("Bridge request failed")
                this.setState({ loader: false })
            }
        } catch (error) {
            toast.error(error.message)
            this.setState({ loader: false })

        }
    }

    submitbridge = () => {
        let { fromselectobj, amount, tokenbalance, tokenobj, tochain } = this.state
        this.setState({ loader: true })
        if (Number(amount) > 1500000 || Number(amount) < 0.5) {
            toast.error("Please enter amount between 0.5 to 1.5M")
            this.setState({ loader: false })
        }
        if (Object.keys(tokenobj).length > 0 && Object.keys(tochain).length > 0 && fromselectobj.ticker === env.MAINTICKER && amount !== '' && amount !== 0 && amount <= tokenbalance && Number(amount) <= 1500000 && Number(amount) >= 0.5) {
            this.TransferAndCall(tokenobj.tokenAddress, fromselectobj.homeMediatorAddress, tokenobj.precision, Number(amount))
        }
        if (Object.keys(tokenobj).length > 0 && Object.keys(tochain).length > 0 && fromselectobj.ticker !== env.MAINTICKER && amount !== '' && amount !== 0 && amount <= tokenbalance && Number(amount) <= 1500000 && Number(amount) >= 0.5) {
            this.relayToken(tokenobj.tokenAddress, fromselectobj.foreignMediatorAddress, tokenobj.precision, Number(amount))
        }
    }

    checkNativeCurrencyBalance = async (address, requiredAmount) => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const balanceInWei = await provider.getBalance(address);
            const balanceInEth = ethers.utils.formatEther(balanceInWei);
            return parseFloat(balanceInEth) >= requiredAmount;
        } catch (error) {
            toast.error('Error fetching balance:', error);
            return false;
        }
    };
    checkTokenBalance = async (tokenAddress, accountAddress, requiredAmount) => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(tokenAddress, abiWVII, provider);
            const balance = await contract.balanceOf(accountAddress);
            const balanceInBigNumber = new BigNumber(balance);
            return balanceInBigNumber.gte(requiredAmount);
        } catch (error) {
            toast.error('Error fetching token balance:', error);
            return false;
        }
    };
    getetherbalance = async () => {
        const { address, dispatch } = this.props
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const balanceInWei = await provider.getBalance(address);
        const balanceInEth = ethers.utils.formatEther(balanceInWei);
        dispatch(getbalance(balanceInEth));
    }
    DepositeMethod = async (tokenAddress, amount) => {
        try {
            const { address, dispatch } = this.props;
            const requiredAmountInEth = parseFloat(amount);
            const sufficientBalance = await this.checkNativeCurrencyBalance(address, requiredAmountInEth);
            if (!sufficientBalance) {
                toast.error("Error: Insufficient balance in native currency");
                this.setState({ swaploader: false })
                return;
            }
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await window.ethereum.enable();
            const signer = provider.getSigner();
            const contract = new ethers.Contract(tokenAddress, abiWVII, signer);
            const transaction = await contract.deposit({ value: ethers.utils.parseEther(amount) })
            if (transaction.hash) {
                toast.success("Swap request sent successfully ", { autoClose: 1500 })
                document.getElementById('swapmodelclose').click();
            }
            const txResponse = await transaction.wait();
            if (txResponse.transactionHash) {
                toast.success("Swap successfull ", { autoClose: 2000 })
                dispatch(loadTokens());
                this.getetherbalance()
                this.setState({ swaploader: false })
            }
        } catch (error) {
            toast.error(error.message)
            this.setState({ swaploader: false })
        }
    }

    WithdrawMethod = async (tokenAddress, amount) => {
        try {
            const { address, dispatch } = this.props;
            const requiredAmountInEth = parseFloat(amount);
            const sufficientBalance = await this.checkTokenBalance(tokenAddress, address, requiredAmountInEth);
            if (!sufficientBalance) {
                toast.error("Error: Insufficient token balance");
                this.setState({ swaploader: false })
                return;
            }
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await window.ethereum.enable();
            const signer = provider.getSigner();
            const contract = new ethers.Contract(tokenAddress, abiWVII, signer);
            const transaction = await contract.withdraw(ethers.utils.parseEther(amount));
            if (transaction.hash) {
                toast.success("Swap request sent successfully ", { autoClose: 1500 })
                document.getElementById('swapmodelclose').click();
            }
            const txResponse = await transaction.wait();
            if (txResponse.transactionHash) {
                toast.success("Swap successfull ", { autoClose: 2000 })
                dispatch(loadTokens());
                this.getetherbalance()
                this.setState({ swaploader: false })
            }
        } catch (error) {
            toast.error(error.message)
            this.setState({ swaploader: false })
        }
    }

    submitswap = () => {
        let { fromswapobjname, toswapobjname, swapamount } = this.state
        this.setState({ swaploader: true })

        if (fromswapobjname.ticker === env.BASECOIN && Number(swapamount) > 0) {
            this.DepositeMethod(toswapobjname.tokenAddress, swapamount)
        }
        else if (fromswapobjname.ticker !== env.BASECOIN && Number(swapamount) > 0) {
            this.WithdrawMethod(fromswapobjname.tokenAddress, swapamount)
        }
        else {
            toast.error("Amount should not be 0")
            this.setState({ swaploader: false })
        }
    }

    renderDesktop() {
        const { dispatch, lightmode, selectedAccountID, networkId, loader, address, ethBalance, tokenbalance } = this.props;
        const { networks, fromchain, tochain, token, tokenname, amount, fromselectobj, tokenobj, swaploader, swapamount, fromswapobjname, toswapobjname } = this.state;
        return (
            <>
                <div className={lightmode.lightmode ? "navbar bg-black navbar-expand-lg" : "navbar bg-black navbar-expand-lg navbarwhite"}>
                    <img className="navbar-brand" src={lightmode.lightmode ? require('../../images/Light-theme.png') : require('../../images/Dark-theme.png')} alt="hydro" style={{
                        height: "80px",
                        width: "100px",
                    }} />
                    <div className=" navbar-nav mr-auto" style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'transparent' }}>
                        <button
                            className="btn btn-primary header-dropdown mr-4"
                            type="button"
                            data-toggle="modal" data-target="#exampleModal">
                            Bridge
                        </button>
                        <button
                            className="btn btn-primary header-dropdown  mr-4"
                            type="button"
                            data-toggle="modal" data-target="#swapModal">
                            Swap
                        </button>
                        <a href={env.BRIDGE_URL} target="blank">
                            <span
                                className="btn btn-primary header-dropdown "
                                type="button"
                                title="Get token">
                                Deposit
                            </span>
                        </a>
                    </div>
                    <button
                        className="btn header-dropdown togglemodebtn mr-4"
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ display: 'flex', alignItems: "center" }}>
                        <i className={!lightmode.lightmode ? "fa fa-moon-o fa-2x mr-2 toglemodeicon togglemodeactive" : "fa fa-moon-o fa-2x mr-2 toglemodeicon togglemodenotactive"} onClick={() => { dispatch(setdarkmode()) }} aria-hidden="true"></i>
                        <i className={!lightmode.lightmode ? "fa fa-sun-o fa-2x toglemodeicon togglemodenotactive " : "fa fa-sun-o fa-2x toglemodeicon togglemodeactive"} onClick={() => { dispatch(setlightmode()) }} aria-hidden="true"></i>
                    </button>
                    {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && (
                        !loader ? <button className="btn btn-primary mr-4" onClick={() => this.switchchain()}>Switch Network</button>
                            : <button className="btn btn-primary mr-4  disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>
                    )}
                    {/* <button
                        className="btn btn-primary collapse-toggle d-block"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbar-collapse"
                        aria-expanded="true">
                        <i className="fa fa-bars" />
                        </button> */}
                    {/* <div className="collapse" id="navbar-collapse"> */}
                    <div className="item">
                        <WalletButton />
                    </div>
                    {this.renderAccount()}
                    {/* </div> */}
                </div>
                <div className="modal fade bridgemodal" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered  modal-lg p-2" role="document">
                        <div className="modal-content modelback" style={{ border: '0' }}>
                            <div className="RainConnect-title" style={{ fontSize: '30px' }}>
                                Bridge Tokens
                                <span className='closemodelspan' id="modelclose" data-dismiss="modal" onClick={() => this.setState({
                                    fromchain: { name: 'Select Network', img: '' },
                                    tochain: { name: 'Select Network', img: '' },
                                    token: [],
                                    tokenname: { name: 'Select Token', img: '' },
                                    amount: '',
                                    fromselectobj: {},
                                    tokenobj: {},
                                    tokenbalance: 0
                                })}>

                                    <i className='fa fa-close closemodelicon'></i>
                                </span>
                            </div>
                            <div className="text-black text-center">
                                Transfer your tokens from one network to another.
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            From
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ fontSize: '17px' }} className='textcapital'>{fromchain.img !== '' && <img src={`${fromchain.img}?v1.1.0`} className='selectcoinimg mr-2' alt="chainlogo" />}{fromchain.name}</span>
                                                </button>
                                                <div className={networks.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton1">
                                                    {networks.length > 0 && networks.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.fromselect(post)}><img src={`${post.image}?v1.1.0`} className='selectcoinimg mr-2' alt="chain-logo" /><span className='textcapital'>{post.chainName}</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-black fs-16 pt-2">
                                            {selectedAccountID === 'EXTENSION' && Object.keys(fromselectobj).length > 0 && fromselectobj.chainId !== parseInt(networkId, 10) &&
                                                <span className='text-themeclr' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.switchnetwork()}>
                                                    Switch Network
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            To
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ fontSize: '17px' }} className='textcapital'>{tochain.img !== '' && <img src={`${tochain.img}?v1.1.0`} className='selectcoinimg mr-2' alt="tochainlogo" />}{tochain.name}</span>
                                                </button>
                                                <div className={networks.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton">
                                                    {networks.length > 0 && networks.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.toselect(post)}><img src={`${post.image}?v1.1.0`} className='selectcoinimg mr-2' alt="tochain-logo" /><span className='textcapital'>{post.chainName}</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row pt-4 pb-4'>
                                    <div className='col-6'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            Tokens
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ fontSize: '17px' }}>{tokenname.img !== '' && <img src={`${tokenname.img}?v1.1.0`} className='selectcoinimg mr-2' alt="ticker" />}{tokenname.name}</span>
                                                </button>
                                                <div className={token.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton">
                                                    {token.length > 0 ? token.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.settoken(post)}><img src={`${post.image}?v1.1.0`} className='selectcoinimg mr-2' alt="ticker1" /><span>{post.ticker}</span></div>
                                                    }) : Object.keys(fromselectobj).length > 0 && fromselectobj.chainId !== parseInt(networkId, 10) ? <div className="dropdown-item list-item" >Switch Network</div> : <div className="dropdown-item list-item" >Please select network</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="d-flex fs-16 fw-600 justify-content-between pb-2 text-black">
                                            <span>Amount</span>
                                            <span>Balance: {this.state.tokenbalance}&nbsp;{tokenobj.ticker}</span>
                                        </div>
                                        <input className="form-control inputfield modelinput pl-3" type="text" style={{ fontSize: '17px' }} placeholder="Enter Amount" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); }} onChange={(e) => this.setState({ amount: e.target.value })} value={amount} />
                                    </div>
                                </div>
                                {Object.keys(tokenobj).length > 0 && amount > this.state.tokenbalance && <div style={{ textAlign: 'center' }}><span className='text-danger insufficientbalance p-2'><i className='RainConnect-fa fa fa-exclamation-triangle'></i>&nbsp;Insufficient token balance !!!!</span></div>}
                                {
                                    fromchain.name !== 'Select Network' && tochain.name !== 'Select Network' && amount !== '' && Object.keys(tokenobj).length > 0 && amount <= this.state.tokenbalance &&
                                    <div className='row pt-4'>
                                        <div className='col-12 text-black mt-2'>
                                            <div className='preview p-3 d-flex'>
                                                <div className='d-flex flex-column justify-content-between mr-3'>
                                                    <img src={`${fromchain.img}?v1.1.0`} className='selectcoinimg' alt='coin' />
                                                    <span style={{ transform: 'rotate(90deg)' }}>----</span>
                                                    <img src={`${tochain.img}?v1.1.0`} className='selectcoinimg' alt='coin' />
                                                </div>
                                                <div className='w-100'>
                                                    <div className='d-flex justify-content-between mb-3'>
                                                        <span>
                                                            You pay on <span className='textcapital'>{fromchain.name}</span>
                                                        </span>
                                                        <span>
                                                            <span className='fw-600'>{amount}</span>&nbsp;<span className='fs-12'>{tokenobj.ticker}</span>
                                                        </span>
                                                    </div>
                                                    <div className='d-flex justify-content-between mb-3'>
                                                        <span>
                                                            Additional Fee (0.2%)
                                                        </span>
                                                        <span>
                                                            <span className='fw-600'> {((amount * Number(env.TRADEFEE)) / 100)}</span>&nbsp;<span className='fs-12'> {tokenobj.ticker}</span>
                                                        </span>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <span>
                                                            You receive on <span className='textcapital'>{tochain.name}</span>
                                                        </span>
                                                        <span>
                                                            <span className='fw-600'> {(amount - ((amount * Number(env.TRADEFEE)) / 100))}</span>&nbsp;<span className='fs-12'> {tokenobj.ticker}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer modalfooter">
                                {address ? fromchain.name !== 'Select Network' && tochain.name !== 'Select Network' && amount !== '' && Object.keys(tokenobj).length > 0 && amount !== 0 && amount <= this.state.tokenbalance ?
                                    this.state.loader ? <button type="button" className="btn btn-primary disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Submit</button> :
                                        <button type="button" className="btn btn-primary" onClick={() => this.submitbridge()}>Submit</button>
                                    :
                                    <button type="button" className="btn btn-primary disablebutton disabled tooltip">Submit <span className="tooltiptext">{selectedAccountID === 'EXTENSION' && fromselectobj.chainId !== parseInt(networkId, 10) && fromchain.name !== 'Select Network' ? "Please switch network" : Object.keys(tokenobj).length > 0 && amount > this.state.tokenbalance ? "Insufficient token balance" : "Please fillup all inputs"} </span></button>
                                    : <span id='walletbuttonclick' onClick={() => document.getElementById('modelclose').click()}><WalletButton /></span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade bridgemodal" id="swapModal" tabIndex="-1" role="dialog" aria-labelledby="swapModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered  modal-open p-2" role="document">
                        <div className="modal-content modelback" style={{ border: '0' }}>
                            <div className="RainConnect-title" style={{ fontSize: '30px' }}>
                                Swap
                                <span className='closemodelspan' id="swapmodelclose" data-dismiss="modal" onClick={() => this.setState({ fromswapobjname: { name: 'Select', image: '' }, toswapobjname: { name: 'Swap To', image: '' }, swapamount: '' })}>

                                    <i className='fa fa-close closemodelicon'></i>
                                </span>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            From
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ fontSize: '17px' }} className='textcapital'>{fromswapobjname.image !== '' && <img src={`${fromswapobjname.image}?v1.1.0`} className='selectcoinimg mr-2' alt="chainlogo" />}{fromswapobjname.name}</span>
                                                </button>
                                                <div className={this.coinstate.mixdata.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton1">
                                                    {this.coinstate.mixdata.length > 0 && this.coinstate.mixdata.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.swapfromselect(post)}><img src={`${post.image}?v1.1.0`} className='selectcoinimg mr-2' alt="chain-logo" /><span className='textcapital'>{post.name}</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-black fs-16 pt-2">
                                            {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
                                                <span className='text-themeclr' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.switchchain()}>
                                                    Switch Network
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="text-black fs-16 pb-2 pt-4 fw-600">
                                            To
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <div className="align-items-center d-flex form-control inputfield modelinput" /* type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" */>
                                                    <span style={{ fontSize: '17px', color: 'var(--black-change)' }} className='textcapital'>{toswapobjname.image !== '' && <img src={`${toswapobjname.image}?v1.1.0`} className='selectcoinimg mr-2' alt="tochainlogo" />}{toswapobjname.name}</span>
                                                </div>
                                                {/* <div className={this.coinstate.mixdata.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton">
                          {this.coinstate.mixdata.length > 0 && this.coinstate.mixdata.map((post, index) => {
                            return <div className="dropdown-item list-item" key={index} onClick={() => this.swaptoselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="tochain-logo" /><span className='textcapital'>{post.ticker}</span></div>
                          })}
                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row pt-4 pb-4'>
                                    <div className='col-12'>
                                        <div className="d-flex fs-16 fw-600 justify-content-between pb-2 text-black">
                                            <span>Amount</span>
                                            <span>Balance: {address && selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) ? fromswapobjname.ticker === env.BASECOIN ? ethBalance.toFixed(5) : fromswapobjname.ticker === JSON.parse(env.BASETOKEN)[0] ? tokenbalance : 0 : 0}&nbsp;
                                                {fromswapobjname.name !== 'Select' && fromswapobjname.ticker}</span>
                                        </div>
                                        <input className="form-control inputfield modelinput pl-3" type="text" style={{ fontSize: '17px' }} placeholder="Enter Amount" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); }} onChange={(e) => this.setState({ swapamount: e.target.value })} value={swapamount} />
                                    </div>
                                </div>
                                {address && selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) && Object.keys(fromswapobjname).length > 0 &&
                                    (
                                        fromswapobjname.ticker === env.BASECOIN ? Number(swapamount) > ethBalance.toFixed(5) : Number(swapamount) > tokenbalance
                                    )
                                    && <div style={{ textAlign: 'center' }}><span className='text-danger insufficientbalance p-2'><i className='RainConnect-fa fa fa-exclamation-triangle'></i>&nbsp;{`Insufficient ${fromswapobjname.ticker === env.BASECOIN ? 'coin' : "token"} balance !!!!`}</span></div>}
                            </div>
                            <div className="modal-footer modalfooter">
                                {address ?
                                    fromswapobjname.name !== 'Select' && toswapobjname.name !== 'Swap To' && swapamount !== '' && Object.keys(toswapobjname).length > 0 && selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) &&
                                        (
                                            fromswapobjname.ticker === env.BASECOIN ? Number(swapamount) <= ethBalance.toFixed(5) : Number(swapamount) <= tokenbalance
                                        )
                                        ? swaploader ? <button type="button" className="btn btn-primary disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Submit</button> :
                                            <button type="button" className="btn btn-primary" onClick={() => { this.submitswap() }}>Submit</button>
                                        :
                                        <button type="button" className="btn btn-primary disablebutton disabled tooltip" style={{ fontSize: '16px' }}>Submit
                                            <span className="tooltiptext">
                                                {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) ? "Please switch network" :
                                                    Object.keys(fromswapobjname).length > 0 &&
                                                        (
                                                            fromswapobjname.ticker === env.BASECOIN ? Number(swapamount) > ethBalance.toFixed(5) : Number(swapamount) > tokenbalance
                                                        )
                                                        ? `Insufficient ${fromswapobjname.ticker === env.BASECOIN ? 'coin' : "token"} balance` : "Please fillup all inputs"}
                                            </span></button>
                                    : <span id='walletbuttonclick' onClick={() => document.getElementById('swapmodelclose').click()}><WalletButton /></span>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    rendermidTab() {
        const { dispatch, lightmode, selectedAccountID, networkId, loader, address, ethBalance, tokenbalance } = this.props;
        const { networks, fromchain, tochain, token, tokenname, amount, fromselectobj, tokenobj, swaploader, swapamount, fromswapobjname, toswapobjname } = this.state;
        return (
            <>
                <div className={lightmode.lightmode ? "navbar bg-black navbar-expand-lg" : "navbar bg-black navbar-expand-lg navbarwhite"}>
                    <img className="navbar-brand" src={lightmode.lightmode ? require('../../images/Light-theme.svg') : require('../../images/Dark-theme.svg')} alt="hydro" style={{
                        height: "60px",
                        width: "70px",
                    }} />
                    {/* <h2 className="text-white">Rainbit</h2> */}
                    <div className="navbar-nav mr-auto" style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'transparent' }}>
                        <button
                            className="btn btn-primary header-dropdown mr-3"
                            type="button"
                            data-toggle="modal" data-target="#exampleModal">
                            Bridge
                        </button>
                        <button
                            className="btn btn-primary header-dropdown mr-3"
                            type="button"
                            data-toggle="modal" data-target="#swapModal">
                            Swap
                        </button>
                        <a href={env.BRIDGE_URL} target="blank">
                            <span
                                className="btn btn-primary header-dropdown "
                                type="button"
                                title="Get token">
                                Deposit
                            </span>
                        </a>
                        {/* <button
            className="btn btn-primary header-dropdown "
            type="button"
            // id="dropdownMenuButton"
            // data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            {currentMarket && currentMarket.id}
          </button> */}
                        {/* <div
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            style={{ maxHeight: 350, overflow: 'auto' }}>
            {markets.map(market => {
              return (
                <button
                  className="dropdown-item"
                  key={market.id}
                  onClick={() => currentMarket.id !== market.id && dispatch(updateCurrentMarket(market))}>
                  {market.id}
                </button>
              );
            })}
          </div> */}
                    </div>
                    <button
                        className="btn btn-primary collapse-toggle d-block"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbar-collapse"
                        aria-expanded="true">
                        <i className="fa fa-bars" />
                    </button>
                    <div className="collapse" id="navbar-collapse">
                        <div className="item" style={lightmode.lightmode ? { boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', display: 'flex' } : { boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.25)', display: 'flex' }}>
                            <button
                                className="btn header-dropdown togglemodebtn mr-4 mb-2"
                                type="button"
                                // id="dropdownMenuButton"
                                // data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ display: 'flex', alignItems: "center" }}>
                                <i className={!lightmode.lightmode ? "fa fa-moon-o fa-2x mr-2 toglemodeicon togglemodeactive" : "fa fa-moon-o fa-2x mr-2 toglemodeicon togglemodenotactive"} onClick={() => { dispatch(setdarkmode()) }} aria-hidden="true"></i>
                                <i className={!lightmode.lightmode ? "fa fa-sun-o fa-2x toglemodeicon togglemodenotactive " : "fa fa-sun-o fa-2x toglemodeicon togglemodeactive"} onClick={() => { dispatch(setlightmode()) }} aria-hidden="true"></i>
                            </button>
                            {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && (
                                !loader ? <button className="btn btn-primary mr-4" onClick={() => this.switchchain()}>Switch Network</button>
                                    : <button className="btn btn-primary mr-4  disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>
                            )}
                            <WalletButton />
                        </div>
                        {this.renderAccount()}
                    </div>
                </div>
                <div className="modal fade bridgemodal" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered  modal-lg p-2" role="document">
                        <div className="modal-content modelback" style={{ border: '0' }}>
                            <div className="RainConnect-title" style={{ fontSize: '30px' }}>
                                Bridge Tokens
                                <span className='closemodelspan' id="modelclose" data-dismiss="modal" onClick={() => this.setState({
                                    fromchain: { name: 'Select Network', img: '' },
                                    tochain: { name: 'Select Network', img: '' },
                                    token: [],
                                    tokenname: { name: 'Select Token', img: '' },
                                    amount: '',
                                    fromselectobj: {},
                                    tokenobj: {},
                                    tokenbalance: 0
                                })}>

                                    <i className='fa fa-close closemodelicon'></i>
                                </span>
                            </div>
                            <div className="text-black text-center">
                                Transfer your tokens from one network to another.
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            From
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ fontSize: '17px' }} className='textcapital'>{fromchain.img !== '' && <img src={fromchain.img} className='selectcoinimg mr-2' alt="chainlogo" />}{fromchain.name}</span>
                                                </button>
                                                <div className={networks.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton1">
                                                    {networks.length > 0 && networks.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.fromselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="chain-logo" /><span className='textcapital'>{post.chainName}</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-black fs-16 pt-2">
                                            {selectedAccountID === 'EXTENSION' && Object.keys(fromselectobj).length > 0 && fromselectobj.chainId !== parseInt(networkId, 10) &&
                                                <span className='text-themeclr' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.switchnetwork()}>
                                                    Switch Network
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            To
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ fontSize: '17px' }} className='textcapital'>{tochain.img !== '' && <img src={tochain.img} className='selectcoinimg mr-2' alt="tochainlogo" />}{tochain.name}</span>
                                                </button>
                                                <div className={networks.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton">
                                                    {networks.length > 0 && networks.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.toselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="tochain-logo" /><span className='textcapital'>{post.chainName}</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row pt-4 pb-4'>
                                    <div className='col-6'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            Tokens
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ fontSize: '17px' }}>{tokenname.img !== '' && <img src={tokenname.img} className='selectcoinimg mr-2' alt="ticker" />}{tokenname.name}</span>
                                                </button>
                                                <div className={token.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton">
                                                    {token.length > 0 ? token.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.settoken(post)}><img src={post.image} className='selectcoinimg mr-2' alt="ticker1" /><span>{post.ticker}</span></div>
                                                    }) : Object.keys(fromselectobj).length > 0 && fromselectobj.chainId !== parseInt(networkId, 10) ? <div className="dropdown-item list-item" >Switch Network</div> : <div className="dropdown-item list-item" >Please select network</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="d-flex fs-16 fw-600 justify-content-between pb-2 text-black">
                                            <span>Amount</span>
                                            <span>Balance: {this.state.tokenbalance}&nbsp;{tokenobj.ticker}</span>
                                        </div>
                                        <input className="form-control inputfield modelinput pl-3" type="text" style={{ fontSize: '17px' }} placeholder="Enter Amount" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); }} onChange={(e) => this.setState({ amount: e.target.value })} value={amount} />
                                    </div>
                                </div>
                                {Object.keys(tokenobj).length > 0 && amount > this.state.tokenbalance && <div style={{ textAlign: 'center' }}><span className='text-danger insufficientbalance p-2'><i className='RainConnect-fa fa fa-exclamation-triangle'></i>&nbsp;Insufficient token balance !!!!</span></div>}
                                {
                                    fromchain.name !== 'Select Network' && tochain.name !== 'Select Network' && amount !== '' && Object.keys(tokenobj).length > 0 && amount <= this.state.tokenbalance &&
                                    <div className='row pt-4'>
                                        <div className='col-12 text-black mt-2'>
                                            <div className='preview p-3 d-flex'>
                                                <div className='d-flex flex-column justify-content-between mr-3'>
                                                    <img src={fromchain.img} className='selectcoinimg' alt='coin' />
                                                    <span style={{ transform: 'rotate(90deg)' }}>----</span>
                                                    <img src={tochain.img} className='selectcoinimg' alt='coin' />
                                                </div>
                                                <div className='w-100'>
                                                    <div className='d-flex justify-content-between mb-3'>
                                                        <span>
                                                            You pay on <span className='textcapital'>{fromchain.name}</span>
                                                        </span>
                                                        <span>
                                                            <span className='fw-600'>{amount}</span>&nbsp;<span className='fs-12'>{tokenobj.ticker}</span>
                                                        </span>
                                                    </div>
                                                    <div className='d-flex justify-content-between mb-3'>
                                                        <span>
                                                            Additional Fee (0.2%)
                                                        </span>
                                                        <span>
                                                            <span className='fw-600'> {((amount * Number(env.TRADEFEE)) / 100)}</span>&nbsp;<span className='fs-12'> {tokenobj.ticker}</span>
                                                        </span>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <span>
                                                            You receive on <span className='textcapital'>{tochain.name}</span>
                                                        </span>
                                                        <span>
                                                            <span className='fw-600'> {(amount - ((amount * Number(env.TRADEFEE)) / 100))}</span>&nbsp;<span className='fs-12'> {tokenobj.ticker}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer modalfooter">
                                {address ? fromchain.name !== 'Select Network' && tochain.name !== 'Select Network' && amount !== '' && Object.keys(tokenobj).length > 0 && amount !== 0 && amount <= this.state.tokenbalance ?
                                    this.state.loader ? <button type="button" className="btn btn-primary disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Submit</button> :
                                        <button type="button" className="btn btn-primary" onClick={() => this.submitbridge()}>Submit</button>
                                    :
                                    <button type="button" className="btn btn-primary disablebutton disabled tooltip">Submit <span className="tooltiptext">{selectedAccountID === 'EXTENSION' && fromselectobj.chainId !== parseInt(networkId, 10) && fromchain.name !== 'Select Network' ? "Please switch network" : Object.keys(tokenobj).length > 0 && amount > this.state.tokenbalance ? "Insufficient token balance" : "Please fillup all inputs"} </span></button>
                                    : <span id='walletbuttonclick' onClick={() => document.getElementById('modelclose').click()}><WalletButton /></span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade bridgemodal" id="swapModal" tabIndex="-1" role="dialog" aria-labelledby="swapModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered  modal-open p-2" role="document">
                        <div className="modal-content modelback" style={{ border: '0' }}>
                            <div className="RainConnect-title" style={{ fontSize: '30px' }}>
                                Swap
                                <span className='closemodelspan' id="swapmodelclose" data-dismiss="modal" onClick={() => this.setState({ fromswapobjname: { name: 'Select', image: '' }, toswapobjname: { name: 'Swap To', image: '' }, swapamount: '' })}>

                                    <i className='fa fa-close closemodelicon'></i>
                                </span>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            From
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ fontSize: '17px' }} className='textcapital'>{fromswapobjname.image !== '' && <img src={fromswapobjname.image} className='selectcoinimg mr-2' alt="chainlogo" />}{fromswapobjname.name}</span>
                                                </button>
                                                <div className={this.coinstate.mixdata.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton1">
                                                    {this.coinstate.mixdata.length > 0 && this.coinstate.mixdata.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.swapfromselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="chain-logo" /><span className='textcapital'>{post.name}</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-black fs-16 pt-2">
                                            {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
                                                <span className='text-themeclr' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.switchchain()}>
                                                    Switch Network
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="text-black fs-16 pb-2 pt-4 fw-600">
                                            To
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <div className="align-items-center d-flex form-control inputfield modelinput" /* type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" */>
                                                    <span style={{ fontSize: '17px', color: 'var(--black-change)' }} className='textcapital'>{toswapobjname.image !== '' && <img src={toswapobjname.image} className='selectcoinimg mr-2' alt="tochainlogo" />}{toswapobjname.name}</span>
                                                </div>
                                                {/* <div className={this.coinstate.mixdata.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton">
                          {this.coinstate.mixdata.length > 0 && this.coinstate.mixdata.map((post, index) => {
                            return <div className="dropdown-item list-item" key={index} onClick={() => this.swaptoselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="tochain-logo" /><span className='textcapital'>{post.ticker}</span></div>
                          })}
                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row pt-4 pb-4'>
                                    <div className='col-12'>
                                        <div className="d-flex fs-16 fw-600 justify-content-between pb-2 text-black">
                                            <span>Amount</span>
                                            <span>Balance: {address && selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) ? fromswapobjname.ticker === env.BASECOIN ? ethBalance.toFixed(5) : fromswapobjname.ticker === JSON.parse(env.BASETOKEN)[0] ? tokenbalance : 0 : 0}&nbsp;
                                                {fromswapobjname.name !== 'Select' && fromswapobjname.ticker}</span>
                                        </div>
                                        <input className="form-control inputfield modelinput pl-3" type="text" style={{ fontSize: '17px' }} placeholder="Enter Amount" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); }} onChange={(e) => this.setState({ swapamount: e.target.value })} value={swapamount} />
                                    </div>
                                </div>
                                {address && selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) && Object.keys(fromswapobjname).length > 0 &&
                                    (
                                        fromswapobjname.ticker === env.BASECOIN ? Number(swapamount) > ethBalance.toFixed(5) : Number(swapamount) > tokenbalance
                                    )
                                    && <div style={{ textAlign: 'center' }}><span className='text-danger insufficientbalance p-2'><i className='RainConnect-fa fa fa-exclamation-triangle'></i>&nbsp;{`Insufficient ${fromswapobjname.ticker === env.BASECOIN ? 'coin' : "token"} balance !!!!`}</span></div>}
                            </div>
                            <div className="modal-footer modalfooter">
                                {address ?
                                    fromswapobjname.name !== 'Select' && toswapobjname.name !== 'Swap To' && swapamount !== '' && Object.keys(toswapobjname).length > 0 && selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) &&
                                        (
                                            fromswapobjname.ticker === env.BASECOIN ? Number(swapamount) <= ethBalance.toFixed(5) : Number(swapamount) <= tokenbalance
                                        )
                                        ? swaploader ? <button type="button" className="btn btn-primary disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Submit</button> :
                                            <button type="button" className="btn btn-primary" onClick={() => { this.submitswap() }}>Submit</button>
                                        :
                                        <button type="button" className="btn btn-primary disablebutton disabled tooltip" style={{ fontSize: '16px' }}>Submit
                                            <span className="tooltiptext">
                                                {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) ? "Please switch network" :
                                                    Object.keys(fromswapobjname).length > 0 &&
                                                        (
                                                            fromswapobjname.ticker === env.BASECOIN ? Number(swapamount) > ethBalance.toFixed(5) : Number(swapamount) > tokenbalance
                                                        )
                                                        ? `Insufficient ${fromswapobjname.ticker === env.BASECOIN ? 'coin' : "token"} balance` : "Please fillup all inputs"}
                                            </span></button>
                                    : <span id='walletbuttonclick' onClick={() => document.getElementById('swapmodelclose').click()}><WalletButton /></span>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderTablet() {
        const { dispatch, lightmode, selectedAccountID, networkId, loader, address, ethBalance, tokenbalance } = this.props;
        const { networks, fromchain, tochain, token, tokenname, amount, fromselectobj, tokenobj, swaploader, swapamount, fromswapobjname, toswapobjname } = this.state;

        return (
            <>
                <div className={lightmode.lightmode ? "navbar bg-black navbar-expand-lg" : "navbar bg-black navbar-expand-lg navbarwhite"}>
                    <img className="navbar-brand" src={lightmode.lightmode ? require('../../images/Light-theme.svg') : require('../../images/Dark-theme.svg')} alt="hydro" style={{
                        height: "60px",
                        width: "70px",
                    }} />
                    {/* <h2 className="text-white">Rainbit</h2> */}
                    <div className="navbar-nav mr-auto" style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'transparent' }}>
                        <button
                            className="btn btn-primary header-dropdown mr-3"
                            type="button"
                            data-toggle="modal" data-target="#exampleModal">
                            Bridge
                        </button>
                        <button
                            className="btn btn-primary header-dropdown mr-3"
                            type="button"
                            data-toggle="modal" data-target="#swapModal">
                            Swap
                        </button>
                        <a href={env.BRIDGE_URL} target="blank">
                            <span
                                className="btn btn-primary header-dropdown "
                                type="button"
                                title="Get token">
                                Deposit
                            </span>
                        </a>
                        {/* <button
            className="btn btn-primary header-dropdown "
            type="button"
            // id="dropdownMenuButton"
            // data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            {currentMarket && currentMarket.id}
          </button> */}
                        {/* <div
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            style={{ maxHeight: 350, overflow: 'auto' }}>
            {markets.map(market => {
              return (
                <button
                  className="dropdown-item"
                  key={market.id}
                  onClick={() => currentMarket.id !== market.id && dispatch(updateCurrentMarket(market))}>
                  {market.id}
                </button>
              );
            })}
          </div> */}
                    </div>
                    <button
                        className="btn btn-primary collapse-toggle d-block"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbar-collapse"
                        aria-expanded="true">
                        <i className="fa fa-bars" />
                    </button>
                    <div className="collapse" id="navbar-collapse">
                        <div className="item" style={lightmode.lightmode ? { boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', display: 'flex' } : { boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.25)', display: 'flex' }}>
                            <button
                                className="btn header-dropdown togglemodebtn mr-4 mb-2"
                                type="button"
                                // id="dropdownMenuButton"
                                // data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ display: 'flex', alignItems: "center" }}>
                                <i className={!lightmode.lightmode ? "fa fa-moon-o fa-2x mr-2 toglemodeicon togglemodeactive" : "fa fa-moon-o fa-2x mr-2 toglemodeicon togglemodenotactive"} onClick={() => { dispatch(setdarkmode()) }} aria-hidden="true"></i>
                                <i className={!lightmode.lightmode ? "fa fa-sun-o fa-2x toglemodeicon togglemodenotactive " : "fa fa-sun-o fa-2x toglemodeicon togglemodeactive"} onClick={() => { dispatch(setlightmode()) }} aria-hidden="true"></i>
                            </button>
                            {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && (
                                !loader ? <button className="btn btn-primary mr-4" onClick={() => this.switchchain()}>Switch Network</button>
                                    : <button className="btn btn-primary mr-4  disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>
                            )}
                            <WalletButton />
                        </div>
                        {this.renderAccount()}
                    </div>
                </div>
                <div className="modal fade bridgemodal" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content modelback" style={{ border: '0' }}>
                            <div className="RainConnect-title">
                                Bridge Tokens
                                <span className='closemodelspan' id="modelclose" data-dismiss="modal" onClick={() => this.setState({
                                    fromchain: { name: 'Select Network', img: '' },
                                    tochain: { name: 'Select Network', img: '' },
                                    token: [],
                                    tokenname: { name: 'Select Token', img: '' },
                                    amount: '',
                                    fromselectobj: {},
                                    tokenobj: {},
                                    tokenbalance: 0
                                })}>
                                    <i className='fa fa-close closemodelicon'></i>
                                </span>
                            </div>
                            <div className="text-black text-center">
                                Transfer your tokens from one network to another.
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            From
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ fontSize: '17px' }} className='textcapital'>{fromchain.img !== '' && <img src={fromchain.img} className='selectcoinimg mr-2' alt="ticker" />}{fromchain.name}</span>
                                                </button>
                                                <div className={networks.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton1">
                                                    {networks.length > 0 && networks.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.fromselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="ticker" /><span className='textcapital'>{post.chainName}</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-black fs-16 pt-2">
                                            {selectedAccountID === 'EXTENSION' && Object.keys(fromselectobj).length > 0 && fromselectobj.chainId !== parseInt(networkId, 10) &&
                                                <span className='text-themeclr' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.switchnetwork()}>
                                                    Switch Network
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            To
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ fontSize: '17px' }} className='textcapital'>{tochain.img !== '' && <img src={tochain.img} className='selectcoinimg mr-2' alt="ticker" />}{tochain.name}</span>
                                                </button>
                                                <div className={networks.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton">
                                                    {networks.length > 0 && networks.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.toselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="ticker" /><span className='textcapital'>{post.chainName}</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row pt-4 pb-4'>
                                    <div className='col-6'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            Tokens
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ fontSize: '17px' }}>{tokenname.img !== '' && <img src={tokenname.img} className='selectcoinimg mr-2' alt="ticker" />}{tokenname.name}</span>
                                                </button>
                                                <div className={networks.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton">
                                                    {token.length > 0 ? token.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.settoken(post)}><img src={post.image} className='selectcoinimg mr-2' alt="ticker" /><span>{post.ticker}</span></div>
                                                    }) : Object.keys(fromselectobj).length > 0 && fromselectobj.chainId !== parseInt(networkId, 10) ? <div className="dropdown-item list-item" >Switch Network</div> : <div className="dropdown-item list-item" >Please select network</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="d-flex fs-16 fw-600 justify-content-between pb-2 text-black">
                                            <span>Amount</span>
                                            <span>Balance: {this.state.tokenbalance}&nbsp;{tokenobj.ticker}</span>
                                        </div>
                                        <input className="form-control inputfield modelinput pl-3" type="text" style={{ fontSize: '17px' }} placeholder="Enter Amount" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); }} onChange={(e) => this.setState({ amount: e.target.value })} value={amount} />
                                    </div>
                                </div>
                                {Object.keys(tokenobj).length > 0 && amount > this.state.tokenbalance && <div style={{ textAlign: 'center' }}><span className='text-danger insufficientbalance p-2'><i className='RainConnect-fa fa fa-exclamation-triangle'></i>&nbsp;Insufficient token balance !!!!</span></div>}
                                {
                                    fromchain.name !== 'Select Network' && tochain.name !== 'Select Network' && amount !== '' && Object.keys(tokenobj).length > 0 && amount <= this.state.tokenbalance &&
                                    <div className='row pt-4'>
                                        <div className='col-12 text-black mt-2'>
                                            <div className='preview p-3'>
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <span>
                                                        You pay on <span className='textcapital'>{fromchain.name}</span>
                                                    </span>
                                                    <span>
                                                        <span className='fw-600'>{amount}</span>&nbsp;<span className='fs-12'>{tokenobj.ticker}</span>
                                                    </span>
                                                </div>
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <span>
                                                        Additional Fee
                                                    </span>
                                                    <span>
                                                        <span className='fw-600'>{((amount * Number(env.TRADEFEE)) / 100).toFixed(4)}</span>&nbsp;<span className='fs-12'>{tokenobj.ticker}</span>
                                                    </span>
                                                </div>
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <span>
                                                        You receive on <span className='textcapital'>{tochain.name}</span>
                                                    </span>
                                                    <span>
                                                        <span className='fw-600'>{(amount - ((amount * Number(env.TRADEFEE)) / 100)).toFixed(4)}</span>&nbsp;<span className='fs-12'>{tokenobj.ticker}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer modalfooter">
                                {address ? fromchain.name !== 'Select Network' && tochain.name !== 'Select Network' && amount !== '' && Object.keys(tokenobj).length > 0 && amount !== 0 && amount <= this.state.tokenbalance ?
                                    this.state.loader ? <button type="button" className="btn btn-primary disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Submit</button> :
                                        <button type="button" className="btn btn-primary" onClick={() => this.submitbridge()}>Submit</button>
                                    :
                                    <button type="button" className="btn btn-primary disablebutton disabled tooltip">Submit <span className="tooltiptext">{selectedAccountID === 'EXTENSION' && fromselectobj.chainId !== parseInt(networkId, 10) && fromchain.name !== 'Select Network' ? "Please switch network" : Object.keys(tokenobj).length > 0 && amount > this.state.tokenbalance ? "Insufficient token balance" : "Please fillup all inputs"} </span></button>
                                    : <span id='walletbuttonclick' onClick={() => document.getElementById('modelclose').click()}><WalletButton /></span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade bridgemodal" id="swapModal" tabIndex="-1" role="dialog" aria-labelledby="swapModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-open" role="document">
                        <div className="modal-content modelback" style={{ border: '0' }}>
                            <div className="RainConnect-title">
                                Swap
                                <span className='closemodelspan' id="swapmodelclose" data-dismiss="modal" onClick={() => this.setState({ fromswapobjname: { name: 'Select', image: '' }, toswapobjname: { name: 'Swap To', image: '' }, swapamount: '' })}>
                                    <i className='fa fa-close closemodelicon'></i>
                                </span>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            From
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ fontSize: '17px' }} className='textcapital'>{fromswapobjname.image !== '' && <img src={fromswapobjname.image} className='selectcoinimg mr-2' alt="chainlogo" />}{fromswapobjname.name}</span>
                                                </button>
                                                <div className={this.coinstate.mixdata.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton1">
                                                    {this.coinstate.mixdata.length > 0 && this.coinstate.mixdata.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.swapfromselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="chain-logo" /><span className='textcapital'>{post.name}</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-black fs-16 pt-2">
                                            {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
                                                <span className='text-themeclr' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.switchchain()}>
                                                    Switch Network
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            To
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <div className="align-items-center d-flex form-control inputfield modelinput" /* type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" */>
                                                    <span style={{ fontSize: '17px', color: 'var(--black-change)' }} className='textcapital'>{toswapobjname.image !== '' && <img src={toswapobjname.image} className='selectcoinimg mr-2' alt="tochainlogo" />}{toswapobjname.name}</span>
                                                </div>
                                                {/* <div className={networks.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton">
                          {networks.length > 0 && networks.map((post, index) => {
                            return <div className="dropdown-item list-item" key={index} onClick={() => this.toselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="ticker" /><span className='textcapital'>{post.chainName}</span></div>
                          })}
                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row pt-4 pb-4'>
                                    <div className='col-12'>
                                        <div className="d-flex fs-16 fw-600 justify-content-between pb-2 text-black">
                                            <span>Amount</span>
                                            <span>Balance: {address && selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) ? fromswapobjname.ticker === env.BASECOIN ? ethBalance.toFixed(5) : fromswapobjname.ticker === JSON.parse(env.BASETOKEN)[0] ? tokenbalance : 0 : 0}&nbsp;
                                                {fromswapobjname.name !== 'Select' && fromswapobjname.ticker}</span>
                                        </div>
                                        <input className="form-control inputfield modelinput pl-3" type="text" style={{ fontSize: '17px' }} placeholder="Enter Amount" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); }} onChange={(e) => this.setState({ swapamount: e.target.value })} value={swapamount} />
                                    </div>
                                </div>
                                {address && selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) && Object.keys(fromswapobjname).length > 0 &&
                                    (
                                        fromswapobjname.ticker === env.BASECOIN ? Number(swapamount) > ethBalance.toFixed(5) : Number(swapamount) > tokenbalance
                                    )
                                    && <div style={{ textAlign: 'center' }}><span className='text-danger insufficientbalance p-2'><i className='RainConnect-fa fa fa-exclamation-triangle'></i>&nbsp;{`Insufficient ${fromswapobjname.ticker === env.BASECOIN ? 'coin' : "token"} balance !!!!`}</span></div>}
                            </div>
                            <div className="modal-footer modalfooter">
                                {address ?
                                    fromswapobjname.name !== 'Select' && toswapobjname.name !== 'Swap To' && swapamount !== '' && Object.keys(toswapobjname).length > 0 && selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) &&
                                        (
                                            fromswapobjname.ticker === env.BASECOIN ? Number(swapamount) <= ethBalance.toFixed(5) : Number(swapamount) <= tokenbalance
                                        )
                                        ? swaploader ? <button type="button" className="btn btn-primary disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Submit</button> :
                                            <button type="button" className="btn btn-primary" onClick={() => { this.submitswap() }}>Submit</button>
                                        :
                                        <button type="button" className="btn btn-primary disablebutton disabled tooltip" style={{ fontSize: '16px' }}>Submit <span className="tooltiptext">
                                            {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) ? "Please switch network" :
                                                Object.keys(fromswapobjname).length > 0 &&
                                                    (
                                                        fromswapobjname.ticker === env.BASECOIN ? Number(swapamount) > ethBalance.toFixed(5) : Number(swapamount) > tokenbalance
                                                    )
                                                    ? `Insufficient ${fromswapobjname.ticker === env.BASECOIN ? 'coin' : "token"} balance` : "Please fillup all inputs"} </span></button>
                                    : <span id='walletbuttonclick' onClick={() => document.getElementById('swapmodelclose').click()}><WalletButton /></span>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderMobile() {
        const { dispatch, lightmode, selectedAccountID, networkId, loader, address, ethBalance, tokenbalance } = this.props;
        const { networks, fromchain, tochain, token, tokenname, amount, fromselectobj, tokenobj, swaploader, swapamount, fromswapobjname, toswapobjname } = this.state;

        return (
            <>
                <div className={lightmode.lightmode ? "navbar bg-black navbar-expand-lg" : "navbar bg-black navbar-expand-lg navbarwhite"}>
                    <img className="navbar-brand" src={lightmode.lightmode ? require('../../images/Light-theme.svg') : require('../../images/Dark-theme.svg')} alt="hydro" style={{
                        height: "60px",
                        width: "70px",
                    }} />
                    {/* <h2 className="text-white">Rainbit</h2> */}
                    <div className="dropdown navbar-nav mr-auto">

                        {/* <div
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            style={{ maxHeight: 350, overflow: 'auto' }}>
            {markets.map(market => {
              return (
                <button
                  className="dropdown-item"
                  key={market.id}
                  onClick={() => currentMarket.id !== market.id && dispatch(updateCurrentMarket(market))}>
                  {market.id}
                </button>
              );
            })}
          </div> */}
                    </div>
                    <button
                        className="btn btn-primary collapse-toggle"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbar-collapse"
                        aria-expanded="true">
                        <i className="fa fa-bars" />
                    </button>
                    <div className="collapse" id="navbar-collapse">
                        <div className="item mobileheader" style={lightmode.lightmode ? { boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' } : { boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.25)' }}>
                            {/* <button
              className="btn btn-primary header-dropdown mb-2"
              type="button"
              // id="dropdownMenuButton"
              // data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              {currentMarket && currentMarket.id}
            </button> */}
                            <button
                                className="btn btn-primary header-dropdown mb-2"
                                type="button"
                                data-toggle="modal" data-target="#exampleModal">
                                Bridge
                            </button>
                            <button
                                className="btn btn-primary header-dropdown mb-2"
                                type="button"
                                data-toggle="modal" data-target="#swapModal">
                                Swap
                            </button>
                            <a href={env.BRIDGE_URL} target="blank">
                                <span
                                    className="btn btn-primary header-dropdown mb-2"
                                    type="button"
                                    title="Get token">
                                    Deposit
                                </span>
                            </a>
                            <button
                                className="btn header-dropdown togglemodebtn mb-2"
                                type="button"
                                // id="dropdownMenuButton"
                                // data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ display: 'flex', alignItems: "center" }}>
                                <i className={!lightmode.lightmode ? "fa fa-moon-o fa-2x mr-2 toglemodeicon togglemodeactive" : "fa fa-moon-o fa-2x mr-2 toglemodeicon togglemodenotactive"} onClick={() => { dispatch(setdarkmode()) }} aria-hidden="true"></i>
                                <i className={!lightmode.lightmode ? "fa fa-sun-o fa-2x toglemodeicon togglemodenotactive " : "fa fa-sun-o fa-2x toglemodeicon togglemodeactive"} onClick={() => { dispatch(setlightmode()) }} aria-hidden="true"></i>
                            </button>
                            {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && (
                                !loader ? <button className="btn btn-primary" onClick={() => this.switchchain()}>Switch Network</button>
                                    : <button className="btn btn-primary disablebutton disabled mb-2"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>
                            )}
                            <WalletButton />
                        </div>
                        {this.renderAccount()}
                    </div>
                </div>
                <div className="modal fade bridgemodal" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content modelback" style={{ border: '0' }}>
                            <div className="RainConnect-title">
                                Bridge Tokens
                                <span className='closemodelspan' id="modelclose" data-dismiss="modal" onClick={() => this.setState({
                                    fromchain: { name: 'Select Network', img: '' },
                                    tochain: { name: 'Select Network', img: '' },
                                    token: [],
                                    tokenname: { name: 'Select Token', img: '' },
                                    amount: '',
                                    fromselectobj: {},
                                    tokenobj: {},
                                    tokenbalance: 0
                                })}>
                                    <i className='fa fa-close closemodelicon'></i>
                                </span>
                            </div>
                            <div className="text-black text-center col-12">
                                Transfer your tokens from one network to another.
                            </div>
                            <div className="modal-body p-2">
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            From
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ width: '125px', textAlign: 'left' }} className='textcapital'>{fromchain.img !== '' && <img src={fromchain.img} className='selectcoinimg mr-2' alt="ticker" />}{fromchain.name}</span>
                                                </button>
                                                <div className={networks.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton1">
                                                    {networks.length > 0 && networks.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.fromselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="ticker" /><span className='textcapital'>{post.chainName}</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-black fs-16 pt-2">
                                            {selectedAccountID === 'EXTENSION' && Object.keys(fromselectobj).length > 0 && fromselectobj.chainId !== parseInt(networkId, 10) &&
                                                <span className='text-themeclr' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.switchnetwork()}>
                                                    Switch Network
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            To
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className='textcapital'>{tochain.img !== '' && <img src={tochain.img} className='selectcoinimg mr-2' alt="ticker" />}{tochain.name}</span>
                                                </button>
                                                <div className={networks.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton">
                                                    {networks.length > 0 && networks.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.toselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="ticker" /><span className='textcapital'>{post.chainName}</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row pt-2 pb-2'>
                                    <div className='col-12'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            Tokens
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span>{tokenname.img !== '' && <img src={tokenname.img} className='selectcoinimg mr-2' alt="ticker" />}{tokenname.name}</span>
                                                </button>
                                                <div className={networks.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton">
                                                    {token.length > 0 ? token.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.settoken(post)}><img src={post.image} className='selectcoinimg mr-2' alt="ticker" /><span>{post.ticker}</span></div>
                                                    }) : Object.keys(fromselectobj).length > 0 && fromselectobj.chainId !== parseInt(networkId, 10) ? <div className="dropdown-item list-item" >Switch Network</div> : <div className="dropdown-item list-item" >Please select network</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 pt-2'>
                                        <div className="d-flex fs-16 fw-600 justify-content-between pb-2 text-black">
                                            <span>Amount</span>
                                            <span>Balance: {this.state.tokenbalance}&nbsp;{tokenobj.ticker}</span>
                                        </div>
                                        <input className="form-control inputfield modelinput pl-3" type="text" placeholder="Enter Amount" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); }} onChange={(e) => this.setState({ amount: e.target.value })} value={amount} />
                                    </div>
                                </div>
                                {Object.keys(tokenobj).length > 0 && amount > this.state.tokenbalance && <div style={{ textAlign: 'center' }} className='mt-2'><span className='text-danger insufficientbalance p-2'><i className='RainConnect-fa fa fa-exclamation-triangle'></i>&nbsp;Insufficient token balance !!!!</span></div>}
                                {
                                    fromchain.name !== 'Select Network' && tochain.name !== 'Select Network' && amount !== '' && Object.keys(tokenobj).length > 0 && amount <= this.state.tokenbalance &&
                                    <div className='row pt-4 pb-4'>
                                        <div className='col-12 text-black mt-2'>
                                            <div className='preview p-2'>
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <span>
                                                        You pay on <span className='textcapital'>{fromchain.name}</span>
                                                    </span>
                                                    <span>
                                                        <span className='fw-600'>{amount}</span>&nbsp;<span className='fs-12'>{tokenobj.ticker}</span>
                                                    </span>
                                                </div>
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <span>
                                                        Additional Fee
                                                    </span>
                                                    <span>
                                                        <span className='fw-600'>{((amount * Number(env.TRADEFEE)) / 100).toFixed(4)}</span>&nbsp;<span className='fs-12'>{tokenobj.ticker}</span>
                                                    </span>
                                                </div>
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <span>
                                                        You receive on <span className='textcapital'>{tochain.name}</span>
                                                    </span>
                                                    <span>
                                                        <span className='fw-600'>{(amount - ((amount * Number(env.TRADEFEE)) / 100)).toFixed(4)}</span>&nbsp;<span className='fs-12'>{tokenobj.ticker}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer modalfooter pt-5">
                                {address ? fromchain.name !== 'Select Network' && tochain.name !== 'Select Network' && amount !== '' && Object.keys(tokenobj).length > 0 && amount !== 0 && amount <= this.state.tokenbalance ?
                                    this.state.loader ? <button type="button" className="btn btn-primary disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Submit</button> :
                                        <button type="button" className="btn btn-primary" onClick={() => this.submitbridge()}>Submit</button>
                                    :
                                    <button type="button" className="btn btn-primary disablebutton disabled tooltip">Submit <span className="tooltiptext">{selectedAccountID === 'EXTENSION' && fromselectobj.chainId !== parseInt(networkId, 10) && fromchain.name !== 'Select Network' ? "Please switch network" : Object.keys(tokenobj).length > 0 && amount > this.state.tokenbalance ? "Insufficient token balance" : "Please fillup all inputs"} </span></button>
                                    : <span id='walletbuttonclick' onClick={() => document.getElementById('modelclose').click()}><WalletButton /></span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade bridgemodal" id="swapModal" tabIndex="-1" role="dialog" aria-labelledby="swapModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-open" role="document">
                        <div className="modal-content modelback" style={{ border: '0' }}>
                            <div className="RainConnect-title">
                                Swap
                                <span className='closemodelspan' id="swapmodelclose" data-dismiss="modal" onClick={() => this.setState({ fromswapobjname: { name: 'Select', image: '' }, toswapobjname: { name: 'Swap To', image: '' }, swapamount: '' })}>
                                    <i className='fa fa-close closemodelicon'></i>
                                </span>
                            </div>
                            <div className="modal-body p-2">
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            From
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <button className="toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span style={{ width: '125px', textAlign: 'left' }} className='textcapital'>{fromswapobjname.image !== '' && <img src={fromswapobjname.image} className='selectcoinimg mr-2' alt="chainlogo" />}{fromswapobjname.name}</span>
                                                </button>
                                                <div className={this.coinstate.mixdata.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton1">
                                                    {this.coinstate.mixdata.length > 0 && this.coinstate.mixdata.map((post, index) => {
                                                        return <div className="dropdown-item list-item" key={index} onClick={() => this.swapfromselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="chain-logo" /><span className='textcapital'>{post.name}</span></div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-black fs-16 pt-2">
                                            {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
                                                <span className='text-themeclr' style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.switchchain()}>
                                                    Switch Network
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="text-black fs-16 pb-2 fw-600">
                                            To
                                        </div>
                                        <div className='position-relative'>
                                            <div className="dropdown selectbutton">
                                                <div className="align-items-center d-flex form-control inputfield modelinput" /* type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" */>
                                                    <span style={{ fontSize: '17px', color: 'var(--black-change)' }} className='textcapital'>{toswapobjname.image !== '' && <img src={toswapobjname.image} className='selectcoinimg mr-2' alt="tochainlogo" />}{toswapobjname.name}</span>
                                                </div>
                                                {/* <div className={networks.length > 3 ? "dropdown-menu scrollable-content dropdownscroll w-100" : "dropdown-menu w-100"} aria-labelledby="dropdownMenuButton">
                          {networks.length > 0 && networks.map((post, index) => {
                            return <div className="dropdown-item list-item" key={index} onClick={() => this.toselect(post)}><img src={post.image} className='selectcoinimg mr-2' alt="ticker" /><span className='textcapital'>{post.chainName}</span></div>
                          })}
                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row pt-2 pb-2'>
                                    <div className='col-12 pt-2'>
                                        <div className="d-flex fs-16 fw-600 justify-content-between pb-2 text-black">
                                            <span>Amount</span>
                                            <span>Balance: {address && selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) ? fromswapobjname.ticker === env.BASECOIN ? ethBalance.toFixed(5) : fromswapobjname.ticker === JSON.parse(env.BASETOKEN)[0] ? tokenbalance : 0 : 0}&nbsp;
                                                {fromswapobjname.name !== 'Select' && fromswapobjname.ticker}</span>
                                        </div>
                                        <input className="form-control inputfield modelinput pl-3" type="text" style={{ fontSize: '17px' }} placeholder="Enter Amount" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); }} onChange={(e) => this.setState({ swapamount: e.target.value })} value={swapamount} />
                                    </div>
                                </div>
                                {address && selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) && Object.keys(fromswapobjname).length > 0 &&
                                    (
                                        fromswapobjname.ticker === env.BASECOIN ? Number(swapamount) > ethBalance.toFixed(5) : Number(swapamount) > tokenbalance
                                    )
                                    && <div style={{ textAlign: 'center' }}><span className='text-danger insufficientbalance p-2'><i className='RainConnect-fa fa fa-exclamation-triangle'></i>&nbsp;{`Insufficient ${fromswapobjname.ticker === env.BASECOIN ? 'coin' : "token"} balance !!!!`}</span></div>}
                            </div>
                            <div className="modal-footer modalfooter pt-5">
                                {address ?
                                    fromswapobjname.name !== 'Select' && toswapobjname.name !== 'Swap To' && swapamount !== '' && Object.keys(toswapobjname).length > 0 && selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) === parseInt(env.NETWORK_ID, 10) &&
                                        (
                                            fromswapobjname.ticker === env.BASECOIN ? Number(swapamount) <= ethBalance.toFixed(5) : Number(swapamount) <= tokenbalance
                                        )
                                        ? swaploader ? <button type="button" className="btn btn-primary disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Submit</button> :
                                            <button type="button" className="btn btn-primary" onClick={() => { this.submitswap() }}>Submit</button>
                                        :
                                        <button type="button" className="btn btn-primary disablebutton disabled tooltip" style={{ fontSize: '16px' }}>Submit <span className="tooltiptext">
                                            {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) ? "Please switch network" :
                                                Object.keys(fromswapobjname).length > 0 &&
                                                    (
                                                        fromswapobjname.ticker === env.BASECOIN ? Number(swapamount) > ethBalance.toFixed(5) : Number(swapamount) > tokenbalance
                                                    )
                                                    ? `Insufficient ${fromswapobjname.ticker === env.BASECOIN ? 'coin' : "token"} balance` : "Please fillup all inputs"} </span></button>
                                    : <span id='walletbuttonclick' onClick={() => document.getElementById('swapmodelclose').click()}><WalletButton /></span>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderAccount() {
        const { address, dispatch, isLoggedIn, isLocked } = this.props;
        if ((isLoggedIn && address) || isLocked) {
            return null;
        } else if (address) {
            return (
                <div className='item'>
                    <button className="btn btn-primary" onClick={() => dispatch(loginRequest())}>
                        connect
                    </button>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default connect(mapStateToProps)(Header);
