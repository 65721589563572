import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import market from './market';
import account from './account';
import trade from './trade';
import config from './config';
import loader from './loader';
import ethbalance from './ethbalance';
import { WalletReducer } from '@gongddex/hydro-sdk-wallet';
import mode from './mode';
const rootReducer = combineReducers({
  market,
  account,
  config,
  form: !!formReducer ? formReducer : {},
  WalletReducer,
  mode,
  trade,
  loader,
  ethbalance
});
export default rootReducer;
