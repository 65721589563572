
const initialState = {
    price: 0,
    amount: 0,
    side: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'TRADE':
            state = { price: action.payload.price, amount: action.payload.amount, side: action.payload.side,click:true }
            return state;
        case 'EMPTYTRADE':
            state = { click:false }
            return state;
        default:
            return state;
    }
};
