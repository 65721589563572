import React from 'react';
import { connect } from 'react-redux';
import { loadMarkets, loadTradeHistory } from './actions/markets';
import Header from './components/Header';
import WebsocketConnector from './components/WebsocketConnector';
import OrderBook from './components/Orderbook';
import Trade from './components/Trade';
import Wallet from './components/Wallet';
import Orders from './components/Orders';
import Charts from './components/Charts';
import TradeHistory from './components/TradeHistory';
import Pairs from './components/Pairs';
import { HydroWallet } from '@gongddex/hydro-sdk-wallet/build/wallets';
import { watchWallet } from '@gongddex/hydro-sdk-wallet/build/actions/wallet';
import env from './lib/env';
import MediaQuery from 'react-responsive';
import Fold from './components/Fold';
import PerfectScrollbar from 'perfect-scrollbar';
import { Wallet as SDKWallet } from '@gongddex/hydro-sdk-wallet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@gongddex/hydro-sdk-wallet/index.css';
import { setloader } from './actions/loader'
const data = require('./data/chaindata.json');

const mapStateToProps = state => {
  const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  return {
    selectedAccountID,
    currentMarket: state.market.getIn(['markets', 'currentMarket']),
    networkId: state.WalletReducer.getIn(['accounts', selectedAccountID, 'networkId']),
    lightmode: state.mode,
    loader: state.loader
  };
};

class App extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      mobileTab: 'trade'
    };
  }

  componentDidMount() {
    const { dispatch, currentMarket } = this.props;
    dispatch(loadMarkets());
    if (parseInt(env.NETWORK_ID) === 66) {
      this.initTestBrowserWallet();
    }
    if (currentMarket) {
      dispatch(loadTradeHistory(currentMarket.id));
    }
  }

  componentDidUpdate(prevProps) {
    const { currentMarket, dispatch } = this.props;
    if (currentMarket !== prevProps.currentMarket) {
      dispatch(loadTradeHistory(currentMarket.id));
    }
  }

  async initTestBrowserWallet() {
    HydroWallet.setNodeUrl(env.NODE_URL);
    const wallet = await HydroWallet.import(
      'B7A0C9D2786FC4DD080EA5D619D36771AEB0C8C26C290AFD3451B92BA2B7BC2C',
      '123456'
    );
    this.props.dispatch(watchWallet(wallet));
  }

  render() {
    const { currentMarket } = this.props;
    if (!currentMarket) {
      return null;
    }
    return (
      <div className="app mainback">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <SDKWallet title="Starter Kit Wallet" nodeUrl={env.NODE_URL} defaultWalletType="Hydro-Wallet" />
        <WebsocketConnector />
        <Header />
        {/* {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && (
          <span className="network-warning bg-warning text-white text-center" style={{ padding: 4 }}>
            Network Error: Switch Metamask's network to {this.getNetworkName()}.
          </span>
        )} */}
        <MediaQuery minWidth={1759}>{this.largedesktop()}</MediaQuery>
        <MediaQuery minWidth={1366} maxWidth={1758}>{this.renderDesktop()}</MediaQuery>
        <MediaQuery minWidth={1024} maxWidth={1365}>
          {this.renderLaptop()}
        </MediaQuery>
        <MediaQuery minWidth={768} maxWidth={1023}>
          {this.renderTablet()}
        </MediaQuery>
        <MediaQuery maxWidth={767}>{this.renderMobile()}</MediaQuery>
      </div>
    );
  }

  setRef(ref) {
    if (ref) {
      this.ps = new PerfectScrollbar(ref, {
        suppressScrollY: true,
        maxScrollbarLength: 20
      });
    }
  }
  switchchain = async () => {
    const { dispatch } = this.props;

    if (window.ethereum) {
      dispatch(setloader(true))
      let obj = data.find((x) => x.ticker === env.MAINTICKER)
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${obj.chainId.toString(16)}` }],
        });
        dispatch(setloader(false))
        window.location.reload()
      } catch (error) {
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: `0x${obj.chainId.toString(16)}`,
                chainName: obj.chainName,
                nativeCurrency: {
                  name: obj.ticker,
                  symbol: obj.ticker,
                  decimals: obj.precision
                },
                rpcUrls: [obj.rpcUrl],
                blockExplorerUrls: [obj.blockExplorerURL]
              }]
            });
            dispatch(setloader(false))
          } catch (addError) {
            console.error(addError);
            dispatch(setloader(false))
          }
        }
        console.error(error);
        dispatch(setloader(false))
      }
    }
  };
  getNetworkName() {
    switch (parseInt(env.NETWORK_ID, 10)) {
      case 1:
        return 'Mainnet';
      case 3:
        return 'Ropsten';
      case 4:
        return 'Rinkeby';
      case 66:
        return 'localhost:8545';
      default:
        return 'id: ' + env.NETWORK_ID;
    }
  }

  renderMobile() {
    const { lightmode, networkId, selectedAccountID, loader } = this.props;

    const selectTab = this.state.mobileTab;
    let content;
    if (selectTab === 'trade' || !selectTab) {
      content = <Trade />;
    } else if (selectTab === 'orders') {
      content =
        <div>{selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
          <div className='disablediv'>
            {!loader ? <button className="btn btn-outline-primary" onClick={() => this.switchchain()}>Switch Network</button>
              : <button className="btn btn-outline-primary  disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>}
          </div>}
          <div style={{ opacity: selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && '0.5' }}>
            <Orders />
          </div>
        </div>;
    } else if (selectTab === 'charts') {
      content = <Charts />;
    } else if (selectTab === 'orderbook') {
      content = (
        <>
          <div className="title text-themeclr fw-600">
            <div>
              <div>Orderbook</div>
              <div className="text-secondary">Available Bid and Ask orders</div>
            </div>
          </div>
          <OrderBook />
        </>
      );
    } else if (selectTab === 'history') {
      content = (
        <>
          <div className="title text-themeclr fw-600">
            <div>Trade History</div>
          </div>
          <TradeHistory />
        </>
      );
    } else if (selectTab === 'Pairs') {
      content = (
        <>
          <Pairs />
        </>
      );
    } else if (selectTab === 'Tokens') {
      content = (
        <>
          <Wallet />
        </>
      );
    }

    return (
      <>
        <div className={selectTab !== 'charts' ? 'mainback pb-5' : "flex-column flex-1 overflow-hidden"} style={selectTab === 'charts' ? { height: '100%' } : { height: '710px' }}>
          {selectTab !== 'charts' ? <section className='container-fluid h-100'>
            <div className="row h-100">
              <div className='col-12 pb-4 h-100' style={{ paddingTop: '15px' }}>
                <div className='basiccard p-3 h-100'>
                  {content}
                </div>
              </div>
            </div>
          </section> : <div className="flex-column flex-1 mainback pb-5">{content}</div>}
          <div className="flex nav-tabs overflow-hidden" ref={ref => this.setRef(ref)} style={{ position: 'absolute', width: '100%', bottom: '0', zIndex: '5' }}>
            <div className="nav-item flex-1 d-inline-block">
              <div
                onClick={() => this.setState({ mobileTab: 'trade' })}
                className={`tab-button text-center border-tab ${selectTab === 'trade' ? lightmode.lightmode ? ' active' : 'nightmodeactive' : ''} ${!lightmode.lightmode && "nightbtn"}`}>
                Trade
              </div>
            </div>
            <div className="nav-item flex-1 d-inline-block">
              <div
                onClick={() => this.setState({ mobileTab: 'orders' })}
                className={`tab-button text-center border-tab ${selectTab === 'orders' ? lightmode.lightmode ? ' active' : 'nightmodeactive' : ''} ${!lightmode.lightmode && "nightbtn"}`}>
                Orders
              </div>
            </div>
            <div className="nav-item flex-1 d-inline-block">
              <div
                onClick={() => this.setState({ mobileTab: 'charts' })}
                className={`tab-button text-center border-tab ${selectTab === 'charts' ? lightmode.lightmode ? ' active' : 'nightmodeactive' : ''} ${!lightmode.lightmode && "nightbtn"}`}>
                Charts
              </div>
            </div>
            <div className="nav-item flex-1 d-inline-block">
              <div
                onClick={() => this.setState({ mobileTab: 'orderbook' })}
                className={`tab-button text-center border-tab ${selectTab === 'orderbook' ? lightmode.lightmode ? ' active' : 'nightmodeactive' : ''} ${!lightmode.lightmode && "nightbtn"}`}>
                Orderbook
              </div>
            </div>
            <div className="nav-item flex-1 d-inline-block">
              <div
                onClick={() => this.setState({ mobileTab: 'history' })}
                className={`tab-button text-center border-tab ${selectTab === 'history' ? lightmode.lightmode ? ' active' : 'nightmodeactive' : ''} ${!lightmode.lightmode && "nightbtn"}`}>
                History
              </div>
            </div>
            <div className="nav-item flex-1 d-inline-block">
              <div
                onClick={() => this.setState({ mobileTab: 'Pairs' })}
                className={`tab-button text-center border-tab ${selectTab === 'Pairs' ? lightmode.lightmode ? ' active' : 'nightmodeactive' : ''} ${!lightmode.lightmode && "nightbtn"}`}>
                Pairs
              </div>
            </div>
            <div className="nav-item flex-1 d-inline-block">
              <div
                onClick={() => this.setState({ mobileTab: 'Tokens' })}
                className={`tab-button text-center border-tab ${selectTab === 'Tokens' ? lightmode.lightmode ? ' active' : 'nightmodeactive' : ''} ${!lightmode.lightmode && "nightbtn"}`}>
                Tokens
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderTablet() {
    const { networkId, selectedAccountID, loader } = this.props;

    return (
      <>
        <section className='container-fluid mainback'>
          <div className="row pt-3 pb-3 h-100">
            <div className="col-4">
              <div className='basiccard p-3' style={{ height: '676px' }}>
                <Trade />
              </div>
            </div>
            <div className="col-8 pl-0" style={{ height: '676px' }}>
              <div className='basiccard h-50 flex-column'>
                <div className="grid flex-1">
                  <Charts />
                </div>
              </div>
              <div className='h-50 flex-column pt-3'>
                <Fold className="basiccard flex-column">
                  <div className="" data-fold-item-title="Orderbook">
                    <OrderBook />
                  </div>
                  <div className="" data-fold-item-title="Trade History">
                    <TradeHistory />
                  </div>
                  <div className="" data-fold-item-title="Pairs">
                    <Pairs />
                  </div>
                  <div className="col-12" data-fold-item-title="Tokens">
                    <Wallet />
                  </div>
                  <div className=" position-relative" data-fold-item-title="Orders">
                    {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
                      <div className='disablediv'>
                        {!loader ? <button className="btn btn-outline-primary" onClick={() => this.switchchain()}>Switch Network</button>
                          : <button className="btn btn-outline-primary  disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>}
                      </div>}
                    <div style={{ opacity: selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && '0.5' }}>
                      <Orders />
                    </div>
                  </div>
                </Fold>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  // renderLaptop() {
  //   return (
  //     <div className="flex flex-1 overflow-hidden">
  //       <div className="flex-column border-right">
  //         <div className="grid flex-1">
  //           <Trade />
  //         </div>
  //       </div>
  //       <Fold className="grid border-right flex-column">
  //         <div className="grid flex-column" data-fold-item-title="Orderbook">
  //           <OrderBook />
  //         </div>
  //         <div className="grid flex-column" data-fold-item-title="Trade History">
  //           <TradeHistory />
  //         </div>
  //         <div className="grid flex-column" data-fold-item-title="Wallet">
  //           <Wallet />
  //         </div>
  //       </Fold>
  //       <div className="flex-column flex-1">
  //         <div className="grid flex-2">
  //           <Charts />
  //         </div>
  //         <div className="grid flex-1 border-top">
  //           <Orders />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  renderLaptop() {
    const { currentMarket, networkId, selectedAccountID, loader } = this.props;

    return (
      <>
        <section className='container-fluid mainback'>
          <div className="row h-100 pt-3 pb-3">
            <div className="col-3 d-flex flex-column justify-content-between">
              <OrderBook />
            </div>
            <div className="col-6 d-flex flex-column justify-content-between">
              <div className="row mb-3 flex-column basiccard" style={{ height: '444px' }}>
                <Charts />
              </div>
              <div className="row " style={{ height: '444px' }}>
                <div className='col-6 pl-0'>
                  <div className='basiccard p-3 h-100 position-relative'>
                    <Trade />
                  </div>
                </div>
                <div className='col-6 p-0'>
                  {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
                    <div className='disablediv'>
                      {!loader ? <button className="btn btn-outline-primary" onClick={() => this.switchchain()}>Switch Network</button>
                        : <button className="btn btn-outline-primary  disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>}
                    </div>}
                  <div className='basiccard p-3 h-100 position-relative'>
                    <div style={{ opacity: selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && '0.5' }}>
                      <Orders />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 d-flex flex-column justify-content-between">
              <div className='row pb-1' style={{ height: '296px' }}>
                <div className='col-12 h-100'>
                  <div className='basiccard h-100 overflow-hidden'>
                    <div className='col-12'>
                      <div className='p-3 row aftertitleborder d-flex justify-content-between'>
                        <div className='cardtitle'>Pairs</div>
                        <div className='cardtitle' style={{ color: "#16C782" }}>{currentMarket && currentMarket.id}</div>
                      </div>
                      <Pairs />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row pt-1 pb-1' style={{ height: '296px' }}>
                <div className='col-12 h-100'>
                  <div className='basiccard h-100'>
                    <div className='col-12'>
                      <div className='pb-3 pt-3 row pl-3 aftertitleborder'>
                        <div className='cardtitle'>Trade History</div>
                      </div>
                      <TradeHistory />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row pt-1' style={{ height: '296px' }}>
                <div className='col-12 h-100'>
                  <div className='basiccard h-100 overflow-hidden'>
                    <div className='col-12'>
                      <div className='pb-3 pt-3 row pl-3 aftertitleborder'>
                        <div className='col-12 d-flex p-0'>
                          <i className="fa fa-circle mr-2" aria-hidden="true" style={{ color: 'var(--theme-clr)' }} />
                          <div className='cardtitle'>Token Balance</div>
                        </div>
                      </div>
                      <div>
                        <Wallet />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  renderDesktop() {
    const { currentMarket, networkId, selectedAccountID, loader } = this.props;

    return (
      <>
        {/* <section>
          <div className="flex flex-1 overflow-hidden mainback">
            <div className="flex">
              <div className="grid border-right flex-column">
                <div className="title">
                  <div>
                    <div>Orderbook</div>
                    <div className="text-secondary">Available Bid and Ask orders</div>
                  </div>
                </div>
                <OrderBook />
              </div>
              <div className="flex-column flex-1 border-right">
            <div className="grid flex-1">
              <Trade />
            </div>
          </div>
            </div>
            <div className="flex-column flex-1 border-right">
              <div className="grid flex-2">
                <Charts />
              </div>
              <div className="grid flex-column">
                <Trade />
              </div>
              <div className="grid flex-1 border-top">
                <Orders />
              </div>
            </div>
            <div className="flex-column">
              <div className="grid">
                <div className="title flex align-items-center">
                  <div>Pairs</div>
                </div>
                <Pairs />
              </div>
              <div className="grid border-top">
                <div className="title flex align-items-center">
                  <div>Trade History</div>
                </div>
                <TradeHistory />
              </div>
              <div className="grid border-top">
                <Wallet />
              </div>
            </div>
          </div>
          <div className="grid flex-1 border-top">
          <section className="border-top">
                <Orders />
              </section>
        </section> */}
        <section className='container-fluid mainback'>
          <div className="row h-100 pt-3 pb-3">
            <div className="col-3 d-flex flex-column justify-content-between">
              <OrderBook />
            </div>
            <div className="col-6 d-flex flex-column justify-content-between">
              <div className="row mb-3 flex-column basiccard" style={{ height: '444px' }}>
                <Charts />
              </div>
              <div className="row" style={{ height: '444px' }}>
                <div className='col-6 pl-0'>
                  <div className='basiccard p-3 h-100 position-relative'>
                    <Trade />
                  </div>
                </div>
                <div className='col-6 p-0 position-relative'>
                  {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
                    <div className='disablediv'>
                      {!loader ? <button className="btn btn-outline-primary" onClick={() => this.switchchain()}>Switch Network</button>
                        : <button className="btn btn-outline-primary  disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>}
                    </div>}
                  <div className='basiccard  p-3 h-100'>
                    <div style={{ opacity: selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && '0.5' }}>
                      <Orders />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 d-flex flex-column justify-content-between">
              <div className='row pb-1' style={{ height: '296px' }}>
                <div className='col-12 h-100'>
                  <div className='basiccard h-100 overflow-hidden'>
                    <div className='col-12 h-100'>
                      <div className='p-3 row aftertitleborder d-flex justify-content-between'>
                        <div className='cardtitle'>Pairs</div>
                        <div className='cardtitle' style={{ color: "#16C782" }}>{currentMarket && currentMarket.id}</div>
                      </div>
                      <Pairs />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row pt-1 pb-1' style={{ height: '296px' }}>
                <div className='col-12 h-100'>
                  <div className='basiccard h-100'>
                    <div className='col-12'>
                      <div className='pb-3 pt-3 row pl-3 aftertitleborder'>
                        <div className='cardtitle'>Trade History</div>
                      </div>
                      <TradeHistory />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row pt-1' style={{ height: '296px' }}>
                <div className='col-12 h-100'>
                  <div className='basiccard h-100 overflow-hidden'>
                    <div className='col-12 h-100'>
                      <div className='pb-3 pt-3 row pl-3 aftertitleborder'>
                        <div className='col-12 d-flex p-0'>
                          <i className="fa fa-circle mr-2" aria-hidden="true" style={{ color: 'var(--theme-clr)' }} />
                          <div className='cardtitle'>Token Balance</div>
                        </div>
                      </div>
                      <div>
                        <Wallet />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* backup */}
        {/* <section>
          <div className="row mainback">
            <div className="col-2">
              <OrderBook />
            </div>
            <div className="col-8">
              <div className="pb-3 pr-1 pt-3 pl-2 row">
                <Charts />
              </div>
              <div className="row pr-1">
                <div className='col-6 pl-2'>
                  <div className='basiccard col-12 pb-3 pt-3 h-100'>
                    <Trade />
                  </div>
                </div>
                <div className='col-6 p-0'>
                  <div className='basiccard col-12 pb-3 pt-3 h-100'>
                    <Orders />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2 pl-0">
              <div className="ml-1 mr-1 pb-3 pl-2 pr-2 pt-3">
                <Pairs />
              </div>
              <div className="border-top">
                <div className="title flex align-items-center">
                  <div>Trade History</div>
                </div>
                <TradeHistory />
              </div>
              <div className="border-top">
                <Wallet />
              </div>
            </div>
          </div>
        </section> */}
      </>
    );
  }
  largedesktop() {
    const { currentMarket, networkId, selectedAccountID, loader } = this.props;

    return (
      <>
        <section className='container-fluid mainback'>
          <div className="row h-100 pt-3 pb-3">
            <div className="col-2 d-flex flex-column justify-content-between">
              <OrderBook />
            </div>
            <div className="col-8 d-flex flex-column justify-content-between">
              <div className="row mb-3 flex-column basiccard" style={{ height: '444px' }}>
                <Charts />
              </div>
              <div className="row " style={{ height: '444px' }}>
                <div className='col-6 pl-0'>
                  <div className='basiccard p-3 h-100 position-relative'>
                    <Trade />
                  </div>
                </div>
                <div className='col-6 p-0 position-relative'>
                  {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
                    <div className='disablediv'>
                      {!loader ? <button className="btn btn-outline-primary" onClick={() => this.switchchain()}>Switch Network</button>
                        : <button className="btn btn-outline-primary  disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>}
                    </div>}
                  <div className='basiccard p-3 h-100'>
                    <div style={{ opacity: selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && '0.5' }}>
                      <Orders />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2 d-flex flex-column justify-content-between">
              <div className='row pb-1' style={{ height: '296px' }}>
                <div className='col-12 h-100'>
                  <div className='basiccard h-100 overflow-hidden'>
                    <div className='col-12'>
                      <div className='p-3 row aftertitleborder d-flex justify-content-between'>
                        <div className='cardtitle'>Pairs</div>
                        <div className='cardtitle' style={{ color: "#16C782" }}>{currentMarket && currentMarket.id}</div>
                      </div>
                      <Pairs />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row pt-1 pb-1' style={{ height: '296px' }}>
                <div className='col-12 h-100'>
                  <div className='basiccard h-100'>
                    <div className='col-12'>
                      <div className='pb-3 pt-3 row pl-3 aftertitleborder'>
                        <div className='cardtitle'>Trade History</div>
                      </div>
                      <TradeHistory />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row pt-1' style={{ height: '296px' }}>
                <div className='col-12 h-100'>
                  <div className='basiccard h-100 overflow-hidden'>
                    <div className='col-12 h-100'>
                      <div className='pb-3 pt-3 row pl-3 aftertitleborder'>
                        <div className='col-12 d-flex p-0'>
                          <i className="fa fa-circle mr-2" aria-hidden="true" style={{ color: 'var(--theme-clr)' }} />
                          <div className='cardtitle'>Token Balance</div>
                        </div>
                      </div>
                      <div>
                        <Wallet />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default connect(mapStateToProps)(App);
