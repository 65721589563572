
const initialState = 0

export default (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ETHBALANCE':
            return state = action.payload;
        default:
            return state;
    }
};
