let _env = process.env;

if (!_env.REACT_APP_API_URL) {
  _env = window._env;
}

export default {
  API_ADDRESS: _env.REACT_APP_API_URL,
  WS_ADDRESS: _env.REACT_APP_WS_URL,
  NODE_URL: _env.REACT_APP_NODE_URL,
  HYDRO_PROXY_ADDRESS: _env.REACT_APP_HYDRO_PROXY_ADDRESS,
  HYDRO_TOKEN_ADDRESS: _env.REACT_APP_HYDRO_TOKEN_ADDRESS,
  WETH_TOKEN_ADDRESS: _env.REACT_APP_WETH_TOKEN_ADDRESS,
  NETWORK_ID: _env.REACT_APP_NETWORK_ID,
  NETWORKS: _env.REACT_APP_NETWORKS,
  TOKENS: _env.REACT_APP_TOKENS,
  MAINTICKER: _env.REACT_APP_MAINTICKER,
  TRADEFEE: _env.REACT_APP_TRADEFEE,
  BASECOIN: _env.REACT_APP_BASECOIN,
  BASETOKEN: _env.REACT_APP_BASETOKEN,
  NETWORKNAME: _env.REACT_APP_NETWORKNAME,
  BASECOINNAME: _env.REACT_APP_BASECOINNAME,
  BASETOKENNAME: _env.REACT_APP_BASETOKENNAME,
  BASEURL: _env.REACT_APP_BASE_URL,
  BRIDGE_URL: _env.REACT_APP_BRIDGE,
};
