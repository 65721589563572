
export const initState = {
    lightmode: localStorage.getItem('mode') ? JSON.parse(localStorage.getItem('mode')): false,
};

export default (state = initState, action) => {
    switch (action.type) {
        case 'LIGHTMODE': 
            state = {lightmode : true};
            return state;
            
            case 'DARKMODE':
                state = { lightmode: false };
            return state;
        default:
            return state;
    }
};
