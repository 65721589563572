"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : new P(function (resolve) {
        resolve(result.value);
      }).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    throw: verb(1),
    return: verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var _this = this;
Object.defineProperty(exports, "__esModule", {
  value: true
});
var wallet_1 = require("../selector/wallet");
var wallets_1 = require("../wallets");
exports.WALLET_STEPS = {
  SELECT: "SELECT",
  CREATE: "CREATE",
  CREATE_CONFIRM: "CREATE_CONFIRM",
  BACKUP: "BACKUP",
  TEST_MNEMONIC: "TEST_MNEMONIC",
  ADD_FUNDS: "ADD_FUNDS",
  IMPORT: "IMPORT",
  DELETE: "DELETE"
};
var TIMER_KEYS = {
  ADDRESS: "ADDRESS",
  STATUS: "STATUS",
  BALANCE: "BALANCE",
  NETWORK: "NETWORK"
};
var timers = {};
var setTimer = function setTimer(accountID, timerKey, nextTimer) {
  if (!timers[accountID]) {
    timers[accountID] = {};
  }
  timers[accountID][timerKey] = nextTimer;
};
var clearTimer = function clearTimer(accountID) {
  if (timers[accountID]) {
    Object.values(timers[accountID]).forEach(window.clearTimeout);
    timers[accountID] = {};
  }
};
var isTimerExist = function isTimerExist(accountID) {
  return !!timers[accountID];
};
exports.destoryTimer = function () {
  Object.keys(timers).map(function (accountID) {
    clearTimer(accountID);
  });
};
exports.cacheWallet = function (wallet, password) {
  return {
    type: "RAIN_WALLET_CACHE_WALLET",
    payload: {
      wallet: wallet,
      password: password
    }
  };
};
exports.setUnit = function (unit, decimals) {
  return {
    type: "RAIN_WALLET_SET_UNIT",
    payload: {
      unit: unit,
      decimals: decimals
    }
  };
};
exports.initCustomLocalWallet = function (walletClass) {
  return {
    type: "RAIN_WALLET_INIT_CUSTOM_LOCAL_WALLET",
    payload: {
      walletClass: walletClass
    }
  };
};
exports.selectWalletType = function (type) {
  return {
    type: "RAIN_WALLET_SELECT_WALLET_TYPE",
    payload: {
      type: type
    }
  };
};
exports.setTranslations = function (translations) {
  return {
    type: "RAIN_WALLET_SET_TRANSLATIONS",
    payload: {
      translations: translations
    }
  };
};
exports.setWalletStep = function (step) {
  return {
    type: "RAIN_WALLET_SET_STEP",
    payload: {
      step: step
    }
  };
};
exports.initAccount = function (accountID, wallet) {
  return {
    type: "RAIN_WALLET_INIT_ACCOUNT",
    payload: {
      accountID: accountID,
      wallet: wallet
    }
  };
};
exports.updateWallet = function (wallet) {
  return {
    type: "RAIN_WALLET_UPDATE_WALLET",
    payload: {
      wallet: wallet
    }
  };
};
exports.loadAddress = function (accountID, address) {
  return {
    type: "RAIN_WALLET_LOAD_ADDRESS",
    payload: {
      accountID: accountID,
      address: address
    }
  };
};
exports.loadBalance = function (accountID, balance) {
  return {
    type: "RAIN_WALLET_LOAD_BALANCE",
    payload: {
      accountID: accountID,
      balance: balance
    }
  };
};
exports.loadNetwork = function (accountID, networkId) {
  return {
    type: "RAIN_WALLET_LOAD_NETWORK",
    payload: {
      accountID: accountID,
      networkId: networkId
    }
  };
};
exports.selectAccount = function (accountID, type) {
  return function (dispatch, getState) {
    return __awaiter(_this, void 0, void 0, function () {
      var wallet;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            window.localStorage.setItem("RainWallet:lastSelectedWalletType", type);
            window.localStorage.setItem("RainWallet:lastSelectedAccountID", accountID);
            return [4, dispatch({
              type: "RAIN_WALLET_SELECT_ACCOUNT",
              payload: {
                accountID: accountID
              }
            })];
          case 1:
            _a.sent();
            wallet = wallet_1.getWallet(getState(), accountID);
            if (wallet) {
              dispatch(exports.watchWallet(wallet));
            }
            return [2];
        }
      });
    });
  };
};
exports.supportExtensionWallet = function () {
  return {
    type: "RAIN_WALLET_SUPPORT_EXTENSION_WALLET"
  };
};
exports.lockAccount = function (accountID) {
  return {
    type: "RAIN_WALLET_LOCK_ACCOUNT",
    payload: {
      accountID: accountID
    }
  };
};
exports.unlockAccount = function (accountID) {
  return {
    type: "RAIN_WALLET_UNLOCK_ACCOUNT",
    payload: {
      accountID: accountID
    }
  };
};
exports.unlockBrowserWalletAccount = function (account, password) {
  return function (dispatch) {
    return __awaiter(_this, void 0, void 0, function () {
      var rainWallet;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            rainWallet = account.get("wallet");
            if (!rainWallet) return [3, 2];
            return [4, rainWallet.unlock(password)];
          case 1:
            _a.sent();
            dispatch(exports.updateWallet(rainWallet));
            dispatch(exports.unlockAccount(rainWallet.id()));
            _a.label = 2;
          case 2:
            return [2];
        }
      });
    });
  };
};
exports.deleteBrowserWalletAccount = function (account) {
  return function (dispatch) {
    return __awaiter(_this, void 0, void 0, function () {
      var rainWallet, isLocked, accountID;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            rainWallet = account.get("wallet");
            isLocked = account.get("isLocked");
            if (!(rainWallet && !isLocked)) return [3, 2];
            accountID = rainWallet.id();
            clearTimer(accountID);
            dispatch({
              type: "RAIN_WALLET_DELETE_ACCOUNT",
              payload: {
                accountID: accountID
              }
            });
            return [4, rainWallet.delete()];
          case 1:
            _a.sent();
            _a.label = 2;
          case 2:
            return [2];
        }
      });
    });
  };
};
exports.showWalletModal = function () {
  return {
    type: "RAIN_WALLET_SHOW_DIALOG"
  };
};
exports.hideWalletModal = function () {
  return {
    type: "RAIN_WALLET_HIDE_DIALOG"
  };
};
exports.loadWallet = function (type, action) {
  return function (dispatch, getState) {
    var LocalWallet = getState().WalletReducer.get("LocalWallet") || wallets_1.RainWallet;
    switch (type) {
      case wallets_1.ExtensionWallet.TYPE:
        return dispatch(exports.loadExtensionWallet());
      case LocalWallet.TYPE:
        return dispatch(exports.loadLocalWallets());
      case wallets_1.WalletConnectWallet.TYPE:
        return dispatch(exports.loadWalletConnectWallet());
      default:
        if (action) {
          return action();
        }
        return;
    }
  };
};
exports.loadCoinbaseWallet = function (appName, appLogoUrl) {
  return function (dispatch) {
    return __awaiter(_this, void 0, void 0, function () {
      var networkId, wallet, e_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 3,, 4]);
            dispatch(exports.connectWallet(wallets_1.CoinbaseWallet.TYPE));
            return [4, wallets_1.getNetworkID()];
          case 1:
            networkId = _a.sent();
            wallet = new wallets_1.CoinbaseWallet(networkId, appName, appLogoUrl);
            return [4, wallet.enable()];
          case 2:
            _a.sent();
            dispatch(exports.watchWallet(wallet));
            return [3, 4];
          case 3:
            e_1 = _a.sent();
            dispatch(exports.connectWalletFinished(wallets_1.CoinbaseWallet.TYPE));
            throw e_1;
          case 4:
            return [2];
        }
      });
    });
  };
};
exports.loadDcentWallet = function (dcent) {
  return function (dispatch) {
    return __awaiter(_this, void 0, void 0, function () {
      var wallet, e_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            dispatch(exports.connectWallet(wallets_1.Dcent.TYPE));
            wallet = new wallets_1.Dcent(dcent);
            return [4, wallet.reconnect()];
          case 1:
            _a.sent();
            dispatch(exports.watchWallet(wallet));
            return [3, 3];
          case 2:
            e_2 = _a.sent();
            dispatch(exports.connectWalletFinished(wallets_1.Dcent.TYPE));
            throw e_2;
          case 3:
            return [2];
        }
      });
    });
  };
};
exports.loadFortmaticWallet = function (apiKey) {
  return function (dispatch) {
    return __awaiter(_this, void 0, void 0, function () {
      var wallet, e_3;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            dispatch(exports.connectWallet(wallets_1.Fortmatic.TYPE));
            wallet = new wallets_1.Fortmatic(apiKey);
            return [4, wallet.enable()];
          case 1:
            _a.sent();
            dispatch(exports.watchWallet(wallet));
            return [3, 3];
          case 2:
            e_3 = _a.sent();
            dispatch(exports.connectWalletFinished(wallets_1.Fortmatic.TYPE));
            throw e_3;
          case 3:
            return [2];
        }
      });
    });
  };
};
exports.loadTorus = function () {
  return function (dispatch) {
    return __awaiter(_this, void 0, void 0, function () {
      var wallet, e_4;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            dispatch(exports.connectWallet(wallets_1.Torus.TYPE));
            wallet = new wallets_1.Torus();
            return [4, wallet.enable()];
          case 1:
            _a.sent();
            dispatch(exports.watchWallet(wallet));
            return [3, 3];
          case 2:
            e_4 = _a.sent();
            dispatch(exports.connectWalletFinished(wallets_1.Torus.TYPE));
            throw e_4;
          case 3:
            return [2];
        }
      });
    });
  };
};
exports.loadExtensionWallet = function () {
  return function (dispatch) {
    return __awaiter(_this, void 0, void 0, function () {
      var wallet;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!(typeof window !== "undefined" && (window.ethereum || window.web3))) return [3, 2];
            wallet = new wallets_1.ExtensionWallet();
            return [4, wallet.enable()];
          case 1:
            _a.sent();
            _a.label = 2;
          case 2:
            if (wallet && wallet.isSupported()) {
              dispatch(exports.supportExtensionWallet());
              dispatch(exports.watchWallet(wallet));
            } else {
              window.setTimeout(function () {
                return dispatch(exports.loadExtensionWallet());
              }, 1000);
            }
            return [2];
        }
      });
    });
  };
};
exports.loadWalletConnectWallet = function () {
  return function (dispatch) {
    return __awaiter(_this, void 0, void 0, function () {
      var wallet, accountID;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            wallet = new wallets_1.WalletConnectWallet({
              bridge: ""
            });
            if (!!wallet.connector.connected) return [3, 2];
            return [4, wallet.connector.createSession()];
          case 1:
            _a.sent();
            _a.label = 2;
          case 2:
            dispatch(exports.watchWallet(wallet));
            accountID = wallet.id();
            wallet.connector.on("connect", function (error, payload) {
              return __awaiter(_this, void 0, void 0, function () {
                var addresses;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      if (error) {
                        throw error;
                      }
                      return [4, wallet.getAddresses()];
                    case 1:
                      addresses = _a.sent();
                      dispatch(exports.unlockAccount(accountID));
                      dispatch(exports.selectAccount(accountID, wallet.type()));
                      dispatch(exports.loadAddress(accountID, addresses[0]));
                      dispatch(exports.loadNetwork(accountID, payload.params[0].chainId));
                      return [2];
                  }
                });
              });
            });
            wallet.connector.on("session_update", function (error, payload) {
              if (error) {
                throw error;
              }
              var _a = payload.params[0],
                accounts = _a.accounts,
                chainId = _a.chainId;
              dispatch(exports.loadAddress(accountID, accounts[0]));
              dispatch(exports.loadNetwork(accountID, chainId));
            });
            wallet.connector.on("disconnect", function (error, payload) {
              return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  if (error) {
                    throw error;
                  }
                  window.location.reload();
                  return [2];
                });
              });
            });
            return [2];
        }
      });
    });
  };
};
exports.loadLocalWallets = function () {
  return function (dispatch, getState) {
    var LocalWallet = getState().WalletReducer.get("LocalWallet") || wallets_1.RainWallet;
    LocalWallet.list().map(function (wallet) {
      dispatch(exports.watchWallet(wallet));
    });
  };
};
exports.loadLedger = function () {
  return function (dispatch) {
    return __awaiter(_this, void 0, void 0, function () {
      var wallet, e_5;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 2,, 3]);
            dispatch(exports.connectWallet(wallets_1.Ledger.TYPE));
            wallet = new wallets_1.Ledger();
            return [4, wallet.enable()];
          case 1:
            _a.sent();
            dispatch(exports.watchWallet(wallet));
            return [3, 3];
          case 2:
            e_5 = _a.sent();
            dispatch(exports.connectWalletFinished(wallets_1.Ledger.TYPE));
            throw e_5;
          case 3:
            return [2];
        }
      });
    });
  };
};
exports.loadTrezor = function () {
  return function (dispatch) {
    return __awaiter(_this, void 0, void 0, function () {
      var wallet;
      return __generator(this, function (_a) {
        try {
          dispatch(exports.connectWallet(wallets_1.Trezor.TYPE));
          wallet = new wallets_1.Trezor();
          dispatch(exports.watchWallet(wallet));
        } catch (e) {
          dispatch(exports.connectWalletFinished(wallets_1.Ledger.TYPE));
          throw e;
        }
        return [2];
      });
    });
  };
};
exports.connectWallet = function (type) {
  return {
    type: "RAIN_WALLET_CONNECT_WALLET",
    payload: {
      type: type
    }
  };
};
exports.connectWalletFinished = function (type) {
  return {
    type: "RAIN_WALLET_CONNECT_WALLET_FINISHED",
    payload: {
      type: type
    }
  };
};
exports.watchWallet = function (wallet) {
  return function (dispatch, getState) {
    return __awaiter(_this, void 0, void 0, function () {
      var accountID, type, _watchAddress, _watchBalance, _watchNetwork;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            accountID = wallet.id();
            type = wallet.type();
            if (isTimerExist(accountID)) {
              clearTimer(accountID);
            }
            if (!!wallet_1.getAccount(getState(), accountID)) return [3, 2];
            return [4, dispatch(exports.initAccount(accountID, wallet))];
          case 1:
            _a.sent();
            return [3, 4];
          case 2:
            return [4, dispatch(exports.updateWallet(wallet))];
          case 3:
            _a.sent();
            _a.label = 4;
          case 4:
            _watchAddress = function watchAddress() {
              return __awaiter(_this, void 0, void 0, function () {
                var timerKey, address, addresses, e_6, walletIsLocked, walletStoreLocked, currentAddressInStore, lastSelectedAccountID, currentSelectedAccountID, selectedAccountID, selectedWalletType, nextTimer;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      timerKey = TIMER_KEYS.ADDRESS;
                      _a.label = 1;
                    case 1:
                      _a.trys.push([1, 3, 4, 5]);
                      return [4, wallet.getAddresses()];
                    case 2:
                      addresses = _a.sent();
                      address = addresses.length > 0 ? addresses[0].toLowerCase() : null;
                      return [3, 5];
                    case 3:
                      e_6 = _a.sent();
                      if (type === wallets_1.Ledger.TYPE || type === wallets_1.Dcent.TYPE || type === wallets_1.Fortmatic.TYPE || type === wallets_1.Torus.TYPE || type === wallets_1.CoinbaseWallet.TYPE || type === wallets_1.Trezor.TYPE) {
                        clearTimer(accountID);
                        throw e_6;
                      } else if (e_6 !== wallets_1.NeedUnlockWalletError && e_6 !== wallets_1.NotSupportedError) {
                        throw e_6;
                      }
                      address = null;
                      return [3, 5];
                    case 4:
                      dispatch(exports.connectWalletFinished(type));
                      return [7];
                    case 5:
                      walletIsLocked = wallet.isLocked(address);
                      walletStoreLocked = getState().WalletReducer.getIn(["accounts", accountID, "isLocked"]);
                      if (walletIsLocked !== walletStoreLocked) {
                        dispatch(walletIsLocked ? exports.lockAccount(accountID) : exports.unlockAccount(accountID));
                      }
                      currentAddressInStore = getState().WalletReducer.getIn(["accounts", accountID, "address"]);
                      if (currentAddressInStore !== address) {
                        dispatch(exports.loadAddress(accountID, address));
                        lastSelectedAccountID = window.localStorage.getItem("RainWallet:lastSelectedAccountID");
                        currentSelectedAccountID = getState().WalletReducer.get("selectedAccountID");
                        if (!currentSelectedAccountID && (lastSelectedAccountID === accountID || !lastSelectedAccountID)) {
                          dispatch(exports.selectAccount(accountID, type));
                        }
                      }
                      selectedAccountID = getState().WalletReducer.get("selectedAccountID");
                      selectedWalletType = getState().WalletReducer.get("selectedWalletType");
                      if (address && accountID !== selectedAccountID && type === selectedWalletType && (type === wallets_1.CoinbaseWallet.TYPE || type === wallets_1.Dcent.TYPE || type === wallets_1.Fortmatic.TYPE || type === wallets_1.Torus.TYPE)) {
                        dispatch(exports.selectAccount(accountID, type));
                      }
                      nextTimer = window.setTimeout(function () {
                        return _watchAddress();
                      }, 3000);
                      setTimer(accountID, timerKey, nextTimer);
                      return [2];
                  }
                });
              });
            };
            _watchBalance = function watchBalance() {
              return __awaiter(_this, void 0, void 0, function () {
                var timerKey, address, balance, balanceInStore, e_7, currentSelectedAccountID, watcherRate, nextTimer;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      timerKey = TIMER_KEYS.BALANCE;
                      address = getState().WalletReducer.getIn(["accounts", accountID, "address"]);
                      if (!address) return [3, 4];
                      _a.label = 1;
                    case 1:
                      _a.trys.push([1, 3,, 4]);
                      return [4, wallet.getBalance(address)];
                    case 2:
                      balance = _a.sent();
                      balanceInStore = getState().WalletReducer.getIn(["accounts", accountID, "balance"]);
                      if (balance.toString() !== balanceInStore.toString()) {
                        dispatch(exports.loadBalance(accountID, balance));
                      }
                      return [3, 4];
                    case 3:
                      e_7 = _a.sent();
                      if (e_7 !== wallets_1.NeedUnlockWalletError && e_7 !== wallets_1.NotSupportedError) {
                        throw e_7;
                      }
                      return [3, 4];
                    case 4:
                      currentSelectedAccountID = getState().WalletReducer.get("selectedAccountID");
                      watcherRate = getWatcherRate(currentSelectedAccountID, accountID);
                      nextTimer = window.setTimeout(function () {
                        return _watchBalance();
                      }, watcherRate);
                      setTimer(accountID, timerKey, nextTimer);
                      return [2];
                  }
                });
              });
            };
            _watchNetwork = function watchNetwork() {
              return __awaiter(_this, void 0, void 0, function () {
                var timerKey, networkId, nextTimer, e_8;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      timerKey = TIMER_KEYS.NETWORK;
                      _a.label = 1;
                    case 1:
                      _a.trys.push([1, 3,, 4]);
                      return [4, wallet.loadNetworkId()];
                    case 2:
                      networkId = _a.sent();
                      if (networkId && networkId !== getState().WalletReducer.getIn(["accounts", accountID, "networkId"])) {
                        dispatch(exports.loadNetwork(accountID, networkId));
                      } else {
                        nextTimer = window.setTimeout(function () {
                          return _watchNetwork();
                        }, 3000);
                        setTimer(accountID, timerKey, nextTimer);
                      }
                      return [3, 4];
                    case 3:
                      e_8 = _a.sent();
                      if (e_8 !== wallets_1.NeedUnlockWalletError && e_8 !== wallets_1.NotSupportedError) {
                        throw e_8;
                      }
                      return [3, 4];
                    case 4:
                      return [2];
                  }
                });
              });
            };
            Promise.all([_watchAddress(), _watchBalance(), _watchNetwork()]);
            return [2];
        }
      });
    });
  };
};
var getWatcherRate = function getWatcherRate(selectedType, type) {
  return selectedType === type && window.document.visibilityState !== "hidden" ? 60000 : 300000;
};