import React from 'react';
import { connect } from 'react-redux';
import BigNumber from 'bignumber.js';
import PerfectScrollbar from 'perfect-scrollbar';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import { loadMarkets } from '../../actions/markets';

const mapStateToProps = state => {
  return {
    tradeHistory: state.market.get('tradeHistory'),
    currentMarket: state.market.getIn(['markets', 'currentMarket'])
  };
};

class TradeHistory extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { tradeHistory, dispatch } = this.props;
    if (tradeHistory !== prevProps.tradeHistory) {
      // this.ps.update();
      dispatch(loadMarkets())
    }
  }

  render() {
    return (
      <>
        <MediaQuery maxWidth={767}>{this.renderTablet()}</MediaQuery>
        <MediaQuery minWidth={768} maxWidth={1023}>{this.renderLaptop()}</MediaQuery>
        <MediaQuery minWidth={1024}>{this.renderWeb()}</MediaQuery>
      </>
    );
  }
  renderWeb() {
    const { tradeHistory } = this.props;
    return (
      <div style={{ lineHeight: "1.5", height: '240px' }} className="trade-history flex-1 position-relative" >
        <div className="flex header tablehead pt-2">
          <div className="col-4 p-0 text-center">Price</div>
          <div className="col-4 p-0 text-right">Amount</div>
          <div className="col-4 p-0 text-center">Time</div>
        </div>
        {tradeHistory.toArray().length > 0 ?
          <div className="flex-column flex-1 scrollable-content overflow-auto" style={{height:'88%'}}>
            <div className="asks flex-column
          ">
              {
                tradeHistory
                  .toArray()
                  .reverse()
                  .map(([id, trade]) => {
                    const colorGreen = trade.takerSide === 'buy';
                    return (
                      <div className="ask flex align-items-center " key={trade.id} style={{ cursor: "pointer" }}>
                        <div className={['text-center  p-0 tradehistorywrap', 'col-4', colorGreen ? 'text-themeclr' : 'redfontclr'].join(' ')} >
                          {trade.price < 0.1 ? new BigNumber(trade.price).toFixed(8) : new BigNumber(trade.price).toFixed(4)}
                          {"     "}
                          {trade.takerSide === 'buy' ? (
                            <i className="fa fa-arrow-up text-themeclr" aria-hidden="true" />
                          ) : (
                            <i className="fa fa-arrow-down redfontclr" aria-hidden="true" />
                          )}
                        </div>
                        <div className="col-4 p-0 orderbook-amount text-right redfontclr">
                          {trade.amount < 0.1 ? new BigNumber(trade.amount).toFixed(8) : new BigNumber(trade.amount).toFixed(4)}
                        </div>
                        <div className="col-4 p-0 redfontclr text-center">{moment(trade.executedAt).format('HH:mm:ss')}</div>
                      </div>
                    );
                  })}
            </div>
          </div>
          : <div className='connectwallet'> No Trade History Found !!!</div>}
      </div>
    )
  }
  renderLaptop() {
    const { tradeHistory } = this.props;
    return (
      <div style={{ lineHeight: "1.5", height: '277px' }} className="trade-history flex-1 position-relative overflow-hidden">
        <div className="flex header tablehead pt-2 border-bottom">
          <div className="col-4 p-0 text-center">Price</div>
          <div className="col-4 p-0 text-right">Amount</div>
          <div className="col-4 p-0 text-center">Time</div>
        </div>
        {tradeHistory.toArray().length > 0 ?
          <div className="flex-column flex-1 overflow-auto scrollable-content" style={{ height: '83%' }}>
            <div className="asks flex-column
          ">
              {
                tradeHistory
                  .toArray()
                  .reverse()
                  .map(([id, trade]) => {
                    const colorGreen = trade.takerSide === 'buy';
                    return (
                      <div className="ask flex align-items-center " key={trade.id} style={{ cursor: "pointer" }}>
                        <div className={['text-center  p-0 tradehistorywrap', 'col-4', colorGreen ? 'text-themeclr' : 'redfontclr'].join(' ')} >
                          {trade.price < 0.1 ? new BigNumber(trade.price).toFixed(8) : new BigNumber(trade.price).toFixed(4)}
                          {"     "}
                          {trade.takerSide === 'buy' ? (
                            <i className="fa fa-arrow-up text-themeclr" aria-hidden="true" />
                          ) : (
                            <i className="fa fa-arrow-down redfontclr" aria-hidden="true" />
                          )}
                        </div>
                        <div className="col-4 p-0 orderbook-amount text-right redfontclr">
                          {trade.amount < 0.1 ? new BigNumber(trade.amount).toFixed(8) : new BigNumber(trade.amount).toFixed(4)}
                        </div>
                        <div className="col-4 p-0 redfontclr text-center">{moment(trade.executedAt).format('HH:mm:ss')}</div>
                      </div>
                    );
                  })}
            </div>
          </div>
          : <div className='connectwallet'> No Trade History Found !!!</div>}
      </div>
    )
  }
  renderTablet() {
    const { tradeHistory } = this.props;
    return (
      <div style={{ lineHeight: "1.5", height: '550px' }} className="trade-history flex-1 position-relative overflow-hidden">
        <div className="flex header tablehead pt-2 pb-2 border-bottom">
          <div className="col-4 p-0 text-center">Price</div>
          <div className="col-4 p-0 text-right">Amount</div>
          <div className="col-4 p-0 text-center">Time</div>
        </div>
        {tradeHistory.toArray().length > 0 ?
          <div className="flex-column flex-1 overflow-auto scrollable-content" style={{ height: '93%' }}>
            <div className="asks flex-column
          ">
              {
                tradeHistory
                  .toArray()
                  .reverse()
                  .map(([id, trade]) => {
                    const colorGreen = trade.takerSide === 'buy';
                    return (
                      <div className="ask flex align-items-center " key={trade.id} style={{ cursor: "pointer" }}>
                        <div className={['text-center  p-0 tradehistorywrap', 'col-4', colorGreen ? 'text-themeclr' : 'redfontclr'].join(' ')} >
                          {trade.price < 0.1 ? new BigNumber(trade.price).toFixed(8) : new BigNumber(trade.price).toFixed(4)}
                          {"     "}
                          {trade.takerSide === 'buy' ? (
                            <i className="fa fa-arrow-up text-themeclr" aria-hidden="true" />
                          ) : (
                            <i className="fa fa-arrow-down redfontclr" aria-hidden="true" />
                          )}
                        </div>
                        <div className="col-4 p-0 orderbook-amount text-right redfontclr">
                          {trade.amount < 0.1 ? new BigNumber(trade.amount).toFixed(8) : new BigNumber(trade.amount).toFixed(4)}
                        </div>
                        <div className="col-4 p-0 redfontclr text-center">{moment(trade.executedAt).format('HH:mm:ss')}</div>
                      </div>
                    );
                  })}
            </div>
          </div>
          : <div className='connectwallet'> No Trade History Found !!!</div>}
      </div>
    )
  }

  setRef(ref) {
    if (ref) {
      this.ps = new PerfectScrollbar(ref, {
        suppressScrollX: true,
        maxScrollbarLength: 20
      });
    }
  }
}

export default connect(mapStateToProps)(TradeHistory);
