import BigNumber from 'bignumber.js';
import { watchToken } from '../actions/account';
import abi from './abi';
import env from './env';
import { ethers } from 'ethers';
import { getSelectedAccountWallet, getTransactionReceipt, getContract } from '@gongddex/hydro-sdk-wallet';
export let web3, Contract;


export const getTokenBalance = async (tokenAddress, accountAddress) => {
  const contract = getContract(tokenAddress, abi);
  const balance = await contract.balanceOf(accountAddress);
  return new BigNumber(balance);
};

export const getAllowance = async (tokenAddress, accountAddress) => {
  const contract = getContract(tokenAddress, abi);
  const allowance = await contract.allowance(accountAddress, env.HYDRO_PROXY_ADDRESS);
  return new BigNumber(allowance);
};

export const wrapETH = (amount, toast) => {
  return async (dispatch, getState) => {
    const state = getState();
    const WETH = state.config.get('WETH');
    const value = new BigNumber(amount).multipliedBy(Math.pow(10, WETH.decimals)).toString();

    let params = {
      to: WETH.address,
      data: '0xd0e30db0',
      value
    };

    try {
      const wallet = (state);
      if (Object.keys(wallet) && Object.keys(wallet)[0] === "ethereum") {
        params.from = wallet.ethereum.selectedAddress
      }
      const transactionID = await wallet.sendTransaction(params);
      toast.success('Wrap ETH request submitted');
      // alert(`Wrap ETH request submitted`);
      watchTransactionStatus(transactionID, async success => {
        if (success) {
          dispatch(watchToken(WETH.address, WETH.symbol, WETH.decimals));
          toast.success('Wrap ETH Successfully');
          // alert('Wrap ETH Successfully');
        } else {
          toast.error('Wrap ETH Failed');
          // alert('Wrap ETH Failed');
        }
      });
      return transactionID;
    } catch (e) {
      toast.error(e.message);
      // alert(e);
    }
    return null;
  };
};

export const unwrapWETH = (amount, toast) => {
  return async (dispatch, getState) => {
    const state = getState();
    const WETH = state.config.get('WETH');
    const value = new BigNumber(amount).multipliedBy(Math.pow(10, WETH.decimals)).toString(16);
    const wallet = getSelectedAccountWallet(state);
    const functionSelector = '2e1a7d4d';
    const valueString = get64BytesString(value);

    let params = {
      to: WETH.address,
      data: `0x${functionSelector}${valueString}`,
      value: 0
    };

    try {
      if (Object.keys(wallet) && Object.keys(wallet)[0] === "ethereum") {
        params.from = wallet.ethereum.selectedAddress
      }
      const transactionID = await wallet.sendTransaction(params);
      toast.success('Unwrap WETH request submitted');
      watchTransactionStatus(transactionID, async success => {
        if (success) {
          dispatch(watchToken(WETH.address, WETH.symbol, WETH.decimals));
          toast.success('Wrap ETH Successfully');
        } else {
          toast.error('Wrap ETH Failed');
          // alert('Wrap ETH Failed');
        }
      });
      return transactionID;
    } catch (e) {
      toast.error(e.message);
      // alert(e);
    }
    return null;
  };
};

export const enable = (address, symbol, decimals, toast) => {
  return async (dispatch, getState) => {
    let transactionID = await dispatch(
      approve(address, symbol, 'f000000000000000000000000000000000000000000000000000000000000000', 'Enable', decimals, toast)
    );
    return transactionID;
  };
};

export const disable = (address, symbol, decimals, toast) => {
  return async (dispatch, getState) => {
    let transactionID = await dispatch(
      approve(address, symbol, '0000000000000000000000000000000000000000000000000000000000000000', 'Disable', decimals, toast)
    );
    return transactionID;
  };
};

export const approve = (tokenAddress, symbol, allowance, action, decimals, toast) => {
  return async (dispatch, getState) => {

    try {

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      await window.ethereum.enable();

      const signer = provider.getSigner();

      const tokenContract = new ethers.Contract(tokenAddress, abi, signer);

      // const balance = await tokenContract.balanceOf(await signer.getAddress());

      const spender = env.HYDRO_PROXY_ADDRESS;

      const tx = await tokenContract.approve(spender, `0x${allowance}`);
      // const tx = await tokenContract.approve(spender, allowance);

      await tx.wait();

      toast.success(`${action} ${symbol} request submitted`);
      watchTransactionStatus(tx.hash, async success => {
        if (success) {
          dispatch(watchToken(tokenAddress, symbol, decimals));
          toast.success(`${action} ${symbol} Successfully`);
        } else {
          toast.error(`${action} ${symbol} Failed`);
        }
      });
      return tx.hash;
    } catch (error) {
      toast.error(error.message);
    }
    return null;
  };
};

// export const approve = (tokenAddress, symbol, allowance, action, decimals,toast) => {
//   return async (dispatch, getState) => {
//     const state = getState();
//     const functionSelector = '095ea7b3';
//     let spender = get64BytesString(env.HYDRO_PROXY_ADDRESS);
//     if (spender.length !== 64) 
//     {
//       return null;
//     }

//     let params = {
//       // from: "0x32133C92983D78ED7193EE4d0f719C578fF5993b",
//       to: tokenAddress,
//       data: `0x${functionSelector}${spender}${allowance}`,
//       value: 0
//     };

//     try {

//       const wallet = getSelectedAccountWallet(state);
//        if(Object.keys(wallet) && Object.keys(wallet)[0]=="ethereum")
//        {
//          params.from = wallet.ethereum.selectedAddress
//        }
//       const transactionID = await wallet.sendTransaction(params);
//       toast.info(`${action} ${symbol} request submitted`);
//       // alert(`${action} ${symbol} request submitted`);
//       watchTransactionStatus(transactionID, async success => {
//         if (success) {
//           dispatch(watchToken(tokenAddress, symbol, decimals));
//           toast.success(`${action} ${symbol} Successfully`);
//           // alert(`${action} ${symbol} Successfully`);
//         } else {
//           toast.error(`${action} ${symbol} Failed`);
//           // alert(`${action} ${symbol} Failed`);
//         }
//       });
//       return transactionID;
//     } catch (e) {

//       toast.error(e.message)
//       // alert(e);
//     }
//     return null;
//   };
// };

const watchTransactionStatus = (txID, callback) => {
  const getTransactionStatus = async () => {
    const tx = await getTransactionReceipt(txID);
    if (!tx) {
      window.setTimeout(() => getTransactionStatus(txID), 3000);
    } else if (callback) {
      callback(Number(tx.status) === 1);
    } else {
      // toast.success('success');
      alert('success');
    }
  };
  window.setTimeout(() => getTransactionStatus(txID), 3000);
};

const get64BytesString = string => {
  string = string.replace('0x', '');
  while (string.length < 64) {
    string = '0'.concat(string);
  }
  return string;
};
