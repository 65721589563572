import React from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import { WalletButton, getSelectedAccount } from '@gongddex/hydro-sdk-wallet';
import Tokens from './Tokens';
import Wrap from './Wrap';
import { connect } from 'react-redux';
import './styles.scss';
import MediaQuery from 'react-responsive';
import env from '../../lib/env';
import { setloader } from '../../actions/loader'
const data = require('../../data/chaindata.json');

const OPTIONS = [
  { value: 'tokens', name: 'Tokens' },
  // { value: 'wrap', name: 'Wrap' },
  // { value: 'unwrap', name: 'Unwrap' }
];
const mapStateToProps = state => {
  const selectedAccount = getSelectedAccount(state);
  const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  const address = selectedAccount ? selectedAccount.get('address') : null;
  return {
    isLoggedIn: state.account.getIn(['isLoggedIn', address]),
    loader: state.loader,
    networkId: state.WalletReducer.getIn(['accounts', selectedAccountID, 'networkId']),
    selectedAccountID
  };
};
class Wallet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedAccountID: OPTIONS[0].value
    };
  }
  switchchain = async () => {
    const { dispatch } = this.props;

    if (window.ethereum) {
      dispatch(setloader(true))
      let obj = data.find((x) => x.ticker === env.MAINTICKER)
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${obj.chainId.toString(16)}` }],
        });
        dispatch(setloader(false))
        window.location.reload()
      } catch (error) {
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: `0x${obj.chainId.toString(16)}`,
                chainName: obj.chainName,
                nativeCurrency: {
                  name: obj.ticker,
                  symbol: obj.ticker,
                  decimals: obj.precision
                },
                rpcUrls: [obj.rpcUrl],
                blockExplorerUrls: [obj.blockExplorerURL]
              }]
            });
            dispatch(setloader(false))
          } catch (addError) {
            console.error(addError);
            dispatch(setloader(false))
          }
        }
        console.error(error);
        dispatch(setloader(false))
      }
    }
  };
  render() {
    return (
      <>
        <MediaQuery maxWidth={767}>{this.renderTablet()}</MediaQuery>
        <MediaQuery minWidth={768} maxWidth={1023}>{this.renderLaptop()}</MediaQuery>
        <MediaQuery minWidth={1024}>{this.renderWeb()}</MediaQuery>
      </>

    )
  }
  renderWeb() {
    const { isLoggedIn } = this.props;
    return (
      <>
        {/* <div className="title flex justify-content-between align-items-center">
          <div>Enable/Disable Tokens</div> */}
          {/* <Selector
            options={OPTIONS}
            selectedValue={selectedAccountID}
            handleClick={option => {
              this.setState({ selectedAccountID: option.value });
            }}
          /> */}
        {/* </div> */}
        <div className="flex-column flex-1 position-relative scrollable-content" style={{ height:"235px",overflow:"scroll"}} ref={ref => this.setRef(ref)}>
          {isLoggedIn ? this.renderTabPanel() : <div className='connectwallet'><span><WalletButton /></span></div>}
        </div>
      </>
    );
  }
  renderLaptop() {
    const { isLoggedIn } = this.props;
    return (
      <>
        <div className="flex-column flex-1 position-relative scrollable-content" style={{ height: "277px", overflow: "scroll"}} ref={ref => this.setRef(ref)}>
          {isLoggedIn ? this.renderTabPanel() : <div className='connectwallet'><span><WalletButton /></span></div>}
        </div>
      </>
    );
  }
  renderTablet() {
    const { isLoggedIn } = this.props;

    return (
      <>
        <div className="flex-column flex-1 position-relative scrollable-content" style={{ height: "600px", overflow: "scroll" }} ref={ref => this.setRef(ref)}>
          {isLoggedIn ? this.renderTabPanel() : <div className='connectwallet'><span><WalletButton /></span></div>}
        </div>
      </>
    );
  }

  renderTabPanel() {
    const { selectedAccountID } = this.state;
    switch (selectedAccountID) {
      case 'tokens':
        return <Tokens />;
      case 'wrap':
        return <Wrap type="wrap" />;
      case 'unwrap':
        return <Wrap type="unwrap" />;
      default:
        return <Tokens />;
    }
  }
  walletbutton(){
    return (
      <WalletButton />
    )
  }
  setRef(ref) {
    if (ref) {
      this.ps = new PerfectScrollbar(ref, {
        suppressScrollX: true,
        maxScrollbarLength: 20
      });
    }
  }
}

export default connect(mapStateToProps)(Wallet);
