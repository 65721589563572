import React from 'react';
import OpenOrders from './OpenOrders';
import Trades from './Trades';
import Selector from '../Selector';
import './styles.scss';
import MediaQuery from 'react-responsive';

const OPTIONS = [{ value: 'openOrders', name: 'Open' }, { value: 'filled', name: 'Filled' }];

class Orders extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedAccountID: OPTIONS[0].value,
      selectedName: OPTIONS[0].name
    };
  }
  render() {
    return (
      <>
        <MediaQuery maxWidth={1023}>
          {this.renderTablet()}
        </MediaQuery>
        <MediaQuery minWidth={1024}>{this.renderWeb()}</MediaQuery>
      </>
    )
  }
  renderWeb(){
    const { selectedAccountID, selectedName } = this.state;
    return (
      // <>
      //   <div className="title flex justify-content-between align-items-center">
      //     <div>
      //       <div>Orders</div>
      //       <div className="text-secondary">View your open orders</div>
      //     </div>
      //     <Selector
      //       options={OPTIONS}
      //       selectedValue={selectedAccountID}
      //       handleClick={option => {
      //         this.setState({ selectedAccountID: option.value });
      //       }}
      //     />
      //   </div>
      //   {selectedAccountID === 'openOrders' ? <OpenOrders /> : <Trades />}
      // </>
      <>
        <div className="trade flex-1 flex-column">
          <Selector
            options={OPTIONS}
            selectedValue={selectedAccountID}
            handleClick={option => {
              this.setState({ selectedAccountID: option.value, selectedName: option.name });
            }}
          />
          <div className='row pt-3 pb-3 aftertitleborder'>
            <div className='col-12'>
              <div className='d-flex justify-content-between'>
                <div className='ordertitle'>Orders</div>
                <div className="ordersubtitle">View your {selectedName} orders</div>
              </div>
            </div>
          </div>
          {/* <Selector
            options={OPTIONS}
            selectedValue={selectedAccountID}
            handleClick={option => {
              this.setState({ selectedAccountID: option.value });
            }}
          /> */}
        </div>
        {selectedAccountID === 'openOrders' ? <OpenOrders /> : <Trades />}
      </>
    );
  }
  renderTablet(){
    const { selectedAccountID, selectedName } = this.state;
    return (
      // <>
      //   <div className="title flex justify-content-between align-items-center">
      //     <div>
      //       <div>Orders</div>
      //       <div className="text-secondary">View your open orders</div>
      //     </div>
      //     <Selector
      //       options={OPTIONS}
      //       selectedValue={selectedAccountID}
      //       handleClick={option => {
      //         this.setState({ selectedAccountID: option.value });
      //       }}
      //     />
      //   </div>
      //   {selectedAccountID === 'openOrders' ? <OpenOrders /> : <Trades />}
      // </>
      <>
        <div className="trade flex-1 flex-column">
          <Selector
            options={OPTIONS}
            selectedValue={selectedAccountID}
            handleClick={option => {
              this.setState({ selectedAccountID: option.value });
            }}
          />
          <div className='pt-3 pb-3' style={{ borderBottom: "1.45px solid #E3E2E2" }}>
            <div className='col-12'>
              <div className='d-flex justify-content-between'>
                <div className='ordertitle'>Orders</div>
                <div className="ordersubtitle">View your {selectedName} orders</div>
              </div>
            </div>
          </div>
          {/* <Selector
            options={OPTIONS}
            selectedValue={selectedAccountID}
            handleClick={option => {
              this.setState({ selectedAccountID: option.value });
            }}
          /> */}
        </div>
        {selectedAccountID === 'openOrders' ? <OpenOrders /> : <Trades />}
      </>
    );
  }
}

export default Orders;
