import api from '../lib/api';
import { getSelectedAccount, getSelectedAccountWallet } from '@gongddex/hydro-sdk-wallet';
import { stateUtils } from '../selectors/account';
// import { toast } from 'react-toastify';
import { enable } from '../lib/wallet';
export const TRADE_FORM_ID = 'TRADE';

export const trade = (side, price, amount, orderType = 'limit', toast, expires = 86400 * 365 * 1000) => {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedAccount = getSelectedAccount(state);
    const address = selectedAccount ? selectedAccount.get('address') : null;
    const currentMarket = state.market.getIn(['markets', 'currentMarket']);
    let tokenaddress = {}
    stateUtils.getTokensInfo(state, address).toArray().map(([token, info]) => {
      const { decimals, address } = info.toJS();
      if (side === "buy") {
        if (currentMarket.quoteToken === token) {
          tokenaddress = { decimals, address }
        }
      } else if (currentMarket.baseToken === token){
          tokenaddress = { decimals, address }
      }
      return true
    })
    try {

      const result = await dispatch(createOrder(side, price, amount, orderType, expires, toast));
      if (result.status === 0) {
        toast.success('Order created successfully');
        // alert('Successfully created order');
        return true;
      } else {
        // toast.error(result.desc);
        if (side === "buy") {
          let response = await dispatch(enable(tokenaddress.address, currentMarket.quoteToken, tokenaddress.decimals, toast));
          if (response !== null) {
            await dispatch(createOrder(side, price, amount, orderType, expires, toast))
          }
        } else {
          let response = await dispatch(enable(tokenaddress.address, currentMarket.baseToken, tokenaddress.decimals, toast));
          if (response !== null) {
            await dispatch(createOrder(side, price, amount, orderType, expires, toast))
          }
        }
        // alert(result.desc);
      }
    } catch (e) {
      toast.error(e.messge);
      // alert(e);
    }

    return false;
  };
};

const createOrder = (side, price, amount, orderType, expires, toast) => {
  return async (dispatch, getState) => {
    const state = getState();
    const currentMarket = state.market.getIn(['markets', 'currentMarket']);

    const buildOrderResponse = await api.post('/orders/build', {
      amount,
      price,
      side,
      expires,
      orderType,
      marketID: currentMarket.id
    });

    if (buildOrderResponse.data.status !== 0) {
      return buildOrderResponse.data;
    }

    const orderParams = buildOrderResponse.data.data.order;
    const { id: orderID } = orderParams;
    try {
      const wallet = getSelectedAccountWallet(state);
      const signature = await wallet.signPersonalMessage(orderID);
      const orderSignature = '0x' + signature.slice(130) + '0'.repeat(62) + signature.slice(2, 130);
      const placeOrderResponse = await api.post('/orders', {
        orderID,
        signature: orderSignature,
        method: 0
      });

      return placeOrderResponse.data;
    } catch (e) {
      toast.error(e.message);
      // alert(e);
    }
  };
};

export const tradeUpdate = trade => {
  return {
    type: 'TRADE_UPDATE',
    payload: {
      trade
    }
  };
};

export const marketTrade = trade => {
  return {
    type: 'MARKET_TRADE',
    payload: {
      trade
    }
  };
};
