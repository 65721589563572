import React from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';

const mapStateToProps = state => {
  const lightmode = state.mode
  return {
    lightmode,
  };
};
class Selector extends React.PureComponent {


  render() {
    return (
    <>
      <MediaQuery maxWidth={1023}>
        {this.renderTablet()}
      </MediaQuery>
      <MediaQuery minWidth={1024}>{this.renderWeb()}</MediaQuery>
    </>
    )
  }
  renderWeb() {
    const { options, selectedValue, handleClick } = this.props;
    const { lightmode } = this.props;

    if (!options) {
      return null;
    }
    return (
      <div className="selector">
        <ul className="nav nav-tabs">
          {options.map(option => {
            return (
              <li
                key={option.value}
                className={!lightmode.lightmode ? `flex-1 tab-button-dark text-center ${selectedValue === option.value ? ' active' : ''} ${option.name === options[0].name ? 'tab-button-dark-left' : 'tab-button-dark-right'}` : `flex-1 tab-button text-center ${selectedValue === option.value ? 'active' : ''} ${option.name === options[0].name ? 'tab-button-left' : 'tab-button-right'}`}
                onClick={() => handleClick(option)}>
                <div className="text-center">{option.name}</div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  renderTablet() {
    const { options, selectedValue, handleClick } = this.props;
    const { lightmode } = this.props;

    if (!options) {
      return null;
    }
    return (
      <div className="selector col-12 pt-2">
        <ul className="nav nav-tabs">
          {options.map(option => {
            return (
              <li
                key={option.value}
                className={!lightmode.lightmode ? `flex-1 tab-button-dark text-center ${selectedValue === option.value ? ' active' : ''} ${option.name === options[0].name ? 'tab-button-dark-left' : 'tab-button-dark-right'}` : `flex-1 tab-button text-center ${selectedValue === option.value ? 'active' : ''} ${option.name === options[0].name ? 'tab-button-left' : 'tab-button-right'}`}
                onClick={() => handleClick(option)}>
                <div className="text-center">{option.name}</div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

}

export default connect(mapStateToProps)(Selector);
