export const settrade = (price,amount,side,click) => {
    return async dispatch => {
        return dispatch({
            type: 'TRADE',
            payload: {
                price,
                amount,
                side,
                click
            }
        });
    };
};
export const emptytrade = () => {
    return async dispatch => {
        return dispatch({
            type: 'EMPTYTRADE'
        });
    };
};


