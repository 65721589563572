import React from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'perfect-scrollbar';
import { loadOrders, cancelOrder } from '../../actions/account';
import { WalletButton, getSelectedAccount } from '@gongddex/hydro-sdk-wallet';
import { toast } from 'react-toastify';
import MediaQuery from 'react-responsive';


const mapStateToProps = state => {
  const selectedAccount = getSelectedAccount(state);
  // const address = "0xd38D26954C4a8087358b8D698fE5e3255C5Aecea";
  const address = selectedAccount ? selectedAccount.get('address') : null;
  return {
    orders: state.account.get('orders'),
    isLoggedIn: state.account.getIn(['isLoggedIn', address]),
    currentMarket: state.market.getIn(['markets', 'currentMarket'])
  };
};

class OpenOrders extends React.PureComponent {
  componentDidMount() {
    const { isLoggedIn, dispatch } = this.props;
    if (isLoggedIn) {
      dispatch(loadOrders(toast));
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoggedIn, dispatch, orders, currentMarket, address } = this.props;
    if (isLoggedIn && (isLoggedIn !== prevProps.isLoggedIn || currentMarket !== prevProps.currentMarket)) {
      dispatch(loadOrders(toast));
    }
    if (orders !== prevProps.orders) {
      this.ps && this.ps.update();
    }
    if (address && address !== prevProps.address) {
      dispatch(loadOrders(toast));
    }
  }

  render() {
    return (
      <>
        <MediaQuery minWidth={1366}>{this.renderDesktop()}</MediaQuery>
        <MediaQuery minWidth={1024} maxWidth={1365}>
          {this.renderLaptop()}
        </MediaQuery>
        <MediaQuery minWidth={768} maxWidth={1023}>
          {this.renderTablet()}
        </MediaQuery>
        <MediaQuery maxWidth={767}>{this.renderMobile()}</MediaQuery>
      </>
    );
  }


  renderDesktop() {
    const { orders, dispatch, currentMarket, isLoggedIn } = this.props;
    return (
      <div className="orders flex-1 position-relative table-responsive scrollable-content scrollBar" style={{ height: '324px', overflow: 'scroll' }}>
        {isLoggedIn ?
          orders.toArray().length > 0 ?
          <>
              <table className="table">
                <thead className='ordersheader'>
                  <tr className="text-secondary">
                    <th className="pair-column">Pair</th>
                    <th>Side</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Amount</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {orders
                    .toArray()
                    .reverse()
                    .map(([id, order]) => {
                      if (order.availableAmount.eq(0)) {
                        return null;
                      }
                      const symbol = order.marketID.split('-')[0];
                      return (
                        <tr key={id} style={{ cursor: 'auto' }}>
                          <td className="pair-column text-nowrap" >{order.marketID}</td>
                          <td className={order.side === 'sell' ? 'text-danger' : 'text-success'} style={{ fontSize: '15px', textTransform: 'capitalize' }}>{order.side}</td>
                          <td className="text-right text-nowrap">{order.price.toFixed(currentMarket.priceDecimals)}</td>
                          <td className="text-right text-nowrap">
                            {order.availableAmount.toFixed(currentMarket.amountDecimals)} {symbol}
                          </td>
                          <td className="text-right">
                            <button className="btn btn-outline-danger" onClick={() => dispatch(cancelOrder(order.id, toast))}>
                              Cancel
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
            : <div className='connectwallet'> No Orders Found !!!</div>
          : <div className='connectwallet'><span><WalletButton /></span></div>}
      </div>
    );
  }

  renderLaptop() {
    const { orders, dispatch, currentMarket, isLoggedIn } = this.props;
    return (
      <div className="orders flex-1 position-relative scrollable-content table-responsive scrollBar" style={{ height: '324px', overflow: 'scroll' }}>
        {isLoggedIn ?
          orders.toArray().length > 0 ?
            <>
              <table className="table">
                <thead className='ordersheader'>
                  <tr className="text-secondary">
                    <th className="pair-column">Pair</th>
                    <th>Side</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Amount</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {orders
                    .toArray()
                    .reverse()
                    .map(([id, order]) => {

                      if (order.availableAmount.eq(0)) {
                        return null;
                      }
                      const symbol = order.marketID.split('-')[0];
                      return (
                        <tr key={id} style={{ cursor: 'auto' }}>
                          <td className="pair-column text-nowrap">{order.marketID}</td>
                          <td className={order.side === 'sell' ? 'text-danger' : 'text-success'} style={{ fontSize: '15px', textTransform: 'capitalize' }}>{order.side}</td>
                          <td className="text-right text-nowrap">{order.price.toFixed(currentMarket.priceDecimals)}</td>
                          <td className="text-right text-nowrap">
                            {order.availableAmount.toFixed(currentMarket.amountDecimals)} {symbol}
                          </td>
                          <td className="text-right">
                            <button className="btn btn-outline-danger" onClick={() => dispatch(cancelOrder(order.id, toast))}>
                              Cancel
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
            : <div className='connectwallet'> No Orders Found !!!</div>
          : <div className='connectwallet'><span><WalletButton /></span></div>}
      </div>
    );
  }

  renderTablet() {
    const { orders, dispatch, currentMarket, isLoggedIn } = this.props;
    return (
      <div className="orders flex-1 position-relative table-responsive  scrollable-content scrollBar" style={{ height: '182px' }} ref={ref => this.setRef(ref)}>
        {isLoggedIn ?
          orders.toArray().length > 0 ?
            <>
              <table className="table">
                <thead className='ordersheader'>
                  <tr className="text-secondary">
                    <th className="pair-column">Pair</th>
                    <th>Side</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Amount</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {orders
                    .toArray()
                    .reverse()
                    .map(([id, order]) => {

                      if (order.availableAmount.eq(0)) {
                        return null;
                      }
                      const symbol = order.marketID.split('-')[0];
                      return (
                        <tr key={id} style={{ cursor: 'auto' }}>
                          <td className="pair-column text-nowrap">{order.marketID}</td>
                          <td className={order.side === 'sell' ? 'text-danger' : 'text-success'} style={{ fontSize: '15px', textTransform: 'capitalize' }}>{order.side}</td>
                          <td className="text-right text-nowrap">{order.price.toFixed(currentMarket.priceDecimals)}</td>
                          <td className="text-right text-nowrap">
                            {order.availableAmount.toFixed(currentMarket.amountDecimals)} {symbol}
                          </td>
                          <td className="text-right">
                            <button className="btn btn-outline-danger" onClick={() => dispatch(cancelOrder(order.id, toast))}>
                              Cancel
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
            : <div className='connectwallet'>No Orders Found !!!</div>
          : <div className='connectwallet'><span><WalletButton /></span></div>}
      </div>
    );
  }

  renderMobile() {
    const { orders, dispatch, currentMarket, isLoggedIn } = this.props;
    return (
      <div className="orders flex-1 position-relative scrollable-content table-responsive scrollBar" style={{ height: '490px', overflow: 'scroll' }}>
        {isLoggedIn ?
          orders.toArray().length > 0 ?
            <>
              <table className="table">
                <thead className="ordersheader">
                  <tr >
                    <th className="">Pair</th>
                    <th>Side</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Amount</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {orders
                    .toArray()
                    .reverse()
                    .map(([id, order]) => {

                      if (order.availableAmount.eq(0)) {
                        return null;
                      }
                      const symbol = order.marketID.split('-')[0];
                      return (
                        <tr key={id} style={{ cursor: 'auto' }}>
                          <td className=" text-nowrap">{order.marketID}</td>
                          <td className={order.side === 'sell' ? 'text-danger' : 'text-success'} style={{ fontSize: '15px', textTransform: 'capitalize' }}>{order.side}</td>
                          <td className="text-right text-nowrap">{order.price.toFixed(currentMarket.priceDecimals)}</td>
                          <td className="text-right text-nowrap">
                            {order.availableAmount.toFixed(currentMarket.amountDecimals)} {symbol}
                          </td>
                          <td className="text-right">
                            <button className="btn btn-outline-danger" onClick={() => dispatch(cancelOrder(order.id, toast))}>
                              Cancel
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
            : <div className='connectwallet'> No Orders Found !!!</div>
          : <div className='connectwallet'><span><WalletButton /></span></div>}
      </div>
    );
  }

  setRef(ref) {
    if (ref) {
      this.ps = new PerfectScrollbar(ref, {
        suppressScrollX: true,
        maxScrollbarLength: 20
      });
    }
  }
}

export default connect(mapStateToProps)(OpenOrders);
