


import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, Field, stopSubmit } from 'redux-form';
import { TRADE_FORM_ID } from '../../actions/trade';
import { reduxForm } from 'redux-form';
import { trade } from '../../actions/trade';
import BigNumber from 'bignumber.js';
import { loadHotDiscountRules, getHotTokenAmount } from '../../actions/fee';
import { calculateTrade } from '../../lib/tradeCalculator';
import { loginRequest } from '../../actions/account';
import PerfectScrollbar from 'perfect-scrollbar';
import './styles.scss';
import { sleep, toUnitAmount } from '../../lib/utils';
import { WalletButton, getSelectedAccount } from '@gongddex/hydro-sdk-wallet';
import { stateUtils } from '../../selectors/account';
import { toast } from 'react-toastify';
import MediaQuery from 'react-responsive';
import { emptytrade } from '../../actions/ordertrade';
import env from '../../lib/env';
import { setloader } from '../../actions/loader'
const data = require('../../data/chaindata.json');
const tradepair = require('../../data/tradepair.json')

const mapStateToProps = state => {
  const selector = formValueSelector(TRADE_FORM_ID);
  const bids = state.market.getIn(['orderbook', 'bids']);
  const asks = state.market.getIn(['orderbook', 'asks']);
  const selectedAccount = getSelectedAccount(state);
  const address = selectedAccount ? selectedAccount.get('address') : null;
  const currentMarket = state.market.getIn(['markets', 'currentMarket']);
  const lastTrade = state.market.get('tradeHistory').first();
  const lastPrice = lastTrade ? new BigNumber(lastTrade.price) : new BigNumber('0');
  const lightmode = state.mode
  const ordertrade = state.trade
  const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  return {
    initialValues: {
      side: 'buy',
      orderType: 'limit',
      subtotal: new BigNumber(0),
      total: new BigNumber(0),
      totalBase: new BigNumber(0),
      feeRate: new BigNumber(0),
      gasFee: new BigNumber(0),
      hotDiscount: new BigNumber(1),
      tradeFee: new BigNumber(0),
      estimatedPrice: new BigNumber(0),
      marketOrderWorstPrice: new BigNumber(0),
      marketOrderWorstTotalQuote: new BigNumber(0),
      marketOrderWorstTotalBase: new BigNumber(0)
    },
    ordertrade,
    lightmode,
    lastPrice,
    currentMarket,
    quoteTokenBalance: stateUtils.getTokenAvailableBalance(state, address, currentMarket.quoteToken),
    baseTokenBalance: stateUtils.getTokenAvailableBalance(state, address, currentMarket.baseToken),
    hotTokenAmount: state.config.get('hotTokenAmount'),
    address,
    isLoggedIn: state.account.getIn(['isLoggedIn', address]),
    price: new BigNumber(selector(state, 'price') || 0),
    amount: new BigNumber(selector(state, 'amount') || 0),
    total: new BigNumber(selector(state, 'total') || 0),
    totalBase: new BigNumber(selector(state, 'totalBase') || 0),
    subtotal: new BigNumber(selector(state, 'subtotal') || 0),
    feeRate: new BigNumber(selector(state, 'feeRate') || 0),
    gasFee: new BigNumber(selector(state, 'gasFee') || 0),
    estimatedPrice: new BigNumber(selector(state, 'estimatedPrice') || 0),
    marketOrderWorstPrice: new BigNumber(selector(state, 'marketOrderWorstPrice') || 0),
    marketOrderWorstTotalQuote: new BigNumber(selector(state, 'marketOrderWorstTotalQuote') || 0),
    marketOrderWorstTotalBase: new BigNumber(selector(state, 'marketOrderWorstTotalBase') || 0),
    hotDiscount: new BigNumber(selector(state, 'hotDiscount') || 1),
    tradeFee: new BigNumber(selector(state, 'tradeFee') || 0),
    side: selector(state, 'side'),
    orderType: selector(state, 'orderType'),
    bestBidPrice: bids.size > 0 ? bids.get(0)[0].toString() : null,
    bestAskPrice: asks.size > 0 ? asks.get(asks.size - 1)[0].toString() : null,
    networkId: state.WalletReducer.getIn(['accounts', selectedAccountID, 'networkId']),
    selectedAccountID,
    loader: state.loader
  };
};

class Trade extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      amountBuy: '',
      amountSell: '',
      priceBuy: '',
      priceSell: '',
      loader: false
    };
  }


  componentDidMount() {
    const { dispatch } = this.props;
    loadHotDiscountRules();
    this.interval = window.setInterval(() => {
      dispatch(getHotTokenAmount());
    }, 30 * 1000);
  }

  componentDidUpdate(prevProps) {
    const { currentMarket, reset, lastPrice, price, change, ordertrade } = this.props;
    if (currentMarket.id === prevProps.currentMarket.id) {
      if (!lastPrice.eq(prevProps.lastPrice) && price.eq(0)) {
        // change('price', lastPrice);
      }
      if (ordertrade.price !== 0 && ordertrade.amount !== 0 && ordertrade.click) {
        change('price', Number(ordertrade.price).toString());
        change('amount', Number(ordertrade.amount).toString());
        change('side', ordertrade.side);
      }
      this.updateFees(prevProps);
    } else {
      reset();
    }
  }
  switchchain = async () => {
    const { dispatch } = this.props;

    if (window.ethereum) {
      dispatch(setloader(true))
      let obj = data.find((x) => x.ticker === env.MAINTICKER)
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${obj.chainId.toString(16)}` }],
        });
        dispatch(setloader(false))
        window.location.reload()
      } catch (error) {
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: `0x${obj.chainId.toString(16)}`,
                chainName: obj.chainName,
                nativeCurrency: {
                  name: obj.ticker,
                  symbol: obj.ticker,
                  decimals: obj.precision
                },
                rpcUrls: [obj.rpcUrl],
                blockExplorerUrls: [obj.blockExplorerURL]
              }]
            });
            dispatch(setloader(false))
          } catch (addError) {
            console.error(addError);
            dispatch(setloader(false))
          }
        }
        console.error(error);
        dispatch(setloader(false))
      }
    }
  };
  async clickfunction() {
    const { dispatch } = this.props;
    await dispatch(emptytrade())
  }
  render() {
    const { currentMarket } = this.props;
    if (!currentMarket) {
      return null;
    }

    return (
      <>
        <MediaQuery minWidth={1366}>{this.renderDesktop()}</MediaQuery>
        <MediaQuery minWidth={1024} maxWidth={1365}>
          {this.renderLaptop()}
        </MediaQuery>
        <MediaQuery minWidth={768} maxWidth={1023}>
          {this.renderTablet()}
        </MediaQuery>
        <MediaQuery maxWidth={767}>{this.renderMobile()}</MediaQuery>
      </>
    );
  }

  renderDesktop() {
    const { side, handleSubmit, currentMarket, total, gasFee, tradeFee, subtotal, change, lightmode, address, networkId, selectedAccountID, loader } = this.props;

    if (!currentMarket) {
      return null;
    }
    return (
      <>
        {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
          <div className='disablediv'>
            {!loader ? <button className="btn btn-outline-primary" onClick={() => this.switchchain()}>Switch Network</button>
              : <button className="btn btn-outline-primary  disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>}
          </div>}
        <div className="trade flex-1 flex-column h-100" onClick={() => { this.clickfunction() }} style={{ opacity: selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && '0.5' }}>
          <ul className="nav nav-tabs">
            <li className="nav-item flex-1 flex">
              <div
                className={!lightmode.lightmode ? `flex-1 tab-button-dark text-center tab-button-dark-left${side === 'buy' ? ' active' : ''}` : `flex-1 tab-button text-center tab-button-left${side === 'buy' ? ' active' : ''}`}
                onClick={() => change('side', 'buy')}>
                Buy
              </div>
            </li>
            <li className="nav-item flex-1 flex">
              <div
                className={!lightmode.lightmode ? `flex-1 tab-button-dark text-center tab-button-dark-right${side === 'sell' ? ' active' : ''}` : `flex-1 tab-button text-center tab-button-right${side === 'sell' ? ' active' : ''}`}
                onClick={() => change('side', 'sell')}>
                Sell
              </div>
            </li>
          </ul>
          <div className="h-100">
            <div
              className="text-secondary pt-4 d-flex flex-column justify-content-between h-100"
            >
              <div>

                <Field
                  name="amount"
                  unit={currentMarket.baseToken}
                  autoComplete="off"
                  component={this.renderField}
                  // label="Amount"
                  placeholder='Amount'
                />
                { }
                <Field
                  name="price"
                  unit={currentMarket.quoteToken}
                  autoComplete="off"
                  component={this.renderField}
                  // label="Price"
                  placeholder='Price'
                />
                <div className="form-group">
                  <div className="form-title">Order Summary</div>
                  <div className="list">
                    <div className="item flex justify-content-between">
                      <div className="name">Order</div>
                      <div className="name">{subtotal.toFixed(currentMarket.priceDecimals)}&nbsp;{side === 'buy' ? currentMarket.quoteToken : currentMarket.baseTokenName}</div>
                    </div>
                    <div className="item flex justify-content-between">
                      <div className="name">Fees</div>
                      <div className="name">{gasFee.plus(tradeFee).toFixed(currentMarket.priceDecimals)}&nbsp;{side === 'buy' ? currentMarket.quoteToken : currentMarket.baseTokenName}</div>
                    </div>
                    <div className="item flex justify-content-between">
                      <div className="name totalname">Total</div>
                      <div className="name totalprice">{total.toFixed(currentMarket.priceDecimals)}&nbsp;{side === 'buy' ? currentMarket.quoteToken : currentMarket.baseTokenName}</div>
                    </div>
                  </div>
                </div>
              </div>
              {address ?
                this.state.loader ?
                  <button type="button" className={` btn ${side === 'buy' ? 'btn-primary' : 'btn-danger'} disabled`}><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;{side} {currentMarket.baseToken}</button>
                  :
                  <button type="button" className={` btn ${side === 'buy' ? 'btn-primary' : 'btn-danger'} text-capitalize w-100`} onClick={handleSubmit(() => this.submit())}>{side} {currentMarket.baseToken}</button>
                : <WalletButton />
              }
            </div>
          </div>
        </div>
      </>
    );
  }


  renderLaptop() {
    const { side, handleSubmit, currentMarket, total, gasFee, tradeFee, subtotal, change, lightmode, address, networkId, selectedAccountID, loader } = this.props;
    if (!currentMarket) {
      return null;
    }

    return (
      <>
        {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
          <div className='disablediv'>
            {!loader ? <button className="btn btn-outline-primary" onClick={() => this.switchchain()}>Switch Network</button>
              : <button className="btn btn-outline-primary  disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>}
          </div>}
        <div className="trade flex-1 flex-column h-100" onClick={() => { this.clickfunction() }} style={{ opacity: selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && '0.5' }}>
          <ul className="nav nav-tabs">
            <li className="nav-item flex-1 flex">
              <div
                className={!lightmode.lightmode ? `flex-1 tab-button-dark text-center tab-button-dark-left${side === 'buy' ? ' active' : ''}` : `flex-1 tab-button text-center tab-button-left${side === 'buy' ? ' active' : ''}`}
                onClick={() => change('side', 'buy')}>
                Buy
              </div>
            </li>
            <li className="nav-item flex-1 flex">
              <div
                className={!lightmode.lightmode ? `flex-1 tab-button-dark text-center tab-button-dark-right${side === 'sell' ? ' active' : ''}` : `flex-1 tab-button text-center tab-button-right${side === 'sell' ? ' active' : ''}`}
                onClick={() => change('side', 'sell')}>
                Sell
              </div>
            </li>
          </ul>
          <div className="h-100">
            <div
              className="text-secondary pt-4 d-flex flex-column justify-content-between h-100"
            >
              <div>

                <Field
                  name="amount"
                  unit={currentMarket.baseToken}
                  autoComplete="off"
                  component={this.renderField}
                  // label="Amount"
                  placeholder='Amount'
                />
                <Field
                  name="price"
                  unit={currentMarket.quoteToken}
                  autoComplete="off"
                  component={this.renderField}
                  // label="Price"
                  placeholder='Price'
                />
                <div className="form-group">
                  <div className="form-title">Order Summary</div>
                  <div className="list">
                    <div className="item flex justify-content-between">
                      <div className="name">Order</div>
                      <div className="name">{subtotal.toFixed(currentMarket.priceDecimals)}&nbsp;{side === 'buy' ? currentMarket.quoteToken : currentMarket.baseTokenName}</div>
                    </div>
                    <div className="item flex justify-content-between">
                      <div className="name">Fees</div>
                      <div className="name">{gasFee.plus(tradeFee).toFixed(currentMarket.priceDecimals)}&nbsp;{side === 'buy' ? currentMarket.quoteToken : currentMarket.baseTokenName}</div>
                    </div>
                    <div className="item flex justify-content-between">
                      <div className="name totalname">Total</div>
                      <div className="name totalprice">{total.toFixed(currentMarket.priceDecimals)}&nbsp;{side === 'buy' ? currentMarket.quoteToken : currentMarket.baseTokenName}</div>
                    </div>
                  </div>
                </div>
              </div>
              {address ?
                this.state.loader ?
                  <button type="button" className={` btn ${side === 'buy' ? 'btn-primary' : 'btn-danger'} disabled`}><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;{side} {currentMarket.baseToken}</button>
                  :
                  <button type="button" className={` btn ${side === 'buy' ? 'btn-primary' : 'btn-danger'} text-capitalize w-100`} onClick={handleSubmit(() => this.submit())}>{side} {currentMarket.baseToken}</button>
                : <WalletButton />
              }
            </div>
          </div>
        </div>
      </>
    );
  }

  renderTablet() {
    const { side, handleSubmit, currentMarket, total, gasFee, tradeFee, subtotal, change, lightmode, address, networkId, selectedAccountID, loader } = this.props;
    if (!currentMarket) {
      return null;
    }
    return (
      <>
        {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
          <div className='disablediv'>
            {!loader ? <button className="btn btn-outline-primary" onClick={() => this.switchchain()}>Switch Network</button>
              : <button className="btn btn-outline-primary disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>}
          </div>}
        <div className="trade flex-1 flex-column h-100" onClick={() => { this.clickfunction() }} style={{ opacity: selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && '0.5' }}>
          <ul className="nav nav-tabs">
            <li className="nav-item flex-1 flex">
              <div
                className={!lightmode.lightmode ? `flex-1 tab-button-dark text-center tab-button-dark-left${side === 'buy' ? ' active' : ''}` : `flex-1 tab-button text-center tab-button-left${side === 'buy' ? ' active' : ''}`}
                onClick={() => change('side', 'buy')}>
                Buy
              </div>
            </li>
            <li className="nav-item flex-1 flex">
              <div
                className={!lightmode.lightmode ? `flex-1 tab-button-dark text-center tab-button-dark-right${side === 'sell' ? ' active' : ''}` : `flex-1 tab-button text-center tab-button-right${side === 'sell' ? ' active' : ''}`}
                onClick={() => change('side', 'sell')}>
                Sell
              </div>
            </li>
          </ul>
          <div className="h-100" ref={ref => this.setRef(ref)}>
            <div
              className="text-secondary pt-4 d-flex flex-column justify-content-between h-100"
            >
              <div>

                <Field
                  name="amount"
                  unit={currentMarket.baseToken}
                  autoComplete="off"
                  component={this.renderField}
                  // label="Amount"
                  placeholder='Amount'
                />
                <Field
                  name="price"
                  unit={currentMarket.quoteToken}
                  autoComplete="off"
                  component={this.renderField}
                  // label="Price"
                  placeholder='Price'
                />
                <div className="form-group">
                  <div className="form-title">Order Summary</div>
                  <div className="list">
                    <div className="item flex justify-content-between">
                      <div className="name">Order</div>
                      <div className="name">{subtotal.toFixed(currentMarket.priceDecimals)}&nbsp;{side === 'buy' ? currentMarket.quoteToken : currentMarket.baseTokenName}</div>
                    </div>
                    <div className="item flex justify-content-between">
                      <div className="name">Fees</div>
                      <div className="name">{gasFee.plus(tradeFee).toFixed(currentMarket.priceDecimals)}&nbsp;{side === 'buy' ? currentMarket.quoteToken : currentMarket.baseTokenName}</div>
                    </div>
                    <div className="item flex justify-content-between">
                      <div className="name totalname">Total</div>
                      <div className="name totalprice">{total.toFixed(currentMarket.priceDecimals)}&nbsp;{side === 'buy' ? currentMarket.quoteToken : currentMarket.baseTokenName}</div>
                    </div>
                  </div>
                </div>
              </div>
              {address ?
                this.state.loader ?
                  <button type="button" className={` btn ${side === 'buy' ? 'btn-primary' : 'btn-danger'} disabled`}><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;{side} {currentMarket.baseToken}</button>
                  :
                  <button type="button" className={` btn ${side === 'buy' ? 'btn-primary' : 'btn-danger'} text-capitalize w-100`} onClick={handleSubmit(() => this.submit())}>{side} {currentMarket.baseToken}</button>
                : <WalletButton />
              }
            </div>
          </div>
        </div>
      </>
    );
  }

  renderMobile() {
    const { side, handleSubmit, currentMarket, total, gasFee, tradeFee, subtotal, change, lightmode, address, networkId, selectedAccountID, loader } = this.props;
    if (!currentMarket) {
      return null;
    }
    return (
      <>
        {selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) &&
          <div className='disablediv'>
            {!loader ? <button className="btn btn-outline-primary" onClick={() => this.switchchain()}>Switch Network</button>
              : <button className="btn btn-outline-primary  disablebutton disabled"><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;Switch Network</button>}
          </div>}
        <div className="trade flex-1 flex-column h-100" onClick={() => { this.clickfunction() }} style={{ opacity: selectedAccountID === 'EXTENSION' && parseInt(networkId, 10) !== parseInt(env.NETWORK_ID, 10) && '0.5' }}>
          <ul className="nav nav-tabs">
            <li className="nav-item flex-1 flex">
              <div
                className={!lightmode.lightmode ? `flex-1 tab-button-dark text-center tab-button-dark-left${side === 'buy' ? ' active' : ''}` : `flex-1 tab-button text-center tab-button-left${side === 'buy' ? ' active' : ''}`}
                onClick={() => change('side', 'buy')}>
                Buy
              </div>
            </li>
            <li className="nav-item flex-1 flex">
              <div
                className={!lightmode.lightmode ? `flex-1 tab-button-dark text-center tab-button-dark-right${side === 'sell' ? ' active' : ''}` : `flex-1 tab-button text-center tab-button-right${side === 'sell' ? ' active' : ''}`}
                onClick={() => change('side', 'sell')}>
                Sell
              </div>
            </li>
          </ul>
          <div className="h-100">
            <div
              className="d-flex flex-column h-100 justify-content-between pt-4 text-secondary"
            >
              <div>

                <Field
                  name="amount"
                  unit={currentMarket.baseToken}
                  autoComplete="off"
                  component={this.renderField}
                  // label="Amount"
                  placeholder='Amount'
                />
                <Field
                  name="price"
                  unit={currentMarket.quoteToken}
                  autoComplete="off"
                  component={this.renderField}
                  // label="Price"
                  placeholder='Price'
                />
                <div className="form-group">
                  <div className="form-title">Order Summary</div>
                  <div className="list">
                    <div className="item flex justify-content-between">
                      <div className="name">Order</div>
                      <div className="name">{subtotal.toFixed(currentMarket.priceDecimals)}&nbsp;{side === 'buy' ? currentMarket.quoteToken : currentMarket.baseTokenName}</div>
                    </div>
                    <div className="item flex justify-content-between">
                      <div className="name">Fees</div>
                      <div className="name">{gasFee.plus(tradeFee).toFixed(currentMarket.priceDecimals)}&nbsp;{side === 'buy' ? currentMarket.quoteToken : currentMarket.baseTokenName}</div>
                    </div>
                    <div className="item flex justify-content-between">
                      <div className="name totalname">Total</div>
                      <div className="name totalprice">{total.toFixed(currentMarket.priceDecimals)}&nbsp;{side === 'buy' ? currentMarket.quoteToken : currentMarket.baseTokenName}</div>
                    </div>
                  </div>
                </div>
              </div>
              {address ?
                this.state.loader ?
                  <button type="button" className={` btn ${side === 'buy' ? 'btn-primary' : 'btn-danger'} disabled`}><i className='RainConnect-fa fa fa-spinner fa-spin blackclrspin'></i>&nbsp;{side} {currentMarket.baseToken}</button>
                  :
                  <button type="button" className={` btn ${side === 'buy' ? 'btn-primary' : 'btn-danger'} text-capitalize w-100`} onClick={handleSubmit(() => this.submit())}>{side} {currentMarket.baseToken}</button>
                : <WalletButton />
              }
            </div>
          </div>
        </div>
      </>
    );
  }

  renderField = ({ input, unit, meta, ...attrs }) => {
    const { submitFailed, error } = meta;
    return (
      <div className="form-group">
        {/* <label>{label}</label> */}
        <div className="input-group">
          <input className="form-control inputfield" {...input} {...attrs} />
          <span className="unit">{unit}</span>
        </div>
        <span className="text-danger">{submitFailed && (error && <span>{error}</span>)}</span>
      </div>
    );
  };
  renderField2 = ({ input, unit, meta, ...attrs }) => {
    const { submitFailed, error } = meta;
    return (
      <div className="form-group">
        {/* <label>{label}</label> */}
        <div className="input-group">
          <input className="form-control inputfield" {...input} {...attrs} />
          <span className="unit">{unit}</span>
        </div>
        <span className="text-danger">{submitFailed && (error && <span>{error}</span>)}</span>
      </div>
    );
  };

  async submit() {
    const { amount, price, side, orderType, dispatch, isLoggedIn, address, currentMarket, total } = this.props;
    if (!isLoggedIn) {
      await dispatch(loginRequest(address));
      // Metamask's window will be hidden when continuous call Metamask sign method
      await sleep(500);
    }
    this.setState({ loader: true })
    if (Number(total) < tradepair[currentMarket.id].minOrdervalue) {
      toast.error("Total Order Amount must be 1 or more")
      this.setState({ loader: false })
    } else {
      try {
        await dispatch(trade(side, price, amount, orderType, toast));
        this.setState({ loader: false })
      } catch (e) {
        toast.error(e.message);
        this.setState({ loader: false })
        // alert(e);
      }
    }
  }


  updateFees(prevProps) {
    const { currentMarket, orderType, side, price, amount, hotTokenAmount, change } = this.props;

    if (
      orderType === prevProps.orderType &&
      side === prevProps.side &&
      price.eq(prevProps.price) &&
      amount.eq(prevProps.amount) &&
      hotTokenAmount.eq(prevProps.hotTokenAmount)
    ) {
      return;
    }
    const { asMakerFeeRate, asTakerFeeRate, gasFeeAmount, priceDecimals, amountDecimals } = currentMarket;

    const calculateParam = {
      orderType,
      side,
      price: new BigNumber(price),
      amount: new BigNumber(amount),
      hotTokenAmount,
      gasFeeAmount,
      asMakerFeeRate,
      asTakerFeeRate,
      amountDecimals,
      priceDecimals
    };
    const calculateResult = calculateTrade(calculateParam);

    change('subtotal', calculateResult.subtotal);
    change('estimatedPrice', calculateResult.estimatedPrice);
    change('totalBase', calculateResult.totalBaseTokens);
    change('total', calculateResult.totalQuoteTokens);
    change('feeRate', calculateResult.feeRateAfterDiscount);
    change('gasFee', calculateResult.gasFeeAmount);
    change('hotDiscount', calculateResult.hotDiscount);
    change('tradeFee', calculateResult.tradeFeeAfterDiscount);
  }

  setRef(ref) {
    if (ref) {
      this.ps = new PerfectScrollbar(ref, {
        suppressScrollX: true,
        maxScrollbarLength: 20
      });
    }
  }
}

const validate = (values, props) => {
  const { price, amount, total } = values;
  const { side, address, currentMarket, quoteTokenBalance, baseTokenBalance } = props;

  let _price, _amount, _total;

  const errors = {};

  if (address) {
    if (side === 'buy') {
      const quoteTokenAmount = toUnitAmount(quoteTokenBalance, currentMarket.quoteTokenDecimals);

      if (quoteTokenAmount.eq(0)) {
        errors.amount = `Insufficient ${currentMarket.quoteToken} balance`;
      }
    } else {
      const baseTokenAmount = toUnitAmount(baseTokenBalance, currentMarket.baseTokenDecimals);
      if (baseTokenAmount.eq(0)) {
        errors.amount = `Insufficient ${currentMarket.baseToken} balance`;
      }
    }
  }

  if (!errors.price) {
    if (!price) {
      errors.price = 'Price required';
    } else if (isNaN(Number(price))) {
      errors.price = 'Price must be a number';
    } else {
      _price = new BigNumber(price);
      if (_price.lte('0')) {
        errors.price = `Price cannot be 0`;
      }
    }
  }
  if (!errors.amount) {
    if (!amount) {
      errors.amount = 'Amount required';
    } else if (isNaN(Number(amount))) {
      errors.amount = 'Amount must be a number';
    } else {
      _amount = new BigNumber(amount);

      if (_amount.lte('0')) {
        errors.amount = `Amount cannot be 0`;
      } else if (_amount.multipliedBy(_price).lt(currentMarket.minOrderSize)) {
        errors.amount = `total ${side} price too small`;
      }
    }
  }

  if (!errors.amount && !errors.price && total && address) {
    _total = new BigNumber(total);
    if (side === 'buy') {
      const quoteTokenAmount = toUnitAmount(quoteTokenBalance, currentMarket.quoteTokenDecimals);

      if (_total.gt(quoteTokenAmount)) {
        errors.price = `Insufficient ${currentMarket.quoteToken} balance`;
      }
    } else {
      const baseTokenAmount = toUnitAmount(baseTokenBalance, currentMarket.baseTokenDecimals);

      if (_amount.gt(baseTokenAmount)) {
        errors.amount = `Insufficient ${currentMarket.baseToken} balance`;
      } else if (_total.lte('0')) {
        errors.amount = `Amount too small: total sale price less than fee`;
      }
    }
  }
  return errors;
};

const shouldError = () => {
  return true;
};
const onSubmitFail = (_, dispatch) => {
  setTimeout(() => {
    dispatch(stopSubmit(TRADE_FORM_ID));
  }, 3000);
};

export default connect(mapStateToProps)(
  reduxForm({
    form: TRADE_FORM_ID,
    destroyOnUnmount: false,
    onSubmitFail,
    validate,
    shouldError
  })(Trade),
);
