

export const setlightmode = () => {
    return async dispatch => {
        return dispatch({
            type: 'LIGHTMODE'
        });
    };
};
export const setdarkmode = () => {
    return async dispatch => {
        return dispatch({
            type: 'DARKMODE'
        });
    };
};
