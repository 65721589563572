
const initialState = false 

export default (state = initialState, action) => {
    switch (action.type) {
        case 'LOADER':
                state = action.payload
            return state;
        default:
            return state;
    }
};
