"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : new P(function (resolve) {
        resolve(result.value);
      }).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _1 = require(".");
var HardwareWallet = function (_super) {
  __extends(HardwareWallet, _super);
  function HardwareWallet() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.PATH_TYPE = {
      LIVE: "m/44'/60'/x'/0/0",
      LEGACY: "m/44'/60'/0'/x"
    };
    _this.PATH_TYPE_NAME = {
      LIVE: "Live",
      LEGACY: "LEGACY"
    };
    _this.currentPathRule = _this.PATH_TYPE.LIVE;
    _this.currentPath = "m/44'/60'/0'/0/0";
    _this.connected = false;
    _this.PER_PAGE = 3;
    _this.CUSTOMIZAION_PATH = "Customization";
    _this.PREFIX_ETHEREUM_PATH = "m/44'/60'/";
    _this.addresses = {};
    return _this;
  }
  HardwareWallet.prototype.getPathType = function (pathRule) {
    return Object.values(this.PATH_TYPE).indexOf(pathRule) > -1 ? pathRule : this.CUSTOMIZAION_PATH;
  };
  HardwareWallet.prototype.setPath = function (pathRule, path) {
    this.currentPathRule = pathRule;
    this.currentPath = path;
    window.localStorage.setItem(this.name() + ":lastSelectedPathRule", pathRule);
    window.localStorage.setItem(this.name() + ":lastSelectedPath", path);
  };
  HardwareWallet.prototype.type = function () {
    return HardwareWallet.TYPE;
  };
  HardwareWallet.prototype.id = function () {
    return HardwareWallet.TYPE;
  };
  HardwareWallet.prototype.signMessage = function (message) {
    return this.signPersonalMessage(message);
  };
  HardwareWallet.prototype.getAddressesWithPath = function (pathRule, from, count) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2, {}];
      });
    });
  };
  HardwareWallet.prototype.getCurrentPathIndex = function () {
    var parts = this.currentPath.split("/");
    var partIndex = parts.length - 1;
    if (this.currentPathRule.includes("x")) {
      var ruleParts = this.currentPathRule.split("/");
      ruleParts.forEach(function (part, index) {
        if (part.includes("x")) {
          partIndex = index;
        }
      });
    }
    return Number(parts[partIndex].replace("'", ""));
  };
  HardwareWallet.prototype.getPath = function (pathRule, index) {
    if (pathRule.includes("x")) {
      return pathRule.replace("x", index.toString());
    } else {
      return pathRule + "/" + index.toString();
    }
  };
  HardwareWallet.prototype.isSupported = function () {
    return this.connected;
  };
  HardwareWallet.prototype.isLocked = function () {
    return !this.connected;
  };
  HardwareWallet.prototype.loadNetworkId = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2, _1.getNetworkID()];
      });
    });
  };
  HardwareWallet.prototype.sendCustomRequest = function (method, params) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2, null];
      });
    });
  };
  HardwareWallet.prototype.name = function () {
    return "HardwareWallet";
  };
  HardwareWallet.LABEL = "Hardware Wallet";
  HardwareWallet.TYPE = "HARDWARE_WALLET";
  return HardwareWallet;
}(_1.BaseWallet);
exports.default = HardwareWallet;