"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : new P(function (resolve) {
        resolve(result.value);
      }).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    throw: verb(1),
    return: verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  result["default"] = mod;
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var React = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var wallet_1 = require("../../../actions/wallet");
var mapStateToProps = function mapStateToProps(state) {
  var walletState = state.WalletReducer;
  var walletCache = walletState.get("walletCache");
  return {
    wallet: walletCache.wallet,
    password: walletCache.password,
    walletTranslations: walletState.get("walletTranslations")
  };
};
var CreateConfirm = function (_super) {
  __extends(CreateConfirm, _super);
  function CreateConfirm(props) {
    var _this = _super.call(this, props) || this;
    _this.state = {
      checkbox: new Array(_this.props.walletTranslations.createConfirm.length).fill(false),
      processing: false
    };
    return _this;
  }
  CreateConfirm.prototype.render = function () {
    var _this = this;
    var _a = this.state,
      checkbox = _a.checkbox,
      processing = _a.processing;
    var walletTranslations = this.props.walletTranslations;
    return React.createElement("div", {
      className: "RainConnect-confirm"
    }, this.renderConfirmCheckbox(), React.createElement("div", {
      className: "RainConnect-desc browserconnect"
    }, walletTranslations.confirmDesc), React.createElement("button", {
      className: "RainConnect-button RainConnect-submitButton RainConnect-featureButton createwallet",
      type: "submit",
      onClick: function onClick() {
        return _this.handleSubmit();
      },
      disabled: checkbox.indexOf(false) !== -1 || processing
    }, processing ? React.createElement("i", {
      className: "RainConnect-fa fa fa-spinner fa-spin"
    }) : null, " ", walletTranslations.create));
  };
  CreateConfirm.prototype.handleSubmit = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _a, dispatch, wallet, password;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _a = this.props, dispatch = _a.dispatch, wallet = _a.wallet, password = _a.password;
            this.setState({
              processing: true
            });
            return [4, wallet.save(password)];
          case 1:
            _b.sent();
            return [4, dispatch(wallet_1.watchWallet(wallet))];
          case 2:
            _b.sent();
            this.setState({
              processing: false
            });
            dispatch(wallet_1.setWalletStep(wallet_1.WALLET_STEPS.ADD_FUNDS));
            return [2];
        }
      });
    });
  };
  CreateConfirm.prototype.renderConfirmCheckbox = function () {
    var _this = this;
    var checkbox = this.state.checkbox;
    var walletTranslations = this.props.walletTranslations;
    var nodes = checkbox.map(function (checked, index) {
      return React.createElement("div", {
        key: index,
        className: "RainConnect-checkboxDiv " + (checked ? "checked" : ""),
        onClick: function onClick() {
          return _this.handleCheck(index);
        }
      }, React.createElement("div", {
        className: "RainConnect-checkbox"
      }, React.createElement("i", {
        className: "fa fa-check"
      })), walletTranslations.createConfirm[index]);
    });
    return React.createElement("div", {
      className: "RainConnect-checkboxGroup"
    }, nodes);
  };
  CreateConfirm.prototype.handleCheck = function (index) {
    var oldCheckbox = this.state.checkbox;
    var checkbox = oldCheckbox.slice();
    checkbox[index] = !oldCheckbox[index];
    this.setState({
      checkbox: checkbox
    });
  };
  return CreateConfirm;
}(React.PureComponent);
exports.default = react_redux_1.connect(mapStateToProps)(CreateConfirm);