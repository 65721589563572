"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  result["default"] = mod;
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var React = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var wallet_1 = require("../../selector/wallet");
var wallet_2 = require("../../actions/wallet");
var wallets_1 = require("../../wallets");
var WalletButton = function (_super) {
  __extends(WalletButton, _super);
  function WalletButton() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  WalletButton.prototype.render = function () {
    var _this = this;
    return React.createElement("button", {
      className: "btn btn-primary",
      onClick: function onClick() {
        return _this.handleClick();
      }
    }, this.toggleText());
  };
  WalletButton.prototype.handleClick = function () {
    var _a = this.props,
      isShowWalletModal = _a.isShowWalletModal,
      dispatch = _a.dispatch,
      notAllowClick = _a.notAllowClick;
    if (!notAllowClick) {
      dispatch(isShowWalletModal ? wallet_2.hideWalletModal() : wallet_2.showWalletModal());
    }
  };
  WalletButton.prototype.toggleText = function () {
    var _a = this.props,
      selectedAccount = _a.selectedAccount,
      walletTranslations = _a.walletTranslations;
    if (selectedAccount) {
      var isLocked = selectedAccount.get("isLocked");
      return React.createElement("span", null, isLocked ? React.createElement("i", {
        className: "RainConnect-fa fa fa-lock"
      }) : React.createElement("i", {
        className: "RainConnect-fa fa fa-check"
      }), selectedAccount.get("address") ? wallets_1.truncateAddress(selectedAccount.get("address") || "") : walletTranslations.toggleButtonText);
    } else {
      return React.createElement("span", null, walletTranslations.toggleButtonText);
    }
  };
  return WalletButton;
}(React.PureComponent);
exports.default = react_redux_1.connect(function (state) {
  var walletState = state.WalletReducer;
  return {
    selectedAccount: wallet_1.getSelectedAccount(state),
    isShowWalletModal: walletState.get("isShowWalletModal"),
    walletTranslations: walletState.get("walletTranslations")
  };
})(WalletButton);